import React, { useEffect } from 'react'
import { Divider } from '@mantine/core'
import { useSelector} from 'react-redux'
import { useNavigate } from "react-router-dom";

import Footer from '../../components/Footer'
import Header from '../../components/Header'

import Login from '../../components/Login'
import Subscribe from '../../components/Subscribe'
import ForgotPassword from '../../components/ForgotPassword'
import ResetPassword from '../../components/ResetPassword'
import Sucess from '../../components/Sucess'

function Auth() {
   const user = useSelector(state => state.Auth.user)

   const navigate = useNavigate();

   const [typeModal, setTypeModal] = React.useState('login')

   useEffect(() => {
      const token = localStorage.getItem("token_cliente")
      if(token !== null){
         user.address === null ? navigate('/endereco') : navigate('/checkout') 
      } 
   }, [user, navigate])

   
   const renderModalWeb = {
      'login': <Login typeModal={typeModal} setTypeModal={setTypeModal} web={false} />,
      'subscribe': <Subscribe typeModal={typeModal} setTypeModal={setTypeModal} web={false}/>,
      'forgotPassword': <ForgotPassword typeModal={typeModal} setTypeModal={setTypeModal} />,
      'resetPassword': <ResetPassword typeModal={typeModal} setTypeModal={setTypeModal} />,
      'sucess': <Sucess setTypeModal={setTypeModal} />
   }  

   const renderModalMobile = {
      'login': <Login typeModal={typeModal} setTypeModal={setTypeModal} web={true} />,
      'subscribe': <Subscribe typeModal={typeModal} setTypeModal={setTypeModal} web={true}/>,
      'forgotPassword': <ForgotPassword typeModal={typeModal} setTypeModal={setTypeModal} />,
      'resetPassword': <ResetPassword typeModal={typeModal} setTypeModal={setTypeModal} />,
      'sucess': <Sucess setTypeModal={setTypeModal} />
   }  

   return (
      <>
         <Header title={'Autenticação'}/>
         <div className='bg-white mx-auto py-20 rounded-lg max-w-6xl hidden sm:block'>
            <p className='mb-4 text-2xl font-bold text-gray-900'>
               Faça login ou cadastre-se para continuar
            </p>
            <Divider className='p-3'/>
            <div className='flex  justify-evenly'>
               {renderModalWeb[typeModal]}   

               <Divider orientation='vertical' className='mx-4'/>         

               <Subscribe />
            </div>
         </div>

         <div className='bg-white block sm:hidden'>
            {renderModalMobile[typeModal]}
         </div>
         <Footer />
      </>
   )
}


 
export default Auth;