import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import moment from 'moment';
import {
    LOGIN_USER,
    LOGOUT_USER,
    REGISTER_USER,
    UPDATE_USER
} from './constants';

import {
    loginUser,
    loginUserSuccess,
    loginFailed,
    subscribeFailed
} from './actions';

import { toast } from 'react-toastify'

import { HelpersAuth } from '../../helpers'
const helpersAuth = new HelpersAuth()

const delay = time => new Promise(resolve => setTimeout(resolve, time));

function* login({ payload: { email, password } }) {
    try {
        const response = yield call(helpersAuth.Login, email, password);
        localStorage.setItem("token_cliente", response.data.token)
        var now = moment().add(1, 'hours').format()
        localStorage.setItem("expiry_date", now)
        const user = yield call(helpersAuth.GetUser, response.data.token);
        yield put(loginUserSuccess(user.data.data));   
    } catch (error) {
        yield put(loginFailed(error));
        yield call(delay, 3000);
        yield put(loginFailed(null));
    }
}

function* register({ payload: { name, email, cpf, password, confirmPassword } }) {
   try {
        yield call(helpersAuth.Register, name, email, cpf, password, confirmPassword);
        yield put(loginUser(email, confirmPassword));
    } catch (error) {
        yield put(subscribeFailed(error));
        yield call(delay, 3000);
        yield put(subscribeFailed(null));
    }
}

function* updateUser({ payload: { valuesUser } }) {
    try {
        yield call(helpersAuth.UpdateUser, valuesUser);
        const validToken = localStorage.getItem("token_cliente")
        const user = yield call(helpersAuth.GetUser, validToken);
        yield put(loginUserSuccess(user.data.data)); 
        toast("Usuário atualizado com sucesso!", {
            type: "success"
        })
    } catch (error) {
        yield put(loginFailed(error));
        yield call(delay, 3000);
        yield put(loginFailed(null));
    }
}

function* logout({ payload: { history } }) {
    try {
       localStorage.clear();
       yield call(() => { history("/")});
    } catch (error) {
 
    }
 }

export function* watchLoginUser() {
    yield takeEvery(LOGIN_USER, login);
}

export function* watchLogoutUser() {
    yield takeEvery(LOGOUT_USER, logout);
}

export function* watchRegisterUser() {
    yield takeEvery(REGISTER_USER, register);
}

export function* watchUpdateUser() {
    yield takeEvery(UPDATE_USER, updateUser);
}


function* authSaga() {
    yield all([
        fork(watchLoginUser),
        fork(watchUpdateUser),
        fork(watchLogoutUser),
        fork(watchRegisterUser),
    ]);
}

export default authSaga;