import React, {useState} from 'react'
import { Accordion } from '@mantine/core';


const doubts = [
  { 
    title: 'É seguro alugar uma mala?',
    body: <p className='leading-loose indent-8'><strong>Alugar malas</strong> é uma opção cada vez mais popular e segura. Na <strong>Malug</strong>, oferecemos uma experiência completa para que você tenha a melhor viagem possível. Trabalhamos com <strong>marcas renomadas e modelos modernos</strong>, para todos os estilos e necessidades. Todas as malas são <strong>higienizadas com produtos específicos</strong> antes e depois de cada uso, para garantir sua saúde e segurança. Oferecemos <strong>seguro contra danos e extravio</strong>, para que você possa viajar tranquilo. <strong>Você recebe a mala na sua casa</strong> e na devolução vamos buscar. <strong>Nossa equipe está sempre disponível</strong> para te ajudar a escolher a mala ideal para sua viagem.</p>,
  },
  { 
    title: 'Como funciona a Malug?',
    body: <p className='leading-loose indent-8'>É simples, <strong>1</strong> - Entre no site e preencha os campos de data de recebimento, data de devolução a cidade em que quer alugar a mala e a quantidade. <strong>2</strong> - Efetue o pagamento via Pix ou cartão de crédito. <strong>3</strong> - Epere o dia de recebimento da mala que vamos deixar até você no endereço cadastrado. <strong>4</strong> - Aproveite a viagem. <strong>5</strong> - Chegou o dia de devolver, vamos buscar a mala com você e está finalizado o aluguel</p>,
  },
  { 
    title: 'Como funciona o seguro mala?',
    body: <p className='leading-loose indent-8'>Na <strong>Malug</strong>, queremos que você tenha a melhor experiência possível em sua viagem. Por isso, oferecemos o Seguro Mala, uma proteção completa para sua bagagem contra imprevistos. <strong>Danos:</strong> Protege sua mala contra danos acidentais durante o transporte, como arranhões, amassados, quebras e rasgos. <strong>Extravio</strong>: Garante o reembolso do valor da mala em caso de extravio pela companhia aérea. <strong>Roubo</strong>: Cobre o valor da mala.</p>,
  },
  { 
    title: 'Quais os meios de pagamento aceitos ?',
    body: <p className='leading-loose indent-8'>Você poderá pagar o aluguel da mala através de cartões crédito, ou via Pix.</p>,
  }
]

function Doubts() {
  const [value, setValue] = useState(null);

  return (
    <div className='px-5 py-20'>
      <h1 className='text-center text-[25px] font-bold mb-10'>Dúvidas frequentes:</h1>
      <Accordion variant="separated" defaultValue="customization" className='mx-auto max-w-6xl' value={value} onChange={setValue}>
        {doubts.map((item, index) => (
          <Accordion.Item value={index.toString()} key={index.toString()} >
            <Accordion.Control>{item.title}</Accordion.Control>
            <Accordion.Panel>{item.body}</Accordion.Panel>
          </Accordion.Item>
        ))}
      </Accordion>
    </div>
  )
}

export default Doubts