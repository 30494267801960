import React, { useEffect } from 'react'
import { Divider } from '@mantine/core'

import Spinner from '../../assets/svgs/spinner'

import { HelpersAuth } from '../../helpers'
const helpersAuth = new HelpersAuth()

export default function ResetPassword({ showModal, setTypeModal, typeModal }) {
   const [code, setCode] = React.useState('')
   const [password, setPassword] = React.useState('');
   const [confirmPassword, setConfirmPassword] = React.useState('');
   const [loadingPassword, setLoadingPassword] = React.useState(false)
   const [erroPassword, setErroPassword] = React.useState(false)

   useEffect(() => {
      setPassword('');
      setConfirmPassword('');
      setCode('')
   }, [showModal, typeModal, erroPassword])

   const handleSubmit = (e) => {
      e.preventDefault();

      setLoadingPassword(true)

      helpersAuth.resetPassword({ code: code, password: password, password_confirmation: confirmPassword })
      .then(() => {
         setTypeModal('sucess')
         setLoadingPassword(false)
      }).catch(() => {
         setLoadingPassword(false);
         setErroPassword(true);
         setTimeout(() => {
            setErroPassword(false);
         }, 3000);
      })
   }

   return (
      <div className="py-6 px-6 lg:px-8 text-left">
         <h3 className="mb-4 text-xl font-bold text-gray-900">
            O código recebido e nova senha
         </h3>
         <Divider />
         <form className="space-y-6 pt-4" onSubmit={handleSubmit}>
            <div>
               <label className="block mb-2 text-sm font-semibold text-gray-900">
                  Código recebido:
               </label>
               <input
                  type="text"
                  name="code"
                  id="code"
                  className="bg-gray-50 border border-gray-300 
                  text-gray-900 text-sm rounded-lg  block w-full 
                  p-2.5 border-solid border-2 focus:border-default-orange outline-none"
                  placeholder="123456"
                  required
                  value={code}
                  onChange={e => setCode(e.target.value)}
                  disabled={loadingPassword}
               />
            </div>
            <div>
               <label className="block mb-2 text-sm font-semibold text-gray-900">
                  Sua nova senha:
               </label>
               <input
                  type="password"
                  name="password"
                  id="password"
                  className="bg-gray-50 border border-gray-300 
                  text-gray-900 text-sm rounded-lg  block w-full 
                  p-2.5 border-solid border-2 focus:border-default-orange outline-none"
                  placeholder="*********"
                  required
                  minLength={6}
                  value={password}
                  onChange={e => setPassword(e.target.value)}
                  disabled={loadingPassword}
               />
            </div>
            <div>
               <label className="block mb-2 text-sm font-semibold text-gray-900" >
                  Confirme sua nova senha:
               </label>
               <input
                  type="password"
                  name="password"
                  id="password"
                  className="bg-gray-50 border border-gray-300 
                 text-gray-900 text-sm rounded-lg  block w-full 
                 p-2.5 border-solid border-2 focus:border-default-orange outline-none"
                  placeholder="*********"
                  required
                  minLength={6}
                  value={confirmPassword}
                  onChange={e => setConfirmPassword(e.target.value)}
                  disabled={loadingPassword}
               />
            </div>
            {
               erroPassword ? <div className='w-full bg-red-300 rounded-lg py-2 px-3'>
                  <p className="text-red-600">
                     Código inválido ou senhas não correspondem
                  </p>
               </div> : null
            }
            <button
               type="submit"
               className="w-full text-white bg-default-orange hover:bg-default-blue
                 border-none font-semibold rounded-lg text-base font-semibold px-5 py-2.5
                 inline-flex items-center justify-center cursor-pointer"
               disabled={loadingPassword}
            >
               { loadingPassword ? <> <Spinner /> Redefinindo senha </> : 'Redefinir senha' }
            </button>
         </form>
      </div>
   )

}
