import React, { useState } from 'react'
import { formatarTelefone } from '../../utils';
import Spinner from '../../assets/svgs/spinner'


import Footer from '../../components/Footer'
import Header from '../../components/Header'

export default function PrivacyPolicy() {
   const [loading, setLoading] = useState('')
   const [name, setName] = useState('')
   const [email, setEmail] = useState('')
   const [telefone, setTelefone] = useState('')
   const [curriculo, setCurriculo] = useState(null)

   const finalizar = (e) => {
      e.preventDefault();
      setLoading(true)
      setTimeout(() => {
         setLoading(false)
         setName('')
         setEmail('')
         setTelefone('')
         setCurriculo('')
         alert('Curriculo enviado!')
      }, 1500)
   }

   return (
      <>
         <Header title={'Trabalhe conosco'} />
         <div className={`p-5 sm:pt-5 mx-auto max-w-6xl`}>
            <label className='mb-0 font-bold text-2xl'>Venha se conectar conosco</label>
            <p className='text-justify pt-5'>
               Nosso propósito é conquistar sorrisos todos os dias. Vamos com a gente?
            </p>
            <p className='text-justify indent-12 pt-5'>
               A malug é uma empresa fundada em 2023, conta com uma operação que inclui 3 licenciados
               (Rio de janeiro | São luis | São José de Ribamar ), Pelo e-commerce, entregamos malas e
               sorrisos para todos as regiões do Brasil. possuímos tecnologia própia com o máximo
               de desempenho
            </p>
            <p className='text-justify pt-5'>
               O novo faz parte da nossa rotina e estamos sempre buscando pessoas que não vejam barreiras entre o físico e o digital.
            </p>
            <p className='text-justify pt-5'>
               Junte-se a nós na missão de proporcionar experiências positivas na vida das pessoas, conquistando sorrisos todos os dias.
            </p>
            <p className='text-justify pt-5 font-bold'>
               Seja um Maluguer !
            </p>
         </div>
         <form className='mx-auto max-w-xl pt-5 pb-10 p-5 sm:pt-5  flex flex-col' onSubmit={finalizar}>
            <label className='mb-0 font-medium text-md gap-3'>Nome completo*</label>
            <input
               type="text"
               name="nome"
               id="nome"
               className="bg-gray-50 border border-gray-300 
          text-gray-900 text-sm rounded-lg  block w-full 
          p-2.5 border-solid border-2 focus:border-default-orange outline-none"
               placeholder="Digite seu nome"
               required
               disabled={loading}
               value={name}
               onChange={(e) => setName(e.target.value)}
            />
            <label className='mb-0 font-medium text-md pt-5'>Email*</label>
            <input
               type="email"
               name="email"
               id="email"
               className="bg-gray-50 border border-gray-300 
          text-gray-900 text-sm rounded-lg  block w-full 
          p-2.5 border-solid border-2 focus:border-default-orange outline-none"
               placeholder="Digite seu email"
               required
               disabled={loading}
               value={email}
               onChange={(e) => setEmail(e.target.value)}
            />
            <label className='mb-0 font-medium text-md pt-5'>Telefone*</label>
            <input
               type="text"
               name="telefone"
               id="telefone"
               className="bg-gray-50 border border-gray-300 
          text-gray-900 text-sm rounded-lg  block w-full 
          p-2.5 border-solid border-2 focus:border-default-orange outline-none"
               placeholder="Digite seu telefone"
               required
               maxLength={14}
               minLength={14}
               disabled={loading}
               value={formatarTelefone(telefone)}
               onChange={(e) => setTelefone(e.target.value)}
            />
            <label className='mb-0 font-medium text-md pt-5'>Anexar currículo*</label>
            <input
               type="file"
               name="curriculo"
               id="curriculo"
               className="bg-gray-50 border border-gray-300 
          text-gray-900 text-sm rounded-lg  block w-full 
          p-2.5 border-solid border-2 focus:border-default-orange outline-none"
               placeholder="Anexar currículo"
               required
               disabled={loading}
               value={curriculo}
               onChange={(e) => setCurriculo(e.target.value)}
            />
            <button
               className="cursor-pointer bg-default-orange 
               hover:bg-default-blue w-full h-[44px] text-xl 
               font-bold rounded-xl text-white flex items-center 
               justify-center mt-10"
               type='submit'>
               {
                  loading ? <> <Spinner />Enviando</> : 'Enviar'
               }
            </button>
         </form>
         <Footer />
      </>
   )
}
