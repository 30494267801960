import * as React from "react";
const SVGComponent = (props) => (
  <svg
    width={18}
    height={20}
    viewBox="0 0 18 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_6_177)">
      <path
        d="M9 18.3333C13.1421 18.3333 16.5 14.6024 16.5 10C16.5 5.39763 13.1421 1.66667 9 1.66667C4.85786 1.66667 1.5 5.39763 1.5 10C1.5 14.6024 4.85786 18.3333 9 18.3333Z"
        stroke="white"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9 6.66667V13.3333"
        stroke="white"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6 10H12"
        stroke="white"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_6_177">
        <rect width={18} height={20} fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export default SVGComponent;
