import React from "react";
import {formataCPF, formatarTelefone, formataData} from '../../../../../utils'

function DetailsData({name, cpf, rg, email, telefone, birthdate}) {
   return (
      <>
         <div className='grid grid-cols-3'>
            <div className='ml-5 mr-2'>
               <label className="block mb-2 text-sm font-semibold text-gray-900">
                  NOME: {name}
               </label>
            </div>

            <div className='ml-2 mr-2 '>
               <label className="block mb-2 text-sm font-semibold text-gray-900">
                  CPF: {formataCPF(cpf)}
               </label>
            </div>

            <div className='ml-2 mr-5'>
               <label className="block mb-2 text-sm font-semibold text-gray-900">
                  RG: {rg}
               </label>
            </div>
         </div>

         <div className='grid grid-cols-6 mt-3'>
            <div className='ml-5 mr-2 col-span-2'>
               <label className="block mb-2 text-sm font-semibold text-gray-900">
                  EMAIL: {email}
               </label>
            </div>
            <div className='ml-2 mr-2 col-span-2'>
               <label className="block mb-2 text-sm font-semibold text-gray-900">
                  TELEFONE: {formatarTelefone(telefone)}
               </label>
            </div>
            <div className='ml-2 mr-5 col-span-2'>
               <label className="block mb-2 text-sm font-semibold text-gray-900">
                  DATA DE NASCIMENTO: {formataData(birthdate)}
               </label>
            </div>
         </div>
      </>
   )
}

export default DetailsData