import React, { useEffect } from 'react'
import { Input, Image } from '@mantine/core'
import { useSelector, useDispatch } from 'react-redux'

// import { Carousel } from 'react-responsive-carousel'

//imgs
import bag1 from '../../assets/images/bagG.png'
import bag2 from '../../assets/images/bagM.png'
import bag3 from '../../assets/images/bagP.png'
// import background from '../../assets/images/carrosel1.png'

// step by step
import calendar from '../../assets/images/calendar.png'
import bag from '../../assets/images/bag.png'
import pin from '../../assets/images/pin.png'
import satisfaction from '../../assets/images/satisfaction.png'

import expansao from '../../assets/images/expansao.png'

//Funcional SVG's
import Dollar from '../../assets/svgs/dollar'
import CreditCard from '../../assets/svgs/credit-card'
import Framer from '../../assets/svgs/framer'
import { FaArrowRight, FaArrowDown } from 'react-icons/fa'

//Components
import Nav from './components/Nav'
import Budget from './components/Budget'
import Doubts from './components/Doubts'

//Global fuction
import Footer from '../../components/Footer'

import { useNavigate } from "react-router-dom";

import { setCity, findState } from '../../utils'

//Actions 
import { setSimulate } from '../../redux/actions'

import { HelpersAdress, HelpersBranchs } from '../../helpers'

const helpersAdress = new HelpersAdress()
const helpersBranchs = new HelpersBranchs()

function Home(props) {

   const user = useSelector(state => state.Auth.user)
   const states = useSelector(state => state.Auth.states)
   const simulate = useSelector(state => state.Simulate.simulate)

   const [branchs, setBranchs] = React.useState([]);

   const navigate = useNavigate();
   const dispatch = useDispatch();


   useEffect(() => {
      const listBranchs = async () => {

         helpersBranchs.ListBranchs().then((resp) => {
            Promise.all(resp.data.data.map(async (item) =>
            ({
               ...item,
               state_name: findState(states, item.state_id),
               city_name: await findCity(item.state_id, item.city_id),
            })
            )).then((newProductList) => {
               setBranchs(newProductList)
            });
         })
      }
      listBranchs()
   }, [states])


   const findCity = async (state_id, city_id) => {
      const city = await helpersAdress.ListCities(state_id).then(resp => {
         const nome = setCity(resp.data.data, city_id)
         return nome
      })
      let cit = city
      return cit
   }


   return (
      <div className='w-full'>
         <div className='bg-primary'>
            <Nav
               user={user}
               navegar={(value) => navigate(value)}
            />
         </div>
         <Budget
            branchs={branchs}
            simulate={simulate}
            setSimulate={(values) => dispatch(setSimulate(values))}
            user={user}
         />

         <div className='m-40 max-w-6xl mx-auto px-5'>
            <div className='mb-10'>
               <h1 className='text-[25px] font-bold'>Malas do tamanho da sua necessidade</h1>
               <p>Possuímos em quase todo Brasil malas de todos os tamanhos para todas as necessidades</p>
            </div>

            <div className='flex justify-between flex-wrap align-center gap-5'>
               <div className='flex flex-col items-center mx-auto'>
                  <img src={bag1} alt='img' />
                  <h4 className='text-10xl font-semibold'>Mala G</h4>
                  <div className='flex'><h4 className='font-semibold'>Material:</h4>&nbsp;ABS</div>
                  <div className='flex'><h4 className='font-semibold'>Dimensões:</h4>&nbsp; 77x50x31</div>
                  <div className='flex'><h4 className='font-semibold'>Capacidade em volume:</h4>&nbsp; 30kg</div>
               </div>
               <div className='flex flex-col items-center mx-auto'>
                  <img src={bag2} alt='img' />
                  <h4 className='text-10xl font-semibold'>Mala M</h4>
                  <div className='flex'><h4 className='font-semibold'>Material:</h4>&nbsp;ABS</div>
                  <div className='flex'><h4 className='font-semibold'>Dimensões:</h4>&nbsp; 66x45x27</div>
                  <div className='flex'><h4 className='font-semibold'>Capacidade em volume:</h4>&nbsp; 23kg</div>
               </div>
               <div className='flex flex-col items-center mx-auto'>
                  <img src={bag3} alt='img' />
                  <h4 className='text-10xl font-semibold'>Mala P</h4>
                  <div className='flex'><h4 className='font-semibold'>Material:</h4>&nbsp;ABS</div>
                  <div className='flex'><h4 className='font-semibold'>Dimensões:</h4>&nbsp; 50x37x36</div>
                  <div className='flex'><h4 className='font-semibold'>Capacidade em volume:</h4>&nbsp; 10kg</div>
               </div>
            </div>
         </div>

         <div className='max-w-6xl m-40 mx-auto px-5 hidden sm:block'>
            <p className='mb-0 text-[25px] font-bold'>Veja como é facil alugar malas na Malug</p>
            <h6 className='mt-0 font-medium'>O processo é simples, veja:</h6>
            <div className='flex justify-between my-10 flex-wrap items-center'>
               <div style={{
                  backgroundImage: `url(${calendar})`,
                  backgroundSize: 'contain   ',
                  backgroundPosition: 'center',
                  backgroundRepeat: 'no-repeat',
                  height: '331px',
                  width: '212px',
               }} className='flex pt-40 px-5'>
                  <p className='font-medium text-white text-center'>
                     Faça a reserva on-line, escolha a melhor data pra entrega e devolução. Pagando via pix e cartão de crédito.
                  </p>
               </div>
               <FaArrowRight size={30} color='#22a699' />
               <div style={{
                  backgroundImage: `url(${pin})`,
                  backgroundSize: 'contain   ',
                  backgroundPosition: 'center',
                  backgroundRepeat: 'no-repeat',
                  height: '331px',
                  width: '212px',
               }} className='flex pt-40 px-5 mt-5'>
                  <p className='font-medium text-white text-center'>
                     Receba as malas no seu endereço, e nós coletamos também na devolução. Sem preocupação ao receber ou entregar.
                  </p>
               </div>
               <FaArrowRight size={30} color='#22a699' />
               <div style={{
                  backgroundImage: `url(${bag})`,
                  backgroundSize: 'contain   ',
                  backgroundPosition: 'center',
                  backgroundRepeat: 'no-repeat',
                  height: '331px',
                  width: '212px',
               }} className='flex pt-40 px-5 mt-5'>
                  <p className='font-medium text-white text-center'>
                     Desfrute da viagem com malas alugadas e com a cobertura do seguro mala contra danos, estravio e roubos.
                  </p>
               </div>
               <FaArrowRight size={30} color='#22a699' />
               <div style={{
                  backgroundImage: `url(${satisfaction})`,
                  backgroundSize: 'contain   ',
                  backgroundPosition: 'center',
                  backgroundRepeat: 'no-repeat',
                  height: '331px',
                  width: '212px',
               }} className='flex pt-40 px-5 mt-5'>
                  <p className='font-medium text-white text-center'>
                     Finalizado o aluguel, você não gastou comprando malas caras, e só pagou pelos dias que usou.
                  </p>
               </div>
            </div>
         </div>

         <div className='max-w-6xl m-20 mx-auto px-5 block sm:hidden '>
            <p className='mb-0 text-[25px] font-bold'>Veja como é fácil alugar malas na Malug</p>
            <h6 className='mt-0 font-medium'>Veja o passo a passo:</h6>
            
            <div className='flex flex-col items-center gap-3 my-10'>
               <div style={{
                  backgroundImage: `url(${calendar})`,
                  backgroundSize: 'contain   ',
                  backgroundPosition: 'center',
                  backgroundRepeat: 'no-repeat',
                  height: '361px',
                  width: '242px',
               }} className='flex pt-40'>
                  <p className='font-medium text-white text-center mx-5'>
                     Faça a reserva on-line, escolha a melhor data pra entrega e devolução. Pagando via pix e cartão de crédito.
                  </p>
               </div>
               <FaArrowDown size={20} color='#22a699' />
               <div style={{
                  backgroundImage: `url(${pin})`,
                  backgroundSize: 'contain   ',
                  backgroundPosition: 'center',
                  backgroundRepeat: 'no-repeat',
                  height: '361px',
                  width: '242px',
               }} className='flex pt-40'>
                  <p className='font-medium text-white text-center mx-5'>
                     Receba as malas no seu endereço, e nós coletamos também na devolução. Sem preocupação ao receber ou entregar.
                  </p>
               </div>
               <FaArrowDown size={20} color='#22a699' />
               <div style={{
                  backgroundImage: `url(${bag})`,
                  backgroundSize: 'contain   ',
                  backgroundPosition: 'center',
                  backgroundRepeat: 'no-repeat',
                  height: '361px',
                  width: '242px',
               }} className='flex pt-40'>
                  <p className='font-medium text-white text-center mx-5'>
                     Desfrute da viagem com malas alugadas e com a cobertura do seguro mala contra danos, estravio e roubos.
                  </p>
               </div>
               <FaArrowDown size={20} color='#22a699' />
               <div style={{
                  backgroundImage: `url(${satisfaction})`,
                  backgroundSize: 'contain   ',
                  backgroundPosition: 'center',
                  backgroundRepeat: 'no-repeat',
                  height: '361px',
                  width: '242px'
               }} className='flex pt-40'>
                  <p className='font-medium text-white text-center mx-5'>
                     Finalizado o aluguel, você não gastou comprando malas caras, e só pagou pelos dias que usou.
                  </p>
               </div>
            </div>
            
         </div>

         <div className='max-w-6xl mx-auto px-5 m-20'>
            <p className='mb-0 text-[25px] font-bold'>Reservar com a Malug é fácil demais!</p>
            <h6 className='mt-0 font-medium '>Conheça as vantagens de alugar suas malas com a gente</h6>
            <div className='flex justify-around my-10 flex-wrap'>
               <div className='bg-primary w-[285px] h-[63px] rounded rounded-2xl flex flex-row items-center justify-around my-3'>
                  <CreditCard />
                  <p className='text-white font-medium text-[18px]'>Pagamento facilitado</p>
               </div>
               <div className='bg-primary w-[285px] h-[63px] rounded rounded-2xl flex flex-row items-center justify-around my-3'>
                  <Framer />
                  <p className='text-white font-medium text-[18px]'>Rapidez na entrega</p>
               </div>
               <div className='bg-primary w-[285px] h-[63px] rounded rounded-2xl flex flex-row items-center justify-around mt-3'>
                  <Dollar />
                  <p className='text-white font-medium text-[18px]'>Melhor custo benefício</p>
               </div>
            </div>
         </div>

         <Doubts />

         <div className=' 
            hidden
            sm:block
            sm:flex 
            justify-around 
            items-center 
            bg-primary 
            shadow-lg 
            max-w-6xl 
            mx-auto 
            h-[244px]
            rounded
            rounded-3xl
            mt-[180px]
            mb-[250px]'>
            <div className='h-48 flex flex-col justify-between'>
               <div>
                  <h1 className='text-white font-bold' >Estamos pertinho de você! S2</h1>
                  <p className='text-white font-medium text-[18px]'>Confira se já chegamos em sua cidade.</p>
               </div>
               <div className='flex flex-row items-center'>
                  <p className='text-white font-bold text-[20px] uppercase mr-3'>Estamos em plena expansão</p>
               </div>
            </div>
            <Image src={expansao} w={700} h={540} />
         </div>

         <div className='
            block
            sm:hidden
            flex 
            flex-col
            justify-between 
            p-5 
            align-center 
            bg-primary 
            shadow-lg 
            max-w-5xl 
            mx-5
            gap-3
            rounded-xl
            my-10'
         >
            <div className='h-[32rem] flex flex-col justify-between items-center'>
               <div>
                  <h1 className='text-white font-bold' >Estamos pertinho de você! S2</h1>
                  <p className='text-white font-medium text-[18px]'>Confira se já chegamos em sua cidade.</p>
               </div>
               <Image src={expansao} w={330} />
               <div className='flex flex-col items-center justify-between'>
                  <div className='flex flex-row items-center '>
                     <p className='text-white font-bold text-[20px] uppercase mr-3'>Estamos em plena expansão</p>
                  </div>
               </div>
            </div>
         </div>

         {/*<div className='my-20'>
            <Carousel 
               showArrows={false}
               showIndicators={true} 
               showThumbs={false} 
               showStatus={false}
               swipeable={true} 
               infiniteLoop
               autoPlay
               emulateTouch
               dynamicHeight={true}
            >
               <div style={{ 
                  backgroundImage: `url(${background})`,
                  height: '569px',
                  backgroundRepeat: 'no-repeat',
                  justifyContent: 'flex-end',
                  paddingBottom: '50px'
               }} className='max-w-6xl mx-auto flex flex-col '>
                     <h1 className='text-lg text-white font-bold text-start pl-5 md:pl-20 '>Conheça as melhores cidades para férias em 2022</h1>
                     <p className=' text-white text-start pl-5 md:pl-20 py-3'>Aproveite também a alta temporada para alugar a sua mala com a Malug</p>
                     <a href="/" className='text-white'>
                        <p className='text-start pl-5 md:pl-20 font-bold'>
                           Ler mais...
                        </p>
                     </a>
               </div>
               <div style={{ 
                  backgroundImage: `url(${background})`,
                  height: '569px',
                  backgroundRepeat: 'no-repeat',
                  justifyContent: 'flex-end',
                  paddingBottom: '50px'
               }} className='max-w-6xl mx-auto flex flex-col '>
                     <h1 className='text-lg text-white font-bold text-start pl-5 md:pl-20 '>Conheça as melhores cidades para férias em 2022</h1>
                     <p className=' text-white text-start pl-5 md:pl-20 py-3'>Aproveite também a alta temporada para alugar a sua mala com a Malug</p>

                     <a href="/" className='text-white'>
                        <p className='text-start pl-5 md:pl-20 font-bold'>
                           Ler mais...
                        </p>
                     </a>
               </div>
            </Carousel>
         </div> */}



         <div className='bg-[#EAEAEA] py-10 px-5'>
            <div className='max-w-6xl mx-auto flex items-center justify-between flex-wrap gap-3'>
               <div className='flex flex-col justify-center'>
                  <h2 className='font-bold'>Inscreva-se para receber ofertas exclusivas</h2>
                  <h6>Receba informações relevantes sobre viagens, ofertas e benefícios<br /> exclusivos</h6>
               </div>
               <div className='flex justify-center'>
                  <Input placeholder='Insira aqui seu e-mail' />
                  <button className="ml-3 cursor-pointer mx-auto bg-secondary rounded-xl text-white px-3 font-medium">Enviar</button>
               </div>
            </div>
         </div>
         <Footer />
      </div>
   )
}

export default Home
