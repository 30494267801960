import React, { useEffect } from 'react'
import { Divider } from '@mantine/core'

import Spinner from '../../assets/svgs/spinner'

import { HelpersAuth } from '../../helpers'
const helpersAuth = new HelpersAuth()

export default function ForgotPassword({ showModal, setTypeModal, typeModal }) {
   const [email, setEmail] = React.useState('');
   const [loadingPassword, setLoadingPassword] = React.useState(false)
   const [erroPassword, setErroPassword] = React.useState(false)

   useEffect(() => {
      setEmail('');
   }, [showModal, typeModal, erroPassword])

   const handleSubmit = (e) => {
      e.preventDefault();

      setLoadingPassword(true)

      helpersAuth.sendEmailForgot(email)
         .then(resp => {
            setTypeModal('resetPassword');
            setLoadingPassword(false);
         }).catch(err => {
            setLoadingPassword(false);
            setErroPassword(true);
            setTimeout(() => {
               setErroPassword(false);
            }, 3000);
         })
   }

   return (
      <div className="py-6 px-6 lg:px-8 text-left">
         <h3 className="mb-4 text-xl font-bold text-gray-900">
            Email pra redefinição de senha
         </h3>
         <Divider />
         <form className="space-y-6 pt-4" onSubmit={handleSubmit}>
            <div>
               <label
                  className="block mb-2 text-sm font-semibold text-gray-900"
               >
                  Seu email:
               </label>
               <input
                  type="email"
                  name="email"
                  id="email"
                  className="bg-gray-50 border border-gray-300 
                    text-gray-900 text-sm rounded-lg  block w-full 
                    p-2.5 border-solid border-2 focus:border-default-orange outline-none"
                  placeholder="nome@email.com"
                  required
                  value={email}
                  onChange={e => setEmail(e.target.value)}
                  disabled={loadingPassword}
               />
            </div>
            {
               erroPassword ? <div className='w-full bg-red-300 rounded-lg py-2 px-3'>
                  <p className="text-red-600">
                     Email inválido
                  </p>
               </div> : null
            }
            <button
               type="submit"
               className="w-full text-white bg-default-orange hover:bg-default-blue
                 border-none font-semibold rounded-lg text-base font-semibold px-5 py-2.5
                 inline-flex items-center justify-center cursor-pointer"
               disabled={loadingPassword}
            >
               {loadingPassword ? <> <Spinner /> Enviando email </> : 'Enviar email'}
            </button>
         </form>
      </div>
   )
}
