import moment from 'moment';
import React, { useEffect, useState } from 'react';

import Modal from '../../components/modal'
import { Divider } from '@mantine/core'
import { setCity } from '../../../../utils'
import { statusBooking } from "../../../../components/renderStatusBooking";
import { statusPayment } from '../../../../components/renderStatusPayment';
import PaginatedItems from '../../../../components/pagination'

import { HelpersAdress, HelpersBookings } from '../../../../helpers';
const helpersBookings = new HelpersBookings()
const helpersAdress = new HelpersAdress()

const Reservas = ({ user }) => {
   const [bookings, setBookings] = useState([])
   const [booking, setBooking] = useState({})
   const [idBooking, setIdBooking] = useState(0)
   const [showModal, setShowModal] = useState(false)
   const [filial, setFilial] = useState("")
   const [records, setRecords] = useState(0)

   useEffect(() => {
      const ListBookings = (page = 0) => {
         helpersBookings.Listbookings(page).then(resp => {
            setRecords(resp.data.data.total)
            Promise.all(resp.data.data.data.map(async (item) =>
            ({
               ...item,
               city_name: await findCity(item.state_id, item.city_id),
            })
            )).then((newProductList) => {
               setBookings(newProductList)
            });
         })
      }
      
      ListBookings() 
   }, [])
   
   useEffect(() => {
      const details = () => {
         helpersBookings.DetailsBooking(idBooking).then(resp => {
            setBooking(resp.data.data)
            findCity(resp.data.data.state_id, resp.data.data.city_id).then(resp => {
               setFilial(resp)
            });
         })
      }

      idBooking > 0 && details()
   }, [idBooking])

   const ListBookings = (page = 0) => {
      helpersBookings.Listbookings(page).then(resp => {
         setRecords(resp.data.data.total)
         Promise.all(resp.data.data.data.map(async (item) =>
         ({
            ...item,
            city_name: await findCity(item.state_id, item.city_id),
         })
         )).then((newProductList) => {
            setBookings(newProductList)
         });
      })
   }
   
   const findCity = async (state_id, city_id) => {
      const city = await helpersAdress.ListCities(state_id).then(resp => {
         const nome = setCity(resp.data.data, city_id)
         return nome
      })
      let cit = city
      return cit
   }

   return (
      <div>
         <div className='hidden md:block'>
            <p className='text-2xl font-bold text-gray-900 mx-10 mt-10'><i className="bi bi-file-earmark-fill"></i> Minhas reservas</p>
            <Divider className='my-5 mx-10'/>
            <div className='overflow-auto rounded-lg shadow mx-10'>
               <table className='w-full'>
                  <thead className="bg-gray-50 border-b-2 border-gray-200 sticky top-0">
                     <tr className=''>
                        <th className='w-1/9 p-3 text-sm font-semibold tracking-wide text-left'>NO.</th>
                        <th className='w-1/9 p-3 text-sm font-semibold tracking-wide text-left'>FILIAL</th>
                        <th className='w-1/9 p-3 text-sm font-semibold tracking-wide text-left'>COMPREI EM</th>
                        <th className='w-1/9 p-3 text-sm font-semibold tracking-wide text-left'>RECEBER</th>
                        <th className='w-1/9 p-3 text-sm font-semibold tracking-wide text-left'>DEVOLVER</th>
                        <th className='w-1/9 p-3 text-sm font-semibold tracking-wide text-left'>VALOR</th>
                        <th className='w-1/9 p-3 text-sm font-semibold tracking-wide text-left'>PAGAMENTO</th>
                        <th className='w-1/9 p-3 text-sm font-semibold tracking-wide text-left'>STATUS</th>
                     </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-100">
                     {
                        bookings.map((book, index) => {
                           return(
                              <tr className='bg-white' key={index.toString()}>
                                 <td className="p-3 text-sm text-gray-700 whitespace-nowrap">
                                    <button 
                                       onClick={() => {setIdBooking(book.id); setShowModal(true)}}
                                       className="font-bold border-none bg-transparent text-blue-500 hover:underline"
                                    >
                                       {book.id}
                                    </button>
                                 </td>
                                 <td className="p-3 text-sm font-semibold text-gray-700 whitespace-nowrap">
                                    {book.city_name}
                                 </td>
                                 <td className="p-3 text-sm text-gray-700 whitespace-nowrap">
                                    {moment(book.created_at).format("ll")}
                                 </td>
                                 <td className="p-3 text-sm text-gray-700 whitespace-nowrap">
                                    {moment(book.check_in).format("DD/MM/YY")}
                                 </td>
                                 <td className="p-3 text-sm text-gray-700 whitespace-nowrap">
                                    {moment(book.check_out).format("DD/MM/YY")}
                                 </td>
                                 <td className="p-3 text-sm text-gray-700 whitespace-nowrap">
                                    R$ {book.total_value}
                                 </td>
                                 <td className="p-3 text-sm text-gray-700 whitespace-nowrap">
                                    {statusPayment(book.payment_method, book.status)}
                                 </td>
                                 <td className="p-3 text-sm text-gray-700 whitespace-nowrap">
                                    {statusBooking(book.reserve_status)}
                                 </td>
                              </tr>
                           )   
                        })
                     }
                  </tbody>
               </table>
            </div>
         </div>

         <div className='grid grid-cols-1 sm:grid-cols-2 gap-4 md:hidden mx-2'>
               <p className='text-2xl font-bold text-gray-900 mt-10'><i className="bi bi-file-earmark-fill"></i> Reservas:</p>
            <Divider />
            {
               bookings.map((book, index) => (
                  <div
                     className='bg-slate-100 space-y-3 p-4 rounded-lg shadow' 
                     onClick={() => {setIdBooking(book.id); setShowModal(true)}}
                     key={index.toString()}
                  >
                     <div className='flex items-center space-x-2 text-sm justify-between'>
                        <div>
                           <p className='text-blue-500 font-bold hover:underline'>{book.id}</p>
                        </div>
                        <div className='flex'>
                           {moment(book.created_at).format("ll")}
                        </div>
                        <div>
                           {statusBooking(book.reserve_status)}
                        </div>
                     </div>
                     <div className='flex text-sm text-gray-700'>
                        <div className='text-gray-900'>Entrega: {moment(book.check_in).format("DD/MM/YY")}</div>
                        <p>&nbsp;-&nbsp;</p>
                        <div className='text-gray-900'>Devolução {moment(book.check_out).format("DD/MM/YY")}</div>
                     </div>
                     <div className='text-sm text-gray-900'>
                        Unidade: {book.city_name}
                     </div>
                     <div className='text-sm text-gray-700'>
                        {statusPayment(book.payment_method)}
                     </div>
                     <div className='flex items-center space-x-2 text-sm justify-between'>
                        <div className='flex items-center text-sm font-medium text-black'>
                           R$ {book.total_value} &nbsp; {statusPayment(book.payment_method, book.status)}
                        </div>
                     </div>
                  </div>
               ))
               
            }
         </div>

         <div className='flex flex-row justify-end w-full h-[100px] items-center px-5'>
            <PaginatedItems itemsPerPage={15} setPg={(page) => ListBookings(page)} qtdItems={records}/>
         </div>

         <Modal isVisible={showModal} onClose={() => setShowModal(false)} overflow="inside">
            <div className='w-[300px] lg:w-[600px] xl:w-[1000px] hidden sm:block'>
               <header className='h-36 flex flex-row justify-between  items-center p-2'>
                  <img src={require("../../../../assets/images/malugOrange.png")} alt="img"  width="150"/>
                  <div>
                     <p className='text-sm'>
                     Malug - Aluguel de Malas
                     </p>
                     <br/>
                     <p className='flex justify-end text-xs'>
                        Rua Vitorino Carmilo, N°: S/N <br />
                        65110-000 - São José de Ribamar, MA <br />
                        Loja: Loja Física
                     </p>
                  </div>
               </header>
               <body>
                  <h3 className='text-center my-5 font-semibold'>Ordem de serviço N° {booking.id}</h3>
                  <div className="w-full flex flex-row">
                     <div className='w-2/5'>
                        <h3 className='font-semibold'>Cliente:</h3>
                        <div className='border border-slate-300 h-36 flex items-center pl-3 mr-2'>
                           <p className='text-sm'>
                              {user.name} <br/>
                              {user.cpf} <br/>
                              {user.address?.street}, {user.address?.number} / {user.address?.complement} <br/>
                              {user.address?.district} <br/>
                              CEP: {user.address?.cep} <br/>
                              Tel: {user.phone}
                           </p>
                        </div>
                     </div>
                     <div className='w-3/5 '>
                        <div className='h-36 mt-6 flex flex-row'>
                           <div className="flex flex-col flex-1">
                              <div className='flex flex-1 border-b-0 border-r-0 border border-black-300 items-center pl-2'>
                                 Unidade
                              </div>
                              <div className='flex flex-1 border-r-0 border border-slate-300 items-center pl-2'>
                                 {filial}
                              </div>
                           </div>
                           <div className="flex flex-col flex-1">
                              <div className='flex flex-1 border-b-0 border-r-0 border border-black-300 items-center pl-2'>
                                 Data de recebimento
                              </div>
                              <div className='flex flex-1 border-r-0 border border-slate-300 items-center pl-2'>
                                 {moment(booking.check_in).format('DD/MM/YY')}
                              </div>
                           </div>
                           <div className="flex flex-col flex-1">
                              <div className='flex flex-1 border-b-0  border border-slate-300 items-center pl-2'>
                                 Data de devolução 
                              </div>
                              <div className='flex flex-1 border border-slate-300 items-center pl-2'>
                                 {moment(booking.check_out).format('DD/MM/YY')}
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div className='mt-6'>
                     <h3 className='font-semibold'>Malas:</h3>
                     {
                        booking.bags?.map((item, index) => (
                           <div className='mb-3 w-full flex items-center' key={index.toString()}>
                              <div className="w-full">
                                 <table className='w-full'>
                                    <thead className="bg-gray-100">
                                       <tr>
                                          <th className='p-2 text-sm font-semibold tracking-wide text-left'>Itens</th>
                                          <th className='p-2 text-sm font-semibold tracking-wide text-left'>Marca / Modelo</th>
                                          <th className='p-2 text-sm font-semibold tracking-wide text-left'>Cor</th>
                                          <th className='p-2 text-sm font-semibold tracking-wide text-left'>Valor Un</th>
                                          <th className='p-2 text-sm font-semibold tracking-wide text-left'>Quantidade de diárias</th>
                                          <th className='p-2 text-sm font-semibold tracking-wide text-left'>Total</th>
                                       </tr>
                                    </thead>
                                    <tbody className="divide-y divide-gray-100">
                                       <tr>
                                          <td className='p-2 text-sm text-gray-700 whitespace-nowrap border border-slate-300'>Mala {item.size_name}</td>
                                          <td className='p-2 text-sm text-gray-700 whitespace-nowrap border border-slate-300'>{item.brand} - {item.model}</td>
                                          <td className='p-2 text-sm text-gray-700 whitespace-nowrap border border-slate-300'>{item.color}</td>
                                          <td className='p-2 text-sm text-gray-700 whitespace-nowrap border border-slate-300'>R$ {item.size_price}</td>
                                          <td className='p-2 text-sm text-gray-700 whitespace-nowrap border border-slate-300'>{booking.days}</td>
                                          <td className='p-2 text-sm text-gray-700 whitespace-nowrap border border-slate-300'>R$ {(parseFloat(item.size_price) * booking.days).toFixed(2)}</td>
                                       </tr>
                                       <tr>
                                          <td className='p-2 text-sm text-gray-700 whitespace-nowrap border border-slate-300'>Seguro</td>
                                          <td className='p-2 text-sm text-gray-700 whitespace-nowrap border border-slate-300'></td>
                                          <td className='p-2 text-sm text-gray-700 whitespace-nowrap border border-slate-300'></td>
                                          <td className='p-2 text-sm text-gray-700 whitespace-nowrap border border-slate-300'>R$ {item.secure_value}</td>
                                          <td className='p-2 text-sm text-gray-700 italic whitespace-nowrap border border-slate-300'>{booking.days} (Não se aplica ao cálculo)</td>
                                          <td className='p-2 text-sm text-gray-700 whitespace-nowrap border border-slate-300'>R$ {item.secure_value}</td>
                                       </tr>
                                       <tr>
                                          <td></td>
                                          <td></td>
                                          <td></td>
                                          <td></td>
                                          <td></td>
                                          <td className='p-2 text-sm text-gray-700 whitespace-nowrap border border-slate-300'>R$ {((parseFloat(item.size_price) * booking.days) + parseInt(item.secure_value)).toFixed(2)}   </td>
                                       </tr>
                                    </tbody>
                                 </table>
                              </div>
                           </div>
                        ))
                     }
                  </div>
                  <div>
                     <h3 className='font-semibold'>Totais:</h3>
                     <div className='h-20 flex flex-row'>
                        <div className="flex flex-col flex-1">
                           <div className='flex flex-1 border-b-0 border-r-0 border border-slate-300 items-center pl-2'>
                           Total em malas:
                           </div>
                           <div className='flex flex-1 border-r-0 border border-slate-300 items-center pl-2'>
                              R${(parseFloat(booking.total_value) - booking.delivery_value).toFixed(2)}
                           </div>
                        </div>
                        <div className="flex flex-col flex-1">
                           <div className='flex flex-1 border-b-0  border border-slate-300 items-center pl-2'>
                              Frete: 
                           </div>
                           <div className='flex flex-1 border border-slate-300 items-center pl-2'>
                              R$ {booking.delivery_value}.00
                           </div>
                        </div>
                        <div className="flex flex-col flex-1">
                           <div className='flex flex-1 border-b-0  border border-slate-300 items-center pl-2'>
                              Total do pedido:
                           </div>
                           <div className='flex flex-1 border border-slate-300 items-center pl-2'>
                              R$ {booking.total_value}
                           </div>
                        </div>
                     </div>
               </div> 
                  <div className='my-6'>
                     <h3 className='font-semibold'>Dados de pagamento:</h3>
                     <div className='h-20 flex flex-row'>
                        <div className="flex flex-col flex-1">
                           <div className='flex flex-1 border-b-0 border-r-0 border border-slate-300 items-center pl-2'>
                              Metodo de pagamento:
                           </div>
                           <div className='flex flex-1 border-r-0 border border-slate-300 items-center pl-2'>
                              {booking.payment_method === 'credit_card' ? 'Cartão de crédito' : null}
                              {booking.payment_method === 'pix' ?  'Pix' : null}
                           </div>
                        </div>
                        <div className="flex flex-col flex-1">
                           <div className='flex flex-1 border-b-0  border border-slate-300 items-center pl-2'>
                              Pagamento:
                           </div>
                           <div className='flex flex-1 border border-slate-300 items-center pl-2'>
                              {statusPayment(booking.payment_method, booking.status)}
                           </div>
                        </div>
                        <div className="flex flex-col flex-1">
                           <div className='flex flex-1 border-b-0  border border-slate-300 items-center pl-2'>
                              Status do pedido:
                           </div>
                           <div className='flex flex-1 border border-slate-300 items-center pl-2'>
                              {statusBooking(booking.reserve_status)}
                           </div>
                        </div>
                     </div>
                  </div>
                  <div className='mt-20'>
                     <p className='text-xs'>Concordo com os termos acima:</p>
                     <div className=' flex flex-row justify-between'>
                        <div>
                           <p>Data: _____ /_____ /______</p>
                        </div>
                        <div> 
                           ________________________________________
                           <p className='text-xs text-center'>Assinatura do responsável</p>
                        </div>
                     </div>
                  </div>
               </body>
            </div>

            <div className='h-[90%] bg-white m-5 block sm:hidden'>
               <div className='h-36 flex flex-row justify-between items-center p-2'>
                  <img src={require("../../../../assets/images/malugOrange.png")} alt="img"  width="80"/>
                  <div>
                     <p className='text-sm'>
                        Malug - Aluguel <br/> de Malas LTDA
                     </p>
                  </div>
               </div>
               <h3 className='text-center my-5 font-semibold'>Ordem de serviço N° {booking.id}</h3>
               <div>
                  <div className="w-full flex flex-col">
                     <div className=''>
                        <h3 className='font-semibold'>Cliente:</h3>
                        <div className='border border-slate-300 h-36 flex items-center px-2'>
                           <p className='text-sm'>
                              {user.name} <br/>
                              {user.cpf} <br/>
                              {user.address?.street}, {user.address?.number} <br/> 
                              {user.address?.complement} <br/>
                              {user.address?.district} <br/>
                              CEP: {user.address?.cep} <br/>
                              Tel: {user.phone}
                           </p>
                        </div>
                     </div>
                     <div className=''>
                        <div className='h-24 flex flex-row'>
                           <div className="flex flex-col flex-1">
                              <div className='flex flex-1 border-b-0 border-r-0 border border-black-300 items-center pl-2'>
                                 Filial
                              </div>
                              <div className='flex flex-1 border-r-0 border border-slate-300 items-center pl-2'>
                                 {filial}
                              </div>
                           </div>
                           <div className="flex flex-col flex-1">
                              <div className='flex flex-1 border-b-0 text-sm border-r-0 border border-black-300 items-center pl-2'>
                                 Receber
                              </div>
                              <div className='flex flex-1 border-r-0 text-sm text-sm border border-slate-300 items-center pl-2'>
                                 {moment(booking.check_in).format('DD/MM/YY')}
                              </div>
                           </div>
                           <div className="flex flex-col flex-1">
                              <div className='flex flex-1 border-b-0 text-sm text-sm border border-slate-300 items-center pl-2'>
                                 Devolver 
                              </div>
                              <div className='flex flex-1 border text-sm border-slate-300 items-center pl-2'>
                                 {moment(booking.check_out).format('DD/MM/YY')}
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div className='mt-6'>
                     <h3 className='font-semibold'>Malas:</h3>
                     {
                        booking.bags?.map((item, index) => (
                           <div className='shadow-lg my-4 p-3 rounded-lg' key={index.toString()}>
                              Mala {item.size_name} <br />
                              {item.brand} - {item.model} <br />
                              Cor: {item.color} <br />
                              Valor Un: R$ {item.size_price} <br />
                              Valor Seguro: R$ {item.secure_value} <br />
                              Qtd de diárias: {booking.days} <br />
                              Total: R$ {((parseFloat(item.size_price) * booking.days) + parseInt(item.secure_value)).toFixed(2)}
                           </div>
                        ))
                     }
                  </div>
                  <div>
                     <h3 className='font-semibold'>Totais:</h3>
                     <div className='h-20 flex flex-row'>
                        <div className="flex flex-col flex-1">
                           <div className='flex flex-1 text-sm border-b-0 border-r-0 border border-slate-300 items-center pl-2'>
                           Malas
                           </div>
                           <div className='flex flex-1 text-sm border-r-0 border border-slate-300 items-center pl-2'>
                           R${(parseFloat(booking.total_value) - booking.delivery_value).toFixed(2)}
                           </div>
                        </div>
                        <div className="flex flex-col flex-1">
                           <div className='flex flex-1 text-sm border-b-0  border border-slate-300 items-center pl-2'>
                              Frete
                           </div>
                           <div className='flex flex-1 text-sm border border-slate-300 items-center pl-2'>
                              R$ {booking.delivery_value}.00
                           </div>
                        </div>
                        <div className="flex flex-col flex-1">
                           <div className='flex flex-1 text-sm border-b-0  border border-slate-300 items-center pl-2'>
                           Total
                           </div>
                           <div className='flex flex-1 text-sm border border-slate-300 items-center pl-2'>
                              R$ {booking.total_value}
                           </div>
                        </div>
                     </div>
                  </div>
                  <div className='my-6'>
                     <h3 className='font-semibold'>Dados de pagamento:</h3>
                     <div className='h-24 flex flex-row'>
                        <div className="flex flex-col flex-1">
                           <div className='flex flex-1 text-sm border border-b-0 border-slate-300 items-center pl-2'>
                              {booking.payment_method === 'credit_card' ? 'Cartão de crédito:' : null}
                              {booking.payment_method === 'pix' ?  'Pix:' : null}
                              &nbsp;
                              {statusPayment(booking.payment_method, booking.status)}
                           </div>
                           <div className='flex flex-1 text-sm border border-slate-300 items-center pl-2'>
                              Pedido:
                              &nbsp;
                              {statusBooking(booking.reserve_status)}
                           </div>
                        </div>
                     </div>
                  </div>
                  <div className='mt-3'>
                     <p className='text-xs pb-4'>Concordo com os termos acima:</p>
                     <div className=' flex flex-col justify-between items-center'>
                        <div>
                           <p>Data: _____ /_____ /______</p>
                        </div>
                        <div> 
                           ________________________
                           <p className='text-xs text-center'>Assinatura do responsável</p>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </Modal>
      </div>
   )
}

export default Reservas



