import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";

const Sidebar = ({children, screen, logout}) => {
   const[isOpen, setIsOpen] = useState(false);
   const toggle = () => setIsOpen (!isOpen);
   const navigate = useNavigate();

   useEffect(() => {
      const isMobile = /(iPhone|Android|Windows Phone)/i.test(navigator.userAgent);
      if (isMobile) {
         setIsOpen(false)
      } else {
         setIsOpen(true)
      }
   }, [])
   

   const menuItem=[
      {
         name:"Dados pessoais",
         label: "dados-pessoais",
         icon: <i className="bi bi-person-lines-fill"></i>,
         action: (rota) => navigate(`/conta/${rota}`)
      },
      {
         name:"Meus pedidos",
         label: "reservas",
         icon: <i className="bi bi-list-check"></i>,
         action: (rota) => navigate(`/conta/${rota}`)	
      },
      {
         name:"Pagamento",
         label: "pagamento",
         icon: <i className="bi bi-credit-card"></i>,
         action: (rota) => navigate(`/conta/${rota}`)
      },
      {
         name:"Logout",
         label: "logout",
         icon: <i className="bi bi-box-arrow-in-right"></i>,
         action: () => logout()
      }
   ]

   return (
      <div className="flex w-screem h-full bg-slate-50">
         <div style={{width: isOpen ? "240px" : "50px"}} className="sidebar bg-primary">
            <div className="top_section ">
               <img 
                  src={require('../../../assets/images/malug.png')} 
                  onClick={() => navigate('/')} 
                  height="50" 
                  width="150" 
                  style={{display: isOpen ? "block" : "none"}} 
                  alt='img'
                  className='cursor-pointer px-2'
               /> 
               <div style={{marginLeft: isOpen ? "10px" : "0px"}} className="bars">
                  {
                     isOpen ? <i className="bi bi bi-caret-left-fill px-2 rounded-md" onClick={toggle}></i>
                     : <i className="bi bi bi-caret-right-fill px-2 rounded-md" onClick={toggle}></i> 
                  }
               </div>
            </div>
            {
               menuItem.map((item, index)=>(
                  <div className={`p-2.5 mt-3 flex items-center 
                     rounded-md  px-4 duration-300 cursor-pointer 
                     text-white bg-${item.label === screen ? "default-blue" : null}`}
                     onClick={() => item.action(item.label)}
                     key={index}
                  >
                     {item.icon}
                     <div style={{display: isOpen ? "block" : "none", marginLeft: 10}}>
                        <span className="text-[15px] text-white font-bold">{item.name}</span>
                     </div>
                  </div>
               ))
            }
         </div>
         <main>{children}</main>
      </div>
   );
};

export default Sidebar;