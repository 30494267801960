import React, { useState, useEffect } from 'react';
import { Button, Divider } from '@mantine/core'
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { 
   valid, 
   formataCPF, 
   formatarTelefone, 
   formatarCep, 
   formataData, 
   states, 
   setCity, 
   validateCPF,
   isValidDate
} from '../../../../utils';
import moment from 'moment'
import Spinner from '../../../../assets/svgs/spinner'

import DetailsAddress from './components/detailsAddress';

import { HelpersAdress } from '../../../../helpers';
import DetailsData from './components/detailsData';
const helpersAdress = new HelpersAdress()

const DadosPessoais = ({ user, updateUser, error, loading }) => {
   const [citys, setCitys] = useState([]);
   const [cityName, setCityName] = useState('');
   const [editAddress, setEditAdrress] = useState(false)
   const [editData, setEditData] = useState(false)

   const AddressSchema = Yup.object().shape({
   name: Yup.string()
      .min(2, "No mínimo 2 caracteres")
      .max(30, "No máximo 30 caracteres")
      .required("O nome é obrigatório"),
   cpf: Yup.string()
      .required("O CPF é obrigatório")
      .test('is-valid-cpf', 'CPF inválido', value => validateCPF(value.replace(/\D/g, ''))), // Remove caracteres não numéricos para validação
   rg: Yup.string()
      .required('RG é obrigatório')
      .test('is-valid-rg', 'RG inválido', value => {
         if (!value) return false;
         const cleanValue = value.replace(/\D/g, '');
         return /^\d{9}$/.test(cleanValue) || /^\d{1,2}\.\d{3}\.\d{3}-\d{1,2}$/.test(value);
      }),
   email: Yup.string().email("Email inválido").required("O email é obrigatório"),
   phoneNumber: Yup.string()
      .required("O telefone é obrigatório")
      .matches(/^\d{10,11}$/, "Telefone inválido"),
   birthDate: Yup.string()
      .required('A data é obrigatória')
      .test('is-valid-date', 'Data inválida', (value) => {
      if (!value) return false;
      return isValidDate(value);
      }),
   cep: Yup.string()
      .matches(/^\d{8}$/, "CEP inválido")
      .required("O CEP é obrigatório"),
   street: Yup.string()
      .required("A rua é obrigatória"),
   state: Yup.string()
      .required("O estado é obrigatório"),
   city: Yup.string()
      .required("A cidade é obrigatória"),
   district: Yup.string()
      .required("O bairro é obrigatório"),
   complement: Yup.string()
      .notRequired(), // Opcional, não é obrigatório
   number: Yup.string()
      .required("O número é obrigatório"),
   });

   const formik = useFormik({
      validationSchema: AddressSchema,
      enableReinitialize: true,
      validateOnBlur: false,
      validateOnChange: true,
      initialValues: {
         name: user?.name,
         cpf: user?.cpf,
         rg: valid(user.rg) ? user.rg : '',
         email: user?.email,
         phoneNumber: valid(user.phone) ? user.phone : '',
         birthDate: valid(user.birthdate) ? moment(user.birthdate).format('DDMMYYYY') : '',
         cep: valid(user.address) ? user.address.cep : '',
         street: valid(user.address) ? user.address.street : '',
         state: valid(user.address) ? user.address.state_id : 1,
         city: valid(user.address) ? user.address.city_id : '',
         district: valid(user.address) ? user.address.district : '',
         complement: valid(user.address) ? user.address.complement : '',
         number: valid(user.address) ? user.address.number : ''
      },
      onSubmit: values => {
         const usuario = {
            id: user.id,
            profile_id: 3,
            name: user.name,
            email: user.email,
            cpf: user.cpf,
            phone: values.phoneNumber, // Sem mascara certo !!
            rg: values.rg, //Sem mascara certo !!
            birthdate: moment(values.birthDate, 'DDMMYYYY').format('DD/MM/YYYY'), // Com mascara certo !!
            city_id: values.city,
            state_id: values.state,
            cep: values.cep, // Sem mascara
            street: values.street,
            number: values.number,
            district: values.district,
            complement: values.complement
         }
         updateUser(usuario)
      },
   });

   
   useEffect(() => {
      const findCity = async () => {
         try {
            const resp = await helpersAdress.ListCities(user.address.state_id);
            setCitys(resp.data.data);
            const nome = setCity(resp.data.data, user.address.city_id)
            if (user.address) {
               setCityName(nome)
            }
         } catch (error) {
            console.error('Failed to fetch cities:', error);
         }
      };

      if (user.address) findCity();
   }, [user.address]);

   const findCity = async (sta) => {
      try {
         const resp = await helpersAdress.ListCities(sta);
         setCitys(resp.data.data);
      } catch (error) {
         setCitys([])
      }
   };

   useEffect(() => {
      setEditAdrress(false)
      setEditData(false)
   }, [user])

   return (
      <form className="space-y-6" onSubmit={formik.handleSubmit}>
         <div className='hidden sm:block'>
            <p className='text-2xl font-bold text-gray-900 mx-10 mt-10'><i className="bi bi-person-circle"></i>Dados Pessoais:</p>
            <Divider className='my-5 mx-10'/>
            <div className='shadow py-5 mx-10 rounded-md bg-white'>
               {
                  editData ? <>
                        <div className='grid grid-cols-3'>
                        <div className='ml-5 mr-2'>
                           <label  className="block mb-2 text-sm font-semibold text-gray-900">
                              Nome: <span className='text-[red]'>*</span>
                           </label>
                           <input
                              type="text"
                              name="name"
                              id="name"
                              className="bg-gray-50 border border-gray-300 
                              text-gray-900 text-sm rounded-lg  block w-full 
                              p-2.5 border-solid border-2 focus:border-default-orange outline-none"
                              placeholder="Digite seu nome" 
                              disabled
                              value={formik.values.name}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                           />    
                           {formik.touched.name && formik.errors.name ? (
                              <p className='text-red-500 text-xs italic'>{formik.errors.name}</p>
                           ) : null}
                        </div>

                        <div className='ml-2 mr-2 '>
                           <label 
                              className="block mb-2 text-sm font-semibold text-gray-900"
                           >
                              CPF: <span className='text-[red]'>*</span>
                           </label>
                           <input
                              type="text"
                              name="cpf"
                              id="cpf"
                              className="bg-gray-50 border border-gray-300 
                              text-gray-900 text-sm rounded-lg  block w-full 
                              p-2.5 border-solid border-2 focus:border-default-orange outline-none"
                              placeholder="Digite seu CPF" 
                              disabled
                              maxLength={14}
                              value={formataCPF(formik.values.cpf)} // Aplica a formatação para exibição
                              onChange={(e) => formik.setFieldValue('cpf', e.target.value.replace(/\D/g, ''))} // Atualiza valor sem formatação para validação
                              onBlur={formik.handleBlur}
                           />    
                           {formik.touched.cpf && formik.errors.cpf ? (
                              <p className='text-red-500 text-xs italic'>{formik.errors.cpf}</p>
                           ) : null}
                        </div>

                        <div className='ml-2 mr-5'>
                           <label 
                              className="block mb-2 text-sm font-semibold text-gray-900"
                           >
                              RG: <span className='text-[red]'>*</span>
                           </label>
                           <input
                              type="text"
                              name="rg"
                              id="rg"
                              className="bg-gray-50 border border-gray-300 
                              text-gray-900 text-sm rounded-lg  block w-full 
                              p-2.5 border-solid border-2 focus:border-default-orange outline-none"
                              placeholder="Digite seu RG" 
                              maxLength={9}
                              value={formik.values.rg}
                              onChange={(e) => formik.setFieldValue('rg', e.target.value.replace(/\D/g, ''))}
                              onBlur={formik.handleBlur}
                           />  
                           {formik.touched.rg && formik.errors.rg ? (
                              <p className='text-red-500 text-xs italic'>{formik.errors.rg}</p>
                           ) : null}  
                        </div>
                     </div>

                     <div className='grid grid-cols-4 mt-3'>
                        <div className='ml-5 mr-2 col-span-2'>
                           <label 
                              className="block mb-2 text-sm font-semibold text-gray-900"
                           >
                              Email:<span className='text-[red]'>*</span>
                           </label>
                           <input
                              type="email"
                              name="email"
                              id="email"
                              className="bg-gray-50 border border-gray-300 
                              text-gray-900 text-sm rounded-lg  block w-full 
                              p-2.5 border-solid border-2 focus:border-default-orange outline-none"
                              placeholder="Digite seu email"
                              disabled
                              value={formik.values.email}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                           />    
                           {formik.touched.email && formik.errors.email ? (
                              <p className='text-red-500 text-xs italic'>{formik.errors.email}</p>
                           ) : null}
                        </div>
                        <div className='ml-2 mr-2'>
                           <label 
                              className="block mb-2 text-sm font-semibold text-gray-900"
                           >
                              Telefone:<span className='text-[red]'>*</span>
                           </label>
                           <input
                              type="text"
                              name="phoneNumber"
                              id="phoneNumber"
                              className="bg-gray-50 border border-gray-300 
                              text-gray-900 text-sm rounded-lg  block w-full 
                              p-2.5 border-solid border-2 focus:border-default-orange outline-none"
                              placeholder="Digite seu telefone" 
                              maxLength={14}
                              minLength={14}
                              value={formatarTelefone(formik.values.phoneNumber)}
                              onChange={(e) => formik.setFieldValue('phoneNumber', e.target.value.replace(/\D/g, ''))}
                              onBlur={formik.handleBlur}
                           />    
                           {formik.touched.phoneNumber && formik.errors.phoneNumber ? (
                              <p className='text-red-500 text-xs italic'>{formik.errors.phoneNumber}</p>
                           ) : null}
                        </div>
                        <div className='ml-2 mr-5'>
                           <label 
                              className="block mb-2 text-sm font-semibold text-gray-900"
                           >
                              Data de nascimento:
                           </label>
                           <input
                              type="text"
                              name="birthDate"
                              id="birthDate"
                              className="bg-gray-50 border border-gray-300 
                              text-gray-900 text-sm rounded-lg  block w-full 
                              p-2.5 border-solid border-2 focus:border-default-orange outline-none"
                              placeholder="Digite sua data de nascimento" 
                              maxLength={10}
                              value={formataData(formik.values.birthDate)}
                              onChange={(e) => formik.setFieldValue('birthDate', e.target.value.replace(/\D/g, ''))}
                              onBlur={formik.handleBlur}
                           />    
                           {formik.touched.birthDate && formik.errors.birthDate ? (
                              <p className='text-red-500 text-xs italic'>{formik.errors.birthDate}</p>
                           ) : null}
                        </div>
                     </div>
                     <div className='flex justify-end mt-10 mx-10 gap-3'>
                        <Button className="cursor-pointer" color='red' onClick={() => setEditData(false)}>
                           Cancelar
                        </Button>
                        <Button className="cursor-pointer bg-secondary" type="submit">
                           { loading ? <> <Spinner />Salvando</> : 'Salvar' }
                        </Button>
                     </div>
                  </>
                  :
                  <>
                     <div className="flex justify-between mx-5 mb-5">
                        <i className="bi bi-person-circle"></i>
                        <button 
                           className="font-medium border-none bg-transparent text-blue-500 hover:underline text-sm"
                           onClick={() => setEditData(true)}
                        >
                           Editar
                        </button>
                     </div>
                     <DetailsData 
                        name={user.name}
                        cpf={user.cpf}
                        rg={user.rg}
                        email={user.email}
                        telefone={user.phone}
                        birthdate={valid(user.birthdate) ? moment(user.birthdate).format('DD/MM/YYYY') : ''}
                     />
                  </>
               }
            </div>

            <p className='mb-4 text-2xl font-bold text-gray-900 mx-10 mt-10'><i className="bi bi-geo-alt-fill"></i>Endereço:</p>
            <Divider className='my-5 mx-10'/>
            
            <div className='shadow py-5 mx-10 rounded-md bg-white'>
               {
                  editAddress ?
                  <>
                     <div className='grid grid-cols-4'>
                        <div className='ml-5 mr-2 col-span-1'>
                           <label 
                              className="block mb-2 text-sm font-semibold text-gray-900"
                           >
                              CEP:<span className='text-[red]'>*</span>
                           </label>
                           <input
                              type="text"
                              name="cep"
                              id="cep"
                              className="bg-gray-50 border border-gray-300 
                              text-gray-900 text-sm rounded-lg  block w-full 
                              p-2.5 border-solid border-2 focus:border-default-orange outline-none"
                              placeholder="Digite seu CEP" 
                              maxLength={9}
                              value={formatarCep(formik.values.cep)}
                              onChange={(e) => formik.setFieldValue('cep', e.target.value.replace(/\D/g, ''))}
                              onBlur={formik.handleBlur}
                           /> 
                           {formik.touched.cep && formik.errors.cep ? (
                              <p className='text-red-500 text-xs italic'>{formik.errors.cep}</p>
                           ) : null}   
                        </div>
                        
                        <div className='ml-2 mr-2 col-span-2'>
                           <label className="block mb-2 text-sm font-semibold text-gray-900">
                              Endereço:<span className='text-[red]'>*</span>
                           </label>
                           <input
                              type="text"
                              name="street"
                              id="street"
                              className="bg-gray-50 border border-gray-300 
                              text-gray-900 text-sm rounded-lg  block w-full 
                              p-2.5 border-solid border-2 focus:border-default-orange outline-none"
                              placeholder="Digite sua rua" 
                              value={formik.values.street}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                           />    
                           {formik.touched.street && formik.errors.street ? (
                              <p className='text-red-500 text-xs italic'>{formik.errors.street}</p>
                           ) : null} 
                        </div>

                        <div className='ml-2 mr-10'>
                           <label className="block mb-2 text-sm font-semibold text-gray-900">
                              Estado:<span className='text-[red]'>*</span>
                           </label>
                           <select
                              type="text"
                              name="state"
                              id="state"
                              className="bg-gray-50 border border-gray-300 
                              text-gray-900 text-sm rounded-lg  block w-full 
                              p-3 border-solid border-2 focus:border-default-orange outline-none"
                              placeholder="Selecione seu estado" 
                              value={formik.values.state}
                              onChange={(e) => {
                                 formik.setFieldValue('state', e.target.value)
                                 formik.setFieldValue('city', '')
                                 findCity(e.target.value)
                              }}
                              onBlur={formik.handleBlur}
                           >  
                             <option value="">Selecione o estado</option>
                              {states.map((item, index) => (
                                 <option value={item.id} key={index.toString()}>{item.name}</option>
                              ))}
                           </select>
                           {formik.touched.state && formik.errors.state ? (
                              <p className='text-red-500 text-xs italic'>{formik.errors.state}</p>
                           ) : null}
                        </div>
                     </div>

                     <div className='grid grid-cols-9 mt-3'>
                        <div className='ml-5 mr-2 col-span-2'>
                           <label className="block mb-2 text-sm font-semibold text-gray-900">
                              Cidade:<span className='text-[red]'>*</span>
                           </label>
                           <select
                              type="text"
                              name="city"
                              id="city"
                              className="bg-gray-50 border border-gray-300 
                              text-gray-900 text-sm rounded-lg  block w-full 
                              p-3 border-solid border-2 focus:border-default-orange outline-none"
                              value={formik.values.city}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                           >  
                              <option value="">Selecione a cidade</option>
                              {citys.map((item, index) => (
                                 <option value={item.id} key={index.toString()}>{item.name}</option>
                              ))}
                           </select>  
                           {formik.touched.city && formik.errors.city ? (
                              <p className='text-red-500 text-xs italic'>{formik.errors.city}</p>
                           ) : null}     
                        </div>
                        <div className='ml-2 mr-2 col-span-3'>
                           <label className="block mb-2 text-sm font-semibold text-gray-900">
                              Bairro:<span className='text-[red]'>*</span>
                           </label>
                           <input
                              type="text"
                              name="district"
                              id="district"
                              className="bg-gray-50 border border-gray-300 
                              text-gray-900 text-sm rounded-lg  block w-full 
                              p-2.5 border-solid border-2 focus:border-default-orange outline-none"
                              placeholder="Digite seu bairro" 
                              value={formik.values.district}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                           />    
                           {formik.touched.district && formik.errors.district ? (
                              <p className='text-red-500 text-xs italic'>{formik.errors.district}</p>
                           ) : null} 
                        </div>
                        <div className='ml-2 mr-2 col-span-3'>
                           <label className="block mb-2 text-sm font-semibold text-gray-900">
                              Complemento:<span className='text-[red]'>*</span>
                           </label>
                           <input
                              type="text"
                              name="complement"
                              id="complement"
                              className="bg-gray-50 border border-gray-300 
                              text-gray-900 text-sm rounded-lg  block w-full 
                              p-2.5 border-solid border-2 focus:border-default-orange outline-none"
                              placeholder="Digite seu bairro" 
                              value={formik.values.complement}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                           />    
                           {formik.touched.complement && formik.errors.complement ? (
                              <p className='text-red-500 text-xs italic'>{formik.errors.complement}</p>
                           ) : null} 
                        </div>
                        <div className='ml-2 mr-10'>
                           <label 
                              className="block mb-2 text-sm font-semibold text-gray-900"
                           >
                              Número:<span className='text-[red]'>*</span>
                           </label>
                           <input
                              type="text"
                              name="number"
                              id="number"
                              className="bg-gray-50 border border-gray-300 
                              text-gray-900 text-sm rounded-lg  block w-full 
                              p-2.5 border-solid border-2 focus:border-default-orange outline-none"
                              placeholder="Digite o número da sua casa" 
                              value={formik.values.number}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                           />    
                           {formik.touched.number && formik.errors.number ? (
                              <p className='text-red-500 text-xs italic'>{formik.errors.number}</p>
                           ) : null}
                        </div>
                     </div>
                     <div className='flex justify-end mt-10 mx-10 gap-3'>
                        <Button className="cursor-pointer" color='red' onClick={() => setEditAdrress(false)}>
                           Cancelar
                        </Button>
                        <Button className="cursor-pointer bg-secondary" type="submit">
                           { loading ? <> <Spinner />Salvando</> : 'Salvar' }
                        </Button>
                     </div>   
                  </>
                  :
                  <>
                     <div className="flex justify-between mx-5 mb-5">
                        <i className="bi bi-geo-alt-fill"></i>
                        <button 
                           className="font-medium border-none bg-transparent text-blue-500 hover:underline text-sm"
                           onClick={() => setEditAdrress(true)}
                        >
                           Editar
                        </button>
                     </div>
                     <DetailsAddress
                        cep={formik.values.cep}
                        rua={formik.values.street}
                        state={formik.values.state}
                        city={cityName}
                        district={formik.values.district}
                        complement={formik.values.complement}
                        number={formik.values.number}
                     /> 
                  </>
               } 
            </div>
         </div>

         <div className="block sm:hidden space-y-3 mx-2">
            <p className='text-2xl font-bold text-gray-900 mt-10'><i className="bi bi-person-circle"></i>Dados Pessoais:</p>
            <Divider />
            <div>
               <input
                  type="text"
                  name="name"
                  id="name"
                  className="bg-gray-50 border border-gray-300 
                  text-gray-900 text-sm rounded-lg focus:ring-blue-500 
                  focus:border-blue-500 block w-full p-2.5"
                  placeholder="Digite seu nome" 
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
               />    
               {formik.touched.name && formik.errors.name ? (
                  <p className='text-red-500 text-xs italic'>{formik.errors.name}</p>
               ) : null}
            </div>
            <div>
               <input
                  type="text"
                  name="cpf"
                  id="cpf"
                  className="bg-gray-50 border border-gray-300 
                  text-gray-900 text-sm rounded-lg block w-full 
                  p-2.5 border-solid border-2 focus:border-default-orange outline-none"
                  placeholder="Digite seu CPF" 
                  maxLength={14}
                  disabled
                  value={formataCPF(formik.values.cpf)} // Aplica a formatação para exibição
                  onChange={(e) => formik.setFieldValue('cpf', e.target.value.replace(/\D/g, ''))} // Atualiza valor sem formatação para validação
                  onBlur={formik.handleBlur}
               />    
               {formik.touched.cpf && formik.errors.cpf ? (
                  <p className='text-red-500 text-xs italic'>{formik.errors.cpf}</p>
               ) : null}
            </div>
            <div>
               <input
                  type="text"
                  name="rg"
                  id="rg"
                  className="bg-gray-50 border border-gray-300 
                  text-gray-900 text-sm rounded-lg  block w-full 
                  p-2.5 border-solid border-2 focus:border-default-orange outline-none"
                  placeholder="Digite seu RG" 
                  maxLength={12}
                  value={formik.values.rg}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
               />    
               {formik.touched.rg && formik.errors.rg ? (
                  <p className='text-red-500 text-xs italic'>{formik.errors.rg}</p>
               ) : null}  
            </div>
            <div>
               <input
                  type="email"
                  name="email"
                  id="email"
                  className="bg-gray-50 border border-gray-300 
                  text-gray-900 text-sm rounded-lg  block w-full 
                  p-2.5 border-solid border-2 focus:border-default-orange outline-none"
                  placeholder="Digite seu email" 
                  disabled={true}
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
               />    
               {formik.touched.email && formik.errors.email ? (
                  <p className='text-red-500 text-xs italic'>{formik.errors.email}</p>
               ) : null}
            </div>
            <div>
               <input
                  type="text"
                  name="phoneNumber"
                  id="phoneNumber"
                  className="bg-gray-50 border border-gray-300 
                  text-gray-900 text-sm rounded-lg  block w-full 
                  p-2.5 border-solid border-2 focus:border-default-orange outline-none"
                  placeholder="Digite seu telefone" 
                  maxLength={16}
                  minLength={16}
                  value={formatarTelefone(formik.values.phoneNumber)}
                  onChange={(e) => formik.setFieldValue('phoneNumber', e.target.value.replace(/\D/g, ''))}
                  onBlur={formik.handleBlur}
               />    
               {formik.touched.phoneNumber && formik.errors.phoneNumber ? (
                  <p className='text-red-500 text-xs italic'>{formik.errors.phoneNumber}</p>
               ) : null}
            </div>
            <div>
               <input
                  type="text"
                  name="birthDate"
                  id="birthDate"
                  className="bg-gray-50 border border-gray-300 
                  text-gray-900 text-sm rounded-lg  block w-full 
                  p-2.5 border-solid border-2 focus:border-default-orange outline-none"
                  placeholder="Digite sua data de nascimento" 
                  maxLength={10}
                  minLength={10}
                  value={formataData(formik.values.birthDate)}
                  onChange={(e) => formik.setFieldValue('birthDate', e.target.value.replace(/\D/g, ''))}
                  onBlur={formik.handleBlur}
               />  
               {formik.touched.birthDate && formik.errors.birthDate ? (
                  <p className='text-red-500 text-xs italic'>{formik.errors.birthDate}</p>
               ) : null}  
            </div>
           

            <p className='mb-4 text-2xl font-bold text-gray-900 pt-10'><i className="bi bi-geo-alt-fill"></i> Endereço:</p>
            <Divider className='my-5'/>
            <div>
               <input
                  type="text"
                  name="cep"
                  id="cep"
                  className="bg-gray-50 border border-gray-300 
                  text-gray-900 text-sm rounded-lg  block w-full 
                  p-2.5 border-solid border-2 focus:border-default-orange outline-none"
                  placeholder="Digite seu CEP" 
                  maxLength={9}
                  minLength={9}
                  value={formatarCep(formik.values.cep)}
                  onChange={(e) => formik.setFieldValue('cep', e.target.value.replace(/\D/g, ''))}
                  onBlur={formik.handleBlur}
               />    
               {formik.touched.cep && formik.errors.cep ? (
                  <p className='text-red-500 text-xs italic'>{formik.errors.cep}</p>
               ) : null} 
            </div>
            <div>
               <input
                  type="text"
                  name="street"
                  id="street"
                  className="bg-gray-50 border border-gray-300 
                  text-gray-900 text-sm rounded-lg  block w-full 
                  p-2.5 border-solid border-2 focus:border-default-orange outline-none"
                  placeholder="Digite sua rua" 
                  value={formik.values.street}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
               />    
               {formik.touched.street && formik.errors.street ? (
                  <p className='text-red-500 text-xs italic'>{formik.errors.street}</p>
               ) : null}
            </div>
            <div>
               <select
                  type="text"
                  name="state"
                  id="state"
                  className="bg-gray-50 border border-gray-300 
                  text-gray-900 text-sm rounded-lg  block w-full 
                  p-2.5 border-solid border-2 focus:border-default-orange outline-none"
                  placeholder="Selecione seu estado" 
                  value={formik.values.state}
                  onChange={(e) => {
                     formik.setFieldValue('state', e.target.value)
                     formik.setFieldValue('city', '')
                     findCity(e.target.value)
                  }}
                  onBlur={formik.handleBlur}
               >  
                  <option value="">Selecione o estado</option>
                  {states.map((item, index) => (
                     <option value={item.id} key={index.toString()}>{item.name}</option>
                  ))}
               </select>
               {formik.touched.state && formik.errors.state ? (
                  <p className='text-red-500 text-xs italic'>{formik.errors.state}</p> 
               ) : null}
            </div>
            <div>
               <select
                  type="text"
                  name="city"
                  id="city"
                  className="bg-gray-50 border border-gray-300 
                  text-gray-900 text-sm rounded-lg  block w-full 
                  p-2.5 border-solid border-2 focus:border-default-orange outline-none"
                  placeholder="Selecione sua cidade" 
                  value={formik.values.city}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
               >  
                  <option value="" >Selecione a cidade</option>
                  {
                     citys.map((item, index) => 
                        <option value={item.id} key={index.toString()}>{item.name}</option>
                     )
                  }
               </select>    
               {formik.touched.city && formik.errors.city ? (
                  <p className='text-red-500 text-xs italic'>{formik.errors.city}</p>
               ) : null}  
            </div>
            <div>
               <input
                  type="text"
                  name="district"
                  id="district"
                  className="bg-gray-50 border border-gray-300 
                  text-gray-900 text-sm rounded-lg  block w-full 
                  p-2.5 border-solid border-2 focus:border-default-orange outline-none"
                  placeholder="Digite seu bairro" 
                  value={formik.values.district}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
               />    
               {formik.touched.district && formik.errors.district ? (
                  <p className='text-red-500 text-xs italic'>{formik.errors.district}</p>
               ) : null} 
            </div>
            <div>
               <input
                  type="text"
                  name="complement"
                  id="complement"
                  className="bg-gray-50 border border-gray-300 
                  text-gray-900 text-sm rounded-lg  block w-full 
                  p-2.5 border-solid border-2 focus:border-default-orange outline-none"
                  placeholder="Digite seu bairro" 
                  value={formik.values.complement}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
               />    
               {formik.touched.complement && formik.errors.complement ? (
                  <p className='text-red-500 text-xs italic'>{formik.errors.complement}</p>
               ) : null} 
            </div>
            <div>
               <input
                  type="text"
                  name="number"
                  id="number"
                  className="bg-gray-50 border border-gray-300 
                  text-gray-900 text-sm rounded-lg block w-full 
                  p-2.5 border-solid border-2 focus:border-default-orange outline-none"
                  placeholder="Digite o número da sua casa" 
                  value={formik.values.number}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
               />    
               {formik.touched.number && formik.errors.number ? (
                  <p className='text-red-500 text-xs italic'>{formik.errors.number}</p>
               ) : null}
            </div>
            <div className='flex pb-10 justify-end'>
               <Button className="cursor-pointer bg-secondary self-end" type="submit">
                  { loading ? <> <Spinner />Salvando</> : 'Salvar' }
               </Button>
            </div>
         </div>

         {
            error ? <div className='bg-red-300 rounded-lg p-2 mx-10'>
               <p className="text-red-600">
                  {error.message  ===
                     'The birthdate does not match the format d/m/Y.' ?
                     'A data de aniversário é inválida' : null
                  }
                  {error.message  ===
                     'O campo cpf não é um CPF válido.' ?
                     'O CPF é inválido' : null
                  }
                  {error.message  ===
                     'O campo cpf não é um CPF válido. (and 1 more error)' ?
                     'O CPF é inválido' : null
                  }
               </p>
            </div> : null
         }
      </form>
   );
};

export default DadosPessoais;