import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Divider } from '@mantine/core'
import { useFormik } from 'formik';
import * as Yup from 'yup';
import * as authActions from '../../redux/actions';

import Spinner from '../../assets/svgs/spinner'

export default function Login({ typeModal, setTypeModal, web }) {

   const dispatch = useDispatch()

   const loading = useSelector(state => state.Auth.loadingLogin)
   const error = useSelector(state => state.Auth.erroLogin)

   useEffect(() => {

   }, [typeModal, error])

   const SignInSchema = Yup.object().shape({
      email: Yup.string().email("Email inválido").required("O email é obrigatório"),
      password: Yup.string()
        .min(6, "A senha deve ter no mínimo 6 caracteres")
        .required("A senha é obrigatória"),
     
    });

   const formik = useFormik({
      validationSchema: SignInSchema, // Corrigido aqui
      validateOnBlur: false,
      validateOnChange: true,
      initialValues: {
         email: '',
         password: ''
      },
      onSubmit: values => {
         // Altere esta linha para a ação de dispatch correta
         dispatch(authActions.loginUser(values.email, values.password));
      },
   });


   return (
      <div className="py-6 px-6 lg:px-8 text-left" >
         <h3 className="mb-4 text-xl font-bold text-gray-900">
            Fazer login em nossa plataforma
         </h3>
         <Divider />
         <form className="space-y-6 mt-4" onSubmit={formik.handleSubmit}>
            <div>
               <label
                  className="block mb-2 text-sm font-semibold text-gray-900"
               >
                  Seu email:
               </label>
               <input
                  type="email"
                  name="email"
                  id="email"
                  autoComplete="email"
                  className="bg-gray-50 border border-gray-300 
                     text-gray-900 text-sm rounded-lg  block w-full 
                     p-2.5 border-solid border-2 focus:border-default-orange outline-none"
                  placeholder="nome@email.com"
                  minLength={6}
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  disabled={loading}
               />
               {formik.touched.email && formik.errors.email ? (
                  <p className='text-red-500 text-xs italic'>{formik.errors.email}</p>
               ) : null}
            </div>
            <div>
               <label
                  className="block mb-2 text-sm font-semibold text-gray-900"
               >
                  Sua senha:
               </label>
               <input
                  type="password"
                  name="password"
                  id="password"
                  autoComplete="current-password"
                  className="bg-gray-50 border border-gray-300 
                     text-gray-900 text-sm rounded-lg block w-full 
                     p-2.5 border-solid border-2 focus:border-default-orange outline-none"
                  placeholder="*********"
                  minLength={6}
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  disabled={loading}
               />
                {formik.touched.password && formik.errors.password ? (
                  <p className='text-red-500 text-xs italic'>{formik.errors.password}</p>
               ) : null}
            </div>
            <div className="flex justify-between">
               <div className="flex items-start">
                  <div className="flex items-center h-5">
                     <input
                        id="remember"
                        type="checkbox"
                        value=""
                        className="w-4 h-4 bg-gray-50 rounded border-none"
                     />
                  </div>
                  <label
                     className="ml-2 text-sm font-semibold text-gray-900"
                  >
                     Lembrar-me
                  </label>
               </div>
               <p className="text-sm text-default-orange hover:underline" onClick={() => setTypeModal('forgotPassword')}>
                     Esqueci a senha
               </p> 
            </div>
            {
               error ? <div className='w-full bg-red-300 rounded-lg py-2 px-3'>
                  <p className="text-red-600">
                     Email e/ou senha estão incorreta(as)
                  </p>
               </div> : null
            }
            <button
               type="submit"
               className="w-full text-white bg-default-orange hover:bg-default-blue
                 border-none font-semibold rounded-lg text-base font-semibold px-5 py-2.5
                 inline-flex items-center justify-center cursor-pointer"
               disabled={loading}
            >
               { loading ? <> <Spinner /> Fazendo login</> : 'Fazer login' }
            </button>
            {
               web && <div className="text-sm font-semibold text-gray-500">
                  Não tem conta?{" "}
                  <button className="bg-transparent border-0 text-sm font-semibold text-default-orange hover:underline cursor-pointer" onClick={() => setTypeModal('subscribe')}>
                     Criar uma conta
                  </button>
               </div> 
            }
         </form>
      </div>
   )
}
