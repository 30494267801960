import React, { useEffect, useState } from 'react';
import './account.css';
import { useNavigate, useParams } from "react-router-dom";
import Sidebar from './components/sidebar';

import PersonalData from './items/PersonalData';
import Reservas from './items/Bookings';
import Cards from './items/Cards';

import { connect } from "react-redux";

import { logoutUser, updateUser } from '../../redux/actions'

const Account = (props) => {
   const { user, logoutUser, updateUser, error, loadingLogin } = props
   const { route } = useParams();
   const navigate = useNavigate();

   const [screen, setScreen] = useState(route);

   useEffect(() => {
      setScreen(route)
   }, [route])


   const renderScreen = {
      'dados-pessoais': <PersonalData
         user={user}
         updateUser={(values) => updateUser(values)}
         error={error}
         loading={loadingLogin}
      />,
      'reservas': <Reservas user={user} />,
      'pagamento': <Cards />
   }


   return (
      <Sidebar screen={screen} logout={() => logoutUser(navigate)}>
         {renderScreen[screen]}
      </Sidebar>
   );
};

const mapStateToProps = (state) => {
   const { user, loadingLogin, error } = state.Auth;
   return { user, loadingLogin, error };
};

export default connect(mapStateToProps, { logoutUser, updateUser })(Account);

