/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/heading-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import Card from "react-credit-cards";
import { Divider } from '@mantine/core'


import ModalComponent from '../../../../components/Modal'
import Add from '../../../../assets/svgs/plus-circle'

import {  
   formatCreditCardNumber,
   formatCVC,
   formatExpirationDate,
} from '../../../../utils';

import { toast } from 'react-toastify'

import { HelpersPayments } from '../../../../helpers';
const helpersPayments = new HelpersPayments()

const Cartoes = () => {
   const [cards, setCards] = useState([])
   const [showModal, setShowModal] = React.useState(false);
   const [typeModal, setTypeModal] = React.useState('addCard')
   const [loadingCard, setLoadingCard] = useState(false);
   const [error, setError] = useState(false)
   const [selectCard, setSelectCard] = useState(0)
   const [card, setCard] = React.useState({
      number: "",
      cvv: "",
      date: "",
      name: "",
      focus: ""
   })   

   
   useEffect(() => {
      const listarCartoes = () => {
         helpersPayments.ListCards().then(resp => {
            setCards(resp.data.data)
         })
      }

      listarCartoes()
   }, [])
   
   const handleFocus = (e) => {
      setCard({ ...card, focus: e.target.name })
   }

   const cadastrarCartao =(e) => {
      e.preventDefault();
      setLoadingCard(true)
      const values = {
         number: card.number.replace(' ','').replace(' ','').replace(' ', ''),
         name: card.name,
         month: card.date.substring(0, 2),
         year: '20' + card.date.substring(3, 7),
         cvv: card.cvv
      }
      helpersPayments.SaveCard(values).then(() => { 
         helpersPayments.ListCards().then(resp => {
            setShowModal(false)
            setCards(resp.data.data)
            setCard({
               number: "",
               cvv: "",
               date: "",
               name: "",
               focus: ""
            })
         })
         setLoadingCard(false)
         toast("Cartão salvo com sucesso!", {
            type: "success"
         })
      }).catch(err => {
         setError(true)
         setLoadingCard(false)
      })
   }

   const deleteCard =(e) => {
      helpersPayments.DeleteCard(selectCard).then(() => { 
         helpersPayments.ListCards().then(resp => {
            setCards(resp.data.data)
         })
         setShowModal(false)
      }).catch(err => {
         setShowModal(false)
      })
   }


   const renderModal = () => {
      switch (typeModal) {
			case 'addCard':
				return (
               <div className='rounded-lg bg-white w-max-4xl py-4'>
                  <p className='m-4 text-2xl font-bold text-gray-900'>Cadastrar novo cartão</p>
                  <Divider className='mx-4'/>
                  <form className="mx-4 flex flex-col items-center mt-8" onSubmit={cadastrarCartao}>
                  <div className='mb-4'>
                     <Card
                        cvc={card.cvv}
                        expiry={card.date}
                        name={card.name}
                        number={card.number}
                        focused={card.focus}
                        
                     />
                  </div>
                     <div className="space-y-3">
                        <div className="flex flex-col">
                           <input
                              type="tel"
                              pattern="[\d| ]{16,22}"
                              required
                              maxLength={19}
                              className="bg-gray-50 border border-gray-300 
                              text-gray-900 text-sm rounded-lg block w-full 
                              p-1.5 border-solid border-2 focus:border-default-orange outline-none"
                              onFocus={e => handleFocus(e)} 
                              name="number" 
                              placeholder='Número do cartão' 
                              value={formatCreditCardNumber(card.number)} 
                              onChange={e => setCard({ ...card, number: e.target.value })}
                           />
                        </div>
                        <div className="flex flex-col">
                           <input
                              type="text"
                              required
                              className="bg-gray-50 border border-gray-300 
                              text-gray-900 text-sm rounded-lg block w-full 
                              p-1.5 border-solid border-2 focus:border-default-orange outline-none"
                              onFocus={e => handleFocus(e)} 
                              name="name" 
                              placeholder='Nome impresso no cartão' 
                              value={card.name} 
                              onChange={e => setCard({ ...card, name: e.target.value })}
                           />
                        </div>
                        <div className="grid grid-cols-2 gap-4">
                           <div>
                              <input
                                 type="tel"
                                 name="expiry"
                                 pattern="\d\d/\d\d\"
                                 required
                                 maxLength={5}
                                 minLength={5}
                                 className="bg-gray-50 border border-gray-300 
                                 text-gray-900 text-sm rounded-lg block w-full 
                                 p-1.5 border-solid border-2 focus:border-default-orange outline-none"
                                 onFocus={e => handleFocus(e)} 
                                 placeholder='Validade MM/AA' 
                                 value={formatExpirationDate(card.date)} 
                                 onChange={e => setCard({ ...card, date: e.target.value })}
                              />
                           </div>
                           <div>
                              <input
                                 type="tel"
                                 name="cvc"
                                 pattern="\d{3,4}"
                                 required
                                 maxLength={4}
                                 className="bg-gray-50 border border-gray-300 
                                 text-gray-900 text-sm rounded-lg block w-full 
                                 p-1.5 border-solid border-2 focus:border-default-orange outline-none"
                                 onFocus={e => handleFocus(e)} 
                                 placeholder='CVV' 
                                 value={formatCVC(card.cvv)} 
                                 onChange={e => setCard({ ...card, cvv: e.target.value })}
                              />
                           </div>
                        </div>
                     </div>
                     {
                           error ? <div className='w-full bg-red-300 rounded-lg py-2 px-3'>
                              <p className="text-red-600">
                                 Não foi possível cadastrar cartão, revise os dados e tente novamente
                              </p>
                           </div> : null
                     }
                     <div className='flex justify-end '>
                        <button 
                           className="cursor-pointer bg-primary p-3 mt-5 h-[54px] text-2xl font-bold rounded-xl text-white flex items-center justify-center" 
                           type="submit" 
                           disabled={loadingCard}
                        >
                           {
                              loadingCard ? <> <svg className="w-5 h-5 mr-1  text-white animate-spin" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                 <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                 <path className="opacity-75" fill="currentColor"
                                       d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z">
                                 </path>
                              </svg>Salvando cartão</> : 'Salvar cartão'
                           }
                        </button>
                     </div>
                  </form>
               </div>
            )
         case 'deleteCard':
            return (
               <div className="py-6 px-6 lg:px-8 text-left">
                  <h3 className="mb-4 text-xl font-bold text-gray-900">
                     Exclusão de cartão
                  </h3>
                  <Divider />
                  <div className='flex justify-center items-center'>
                     <i className="bi bi-emoji-dizzy" style={{fontSize: 80, color: 'red'}}></i>
                  </div>
                  <div className='flex flex-col justify-evenly items-center gap-3'>
                     <h1 className="font-bold text-xl">Excluir cartão</h1>
                     <h1 className='text-center font-semibold '>Tem certeza que deseja excluir esse cartão</h1>
                     <button
                        onClick={() => deleteCard()}
                        className="w-full text-white bg-default-orange hover:bg-default-blue
                           border-none font-semibold rounded-lg text-base font-semibold px-5 py-2.5
                           inline-flex items-center justify-center cursor-pointer"
                     >
                        Tenho certeza 
                     </button>
                  </div>
               </div>
            )
			default:
				break;
		}
   }

   return (
      <div>
         <div className='hidden sm:block'>
            <div className='flex-row justify-between w-full flex items-center'>
                  <p className='text-2xl font-bold text-gray-900 mx-10 mt-10'><i className="bi bi-credit-card-2-back-fill"></i> Meus cartões</p>
                  <button
                     onClick={() => { setShowModal(true); setTypeModal('addCard')}}
                     className="px-4 py-1.5 text-xs font-bold uppercase gap-2
                     tracking-normal bg-default-orange hover:bg-default-blue text-white 
                     border-none rounded-2xl flex justify-center items-center mx-10 mt-10"
                  >
                     <Add />
                     Adicionar cartão
                  </button>
            </div>

            <Divider className='my-5 mx-10'/>
            
            <div className="flex flex-wrap mx-10 gap-3">
               {
                  cards.map((card, index) => (
                     <div className='flex flex-col items-end gap-3' key={index.toString()}>
                        <Card
                           name={card.client_name}
                           number={card.first_numbers+" 4444 3333 1111 "+card.final_numbers}
                           expiry="**/**"
                           cvc=""
                        />
                        <button 
                           className='bg-default-red rounded border-none px-2 py-1 text-white text-sm font-semibold'
                           onClick={() => {
                              setSelectCard(card.id)
                              setTypeModal('deleteCard')
                              setShowModal(true)
                           }}
                        >
                           Excluir
                        </button>
                     </div>
                  ))
               }
            </div>
         </div>

         <div className='block sm:hidden space-y-3 mx-2'>
            <div className='flex-row justify-between w-full flex items-center'>
               <p className='text-2xl font-bold text-gray-900 mt-10'><i className="bi bi-credit-card-2-back-fill"></i> Cartões</p>
               <button
                  onClick={() => { setShowModal(true); setTypeModal('addCard')}}
                  className="p-2.5 text-xs font-bold uppercase gap-2
                  tracking-normal bg-default-orange hover:bg-default-blue text-white 
                  border-none rounded-2xl flex justify-center items-center mt-10"
               >
                  <Add />
                  Adicionar
               </button>
            </div>

            <Divider/>
            
            <div className="flex flex-wrap  gap-3">
               {
                  cards.map((card, index) => (
                     <div className='flex flex-col items-end gap-3' key={index.toString()}>
                        <Card
                           name={card.client_name}
                           number={card.first_numbers.substring(0, 4)+"********"+card.final_numbers}
                           expiry="**/**"
                           cvc=""
                        />
                        <button 
                           className='bg-default-red rounded border-none px-2 py-1 text-white text-sm font-semibold'
                           onClick={() => {
                              setSelectCard(card.id)
                              setTypeModal('deleteCard')
                              setShowModal(true)
                           }}
                        >
                           Excluir
                        </button>
                     </div>
                  ))
               }
            </div>
         </div>
         
         <ModalComponent
            isVisible={showModal}
            onClose={() => setShowModal(false)}
            currentModal={0}
         >
            {renderModal()}
         </ModalComponent>
        
      </div>
   )
}

export default Cartoes


