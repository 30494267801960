import React from 'react'

import Footer from '../../components/Footer'
import Header from '../../components/Header'


export default function PrivacyPolicy() {
   return (
      <>
         <Header title={'Política de privacidade'} />
         <div className={`w-full pb-10 p-5 sm:pt-5  mx-auto max-w-6xl`}>
            <strong>Política de Privacidade - MALUG Brasil</strong> <br />

            <p className='indent-8'>Bem-vindo à Política de Privacidade da MALUG Brasil. Nós, na MALUG Brasil, estamos comprometidos em proteger e respeitar sua privacidade. Esta política descreve como tratamos os dados pessoais que coletamos quando você usa nossos serviços de aluguel de malas online.  </p><br />

            <p className='font-bold'>1. Dados Tratados e Coletados</p> 

            <p className='indent-8'>Ao utilizar nossos serviços, coletamos os seguintes dados pessoais:

            Nome: Para identificar o cliente e personalizar a comunicação.
            Endereço de entrega: Para garantir a entrega das malas no local especificado pelo cliente.
            Endereço de e-mail: Para comunicação sobre pedidos, confirmações e atualizações do serviço.
            Número de telefone: Para facilitar a comunicação e resolver possíveis problemas com os pedidos.
            Informações de pagamento: Para processar os pagamentos e garantir a conclusão dos pedidos.</p> <br />

            <p className='font-bold'>2. Finalidade dos Dados</p>
            <p className='indent-8'>Os dados pessoais são coletados para os seguintes propósitos: 

            Processar pedidos de aluguel de malas: Utilizamos as informações fornecidas para registrar e processar os pedidos dos clientes.
            Entregar as malas no endereço fornecido pelo cliente: O endereço de entrega é necessário para garantir que as malas sejam enviadas para o local desejado pelo cliente.
            Coletar a assinatura do contrato-recebimento: Para formalizar a transação e garantir a recepção das malas pelo cliente.
            Comunicar-se com os clientes sobre seus pedidos: Utilizamos o endereço de e-mail e número de telefone para fornecer atualizações sobre o status dos pedidos e responder a quaisquer dúvidas ou preocupações dos clientes.
            Fornecer suporte ao cliente: Para resolver problemas ou fornecer assistência relacionada aos serviços de aluguel de malas. </p> <br /> 
            <p className='font-bold'>3. Medidas de Preservação de Dados </p>

            <p className='indent-8'>A MALUG Brasil implementa medidas técnicas e organizacionais adequadas para proteger os dados pessoais dos clientes contra perda, uso indevido, acesso não autorizado, divulgação, alteração ou destruição não autorizada. Isso inclui o uso de tecnologias de segurança da informação, criptografia de dados e restrição de acesso aos dados apenas a funcionários autorizados. </p> <br />

            <p className='font-bold'>4. Compartilhamento de Dados</p> 

            <p className='indent-8'>Compartilhamos os dados pessoais dos clientes com nossos licenciados exclusivamente para a entrega das malas no endereço do cliente e para coletar a devida assinatura do contrato-recebimento. Esses licenciados são parceiros confiáveis que operam em conformidade com os padrões de privacidade e segurança de dados. </p><br />

            <p className='font-bold'>5. Canal de Atendimento e Notificação de Atualizações</p>

            <p className='indent-8'>Os clientes podem entrar em contato conosco para falar sobre seus dados pessoais através do seguinte canal de atendimento: E-mail: contato@malug.com.br <br />
            Quando atualizarmos nossa política de privacidade, os clientes serão notificados por e-mail ou através de aviso em nosso site. Essas atualizações podem incluir mudanças nos tipos de dados coletados, finalidades de processamento ou medidas de segurança. </p><br />

            <p className='font-bold'>Direitos do Cliente </p>

            <p className='indent-8'>Os clientes têm os seguintes direitos em relação aos seus dados pessoais: </p><br />

            <p><a className='font-semibold'>Direito de acesso:</a> Os clientes têm o direito de acessar os dados pessoais que coletamos sobre eles.<br /></p>
            <p><a className='font-semibold'>Direito de retificação:</a> Os clientes podem solicitar a correção ou atualização de seus dados pessoais, caso estejam imprecisos ou desatualizados.<br /></p>
            <p><a className='font-semibold'>Direito de exclusão:</a> Os clientes podem solicitar a exclusão de seus dados pessoais, sujeito a requisitos legais ou contratuais.<br /></p>
            <p><a className='font-semibold'>Direito de restrição de processamento:</a> Os clientes podem solicitar a restrição do processamento de seus dados pessoais em certas circunstâncias.<br /></p>
            <p><a className='font-semibold'>Direito de oposição:</a> Os clientes têm o direito de se opor ao processamento de seus dados pessoais em determinadas situações, como para fins de marketing direto.<br /></p>
            <p><a className='font-semibold'>Direito à portabilidade dos dados:</a> Os clientes podem solicitar a transferência de seus dados pessoais para outro provedor de serviços, em um formato estruturado e comumente usado.<br /></p>
            <p>Se você tiver alguma dúvida sobre nossa Política de Privacidade ou desejar exercer seus direitos de proteção de dados, entre em contato conosco através do e-mail fornecido acima. Estamos comprometidos em proteger sua privacidade e responder a quaisquer preocupações que você possa ter sobre o uso de seus dados pessoais.<br /></p>
         </div>
         <Footer />
      </>
   )
}
