import {
    SET_SIMULATE,
    REMOVE_SIMULATE,
} from './constants';


export const setSimulate = (simulate) => ({
    type: SET_SIMULATE,
    payload: simulate
});

export const removeSimulate = () => ({
    type: REMOVE_SIMULATE
});
