import React from 'react'
import { Divider } from '@mantine/core'

import { models } from '../../../utils'

const Budget = ({ simulate }) => {

   return (
      <>
         <div className='mr-4 space-y-4'>
            <p className='my-4 text-base font-semibold text-gray-900'>Informações do pedido:</p>
            <div className='flex flex-col'>
               {
                  simulate.items.map((item, index) => (
                     <div className='my-5 w-full flex  items-center' key={index.toString()}>
                        {item.size_id ? <img width={80} height={90} src={models.find(el => el.value === item.size_id).image} alt="img" /> : null}
                        <div className="w-full mx-5">
                           <table className='w-full'>
                              <thead className="bg-gray-100">
                                 <tr>
                                    <th className='p-2 text-sm font-semibold tracking-wide text-left'>Itens</th>
                                    <th className='p-2 text-sm font-semibold tracking-wide text-left'>Valor Un</th>
                                    <th className='p-2 text-sm font-semibold tracking-wide text-left'>Quantidade de malas</th>
                                    <th className='p-2 text-sm font-semibold tracking-wide text-left'>Quantidade de diárias</th>
                                    <th className='p-2 text-sm font-semibold tracking-wide text-left'>Total</th>
                                 </tr>
                              </thead>
                              <tbody className="divide-y divide-gray-100">
                                 <tr>
                                    <td className='p-2 text-sm text-gray-700 whitespace-nowrap border border-slate-300'>Mala {item.size_name}</td>
                                    <td className='p-2 text-sm text-gray-700 whitespace-nowrap border border-slate-300'>R$ {item.size_price}</td>
                                    <td className='p-2 text-sm text-gray-700 whitespace-nowrap border border-slate-300'>{item.quantity}</td>
                                    <td className='p-2 text-sm text-gray-700 whitespace-nowrap border border-slate-300'>{item.number_daily}</td>
                                    <td className='p-2 text-sm text-gray-700 whitespace-nowrap border border-slate-300'>R$ {(parseFloat(item.size_price) * item.quantity * item.number_daily).toFixed(2)}</td>
                                 </tr>
                                 <tr>
                                    <td className='p-2 text-sm text-gray-700 whitespace-nowrap border border-slate-300'>Seguro</td>
                                    <td className='p-2 text-sm text-gray-700 whitespace-nowrap border border-slate-300'>R$ {item.secure_value}</td>
                                    <td className='p-2 text-sm text-gray-700 whitespace-nowrap border border-slate-300'>{item.quantity}</td>
                                    <td className='p-2 text-sm text-gray-700 whitespace-nowrap italic border border-slate-300'>Não aplicável no cálculo</td>
                                    <td className='p-2 text-sm text-gray-700 whitespace-nowrap border border-slate-300'>R$ {(parseFloat(item.secure_value) * item.quantity).toFixed(2)}</td>
                                 </tr>
                                 <tr>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td className='p-2 text-sm text-gray-700 whitespace-nowrap border border-slate-300'>R$ {item.total}</td>
                                 </tr>
                              </tbody>
                           </table>
                        </div>
                     </div>
                  ))
               }
            </div>
         </div>



         <div className='block sm:hidden bg-white mx-auto p-4 rounded-lg max-w-6xl'>
            <h4>Informações sobre o seu pedido</h4>
            {
               simulate.items.map((item, index) => (
                  <div className='shadow-lg my-4 p-3 rounded-lg' key={index.toString()}>
                     <h5> N° de diárias: {item.number_daily} </h5>
                     <h5>Tipo de Mala: {item.size_name} </h5>
                     <h5>Número de malas: {item.quantity} </h5>
                     <h5>Preco unitário: R$ {item.size_price}  </h5>
                     <h5>Preço total das diárias: R$ {(parseFloat(item.size_price) * item.quantity * item.number_daily).toFixed(2)}  </h5>
                     <h5>Preço unitário do seguro: R$ {item.secure_value} </h5>
                     <h5>Preço total do seguro: R$ {(parseFloat(item.secure_value) * item.quantity).toFixed(2)} </h5>
                     <h4>Total: {item.total} </h4>
                  </div>
               ))
            }
            <Divider />
            <div className='pt-2 flex justify-center flex-col'>
               <p className=''> Diárias: R$ {(simulate.total_value - simulate.delivery_price).toFixed(2)}</p>
               <p className='my-2'>Entrega/coleta: R$ {simulate.delivery_price}</p>
               <h3 className=''>Total: R$ {simulate.total_value}</h3>
            </div>
            <div className='flex justify-center mx-auto mt-5'>
               <button className="cursor-pointer mx-auto bg-secondary p-3 rounded-xl text-white font-semibold">Fazer reserva</button>
            </div>
            <div className='flex justify-end mt-5'>
               <p className='text-sm'>*Pagamento disponível via Pix e  cartão de crédito</p>
            </div>
         </div>
      </>
   )
}

export default Budget