export const LOGIN_USER = "LOGIN_USER";
export const LOGIN_USER_SUCCESS = "LOGIN_USER_SUCCESS";

export const LOGOUT_USER = "LOGOUT_USER";

export const REGISTER_USER = "REGISTER_USER";
export const REGISTER_USER_SUCCESS = "REGISTER_USER_SUCCESS";

export const UPDATE_USER = "UPDATE_USER";

export const STATES = "STATES";

export const LOGIN_FAILED = "LOGIN_FAILED";

export const SUBSCRIBE_FAILED = "SUBSCRIBE_FAILED";





