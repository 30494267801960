import React from 'react'
import { Divider } from '@mantine/core'

import { models, formatBag } from '../../../../../utils'

const Simulation = ({ nextStep, simulate }) => {

   return (
      <>
         <div className='bg-white mx-auto p-4 rounded-lg max-w-6xl hidden sm:block'>
            <div className='max-w-5xl mx-auto '>
               <p className='mb-4 text-2xl font-bold text-gray-900'>Informações sobre o seu orçamento</p>
               <Divider className='' />
               <div className='flex my-5'>
                  <div className='w-[75%]'>
                     {
                        simulate.items.map((item, index) => (
                           <div className='my-5 w-full flex items-center' key={index.toString()}>
                              {item.size_id ? <img width={80} height={90} src={models.find(el => el.value === item.size_id).image} alt="img" /> : null}
                              <div className="w-full mx-5">
                                 <table className='w-full'>
                                    <thead className="bg-gray-100">
                                       <tr>
                                          <th className='p-2 text-sm font-semibold tracking-wide text-left'>Itens</th>
                                          <th className='p-2 text-sm font-semibold tracking-wide text-left'>Valor Un</th>
                                          <th className='p-2 text-sm font-semibold tracking-wide text-left'>Quantidade de malas</th>
                                          <th className='p-2 text-sm font-semibold tracking-wide text-left'>Quantidade de diárias</th>
                                          <th className='p-2 text-sm font-semibold tracking-wide text-left'>Total</th>
                                       </tr>
                                    </thead>
                                    <tbody className="divide-y divide-gray-100">
                                       <tr>
                                          <td className='p-2 text-sm text-gray-700 whitespace-nowrap border border-slate-300'>Mala {item.size_name}</td>
                                          <td className='p-2 text-sm text-gray-700 whitespace-nowrap border border-slate-300'>R$ {item.size_price}</td>
                                          <td className='p-2 text-sm text-gray-700 whitespace-nowrap border border-slate-300'>{item.quantity}</td>
                                          <td className='p-2 text-sm text-gray-700 whitespace-nowrap border border-slate-300'>{item.number_daily}</td>
                                          <td className='p-2 text-sm text-gray-700 whitespace-nowrap border border-slate-300'>R$ {(parseFloat(item.size_price) * item.quantity * item.number_daily).toFixed(2)}</td>
                                       </tr>
                                       <tr>
                                          <td className='p-2 text-sm text-gray-700 whitespace-nowrap border border-slate-300'>Seguro</td>
                                          <td className='p-2 text-sm text-gray-700 whitespace-nowrap border border-slate-300'>R$ {item.secure_value}</td>
                                          <td className='p-2 text-sm text-gray-700 whitespace-nowrap border border-slate-300'>{item.quantity}</td>
                                          <td className='p-2 text-sm text-gray-700 whitespace-nowrap italic border border-slate-300'>Não aplicável no cálculo</td>
                                          <td className='p-2 text-sm text-gray-700 whitespace-nowrap border border-slate-300'>R$ {(parseFloat(item.secure_value) * item.quantity).toFixed(2)}</td>
                                       </tr>
                                       <tr>
                                          <td></td>
                                          <td></td>
                                          <td></td>
                                          <td></td>
                                          <td className='p-2 text-sm text-gray-700 whitespace-nowrap border border-slate-300'>R$ {item.total}</td>
                                       </tr>
                                    </tbody>
                                 </table>
                              </div>
                           </div>
                        ))
                     }
                  </div>
                  <Divider orientation='vertical' className='w-[5%]' />
                  <div className='w-[20%] flex justify-center flex-col'>
                     <div className='flex font-semibold'><p className='font-normal'>Diárias:</p>&nbsp; R$ {(simulate.total_value - simulate.delivery_price).toFixed(2)}</div>
                     <div className='flex my-2 font-semibold'><p className='font-normal'>Frete:</p>&nbsp; R$ {simulate.delivery_price}</div>
                     <div className='flex font-semibold text-2xl'><p className='font-normal text-2xl'>Total:</p>&nbsp;R$ {simulate.total_value}</div>
                     <div className='flex justify-center mx-auto mt-10'>
                        <button 
                           size='md' 
                           className="cursor-pointer mx-auto bg-default-orange hover:bg-default-blue p-3 rounded-xl text-white font-semibold text-2xl" 
                           onClick={nextStep}
                           disabled={simulate.items.length > 0 ? false : true}
                        >
                           Prosseguir com aluguel
                        </button>
                     </div>
                  </div>
               </div>
            </div>

            <div className='flex justify-end'>
               <p className='text-sm'>Pagamento disponível via Pix e  cartão de crédito</p>
            </div>
         </div>

         <div className='block sm:hidden bg-white mx-auto  rounded-lg max-w-6xl'>
            <h4 className='mb-4 text-2xl font-bold text-gray-900'>Informações de orçamento</h4>
            <Divider />
            
            {
               simulate.items.map((item, index) => {
                  return(
                     <div className='shadow-lg my-4 p-2 rounded-lg' key={index.toString()}>
                        <table className='w-full'>
                           <thead className="bg-gray-100">
                              <tr>
                                 <th className='p-1 text-sm font-semibold tracking-wide text-left'>Itens</th>
                                 <th className='p-1 text-sm font-semibold tracking-wide text-left'>Valor Un</th>
                                 <th className='p-1 text-sm font-semibold tracking-wide text-left'>Total</th>
                              </tr>
                           </thead>
                           <tbody className="divide-y divide-gray-100">
                              <tr>
                                 <td className='p-2 text-xs text-gray-700 whitespace-nowrap border border-slate-300'>{item.quantity} x Mala {formatBag(item.size_name)}</td>
                                 <td className='p-2 text-xs text-gray-700 whitespace-nowrap border border-slate-300'>R$ {item.size_price}</td>
                                 <td className='p-2 text-xs text-gray-700 whitespace-nowrap border border-slate-300'>R$ {(parseFloat(item.size_price) * item.quantity * item.number_daily).toFixed(2)}</td>
                              </tr>
                              <tr>
                                 <td className='p-2 text-xs text-gray-700 whitespace-nowrap border border-slate-300'>Seguro</td>
                                 <td className='p-2 text-xs text-gray-700 whitespace-nowrap border border-slate-300'>R$ {item.secure_value}</td>
                                 <td className='p-2 text-xs text-gray-700 whitespace-nowrap border border-slate-300'>R$ {(parseFloat(item.secure_value) * item.quantity).toFixed(2)}</td>
                              </tr>
                              <tr>
                                 <td></td>
                                 <td></td>
                                 <td className='p-2 text-xs text-gray-700 whitespace-nowrap border border-slate-300'>R$ {item.total}</td>
                              </tr>
                           </tbody>
                        </table>
                     </div>
                  )
               })
            }
            <Divider />
            <div className='pt-2 flex justify-center flex-col'>
               <div className='flex font-semibold'><p className='font-normal'> {simulate.items[0]?.number_daily} diárias:</p>&nbsp; R$ {(simulate.total_value - simulate.delivery_price).toFixed(2)}</div>
               <div className='flex my-2 font-semibold'><p className='font-normal'>Frete:</p>&nbsp; R$ {simulate.delivery_price}</div>
               <div className='flex font-semibold text-2xl'><p className='font-normal text-2xl'>Total:</p>&nbsp;R$ {simulate.total_value}</div>
               <div className='flex justify-center mx-auto mt-10'>
                  <button 
                     size='md' 
                     className="cursor-pointer mx-auto bg-primary p-3 rounded-xl text-white font-semibold text-md" 
                     onClick={nextStep}
                     disabled={simulate.items.length > 0 ? false : true}
                  >
                     Prosseguir com aluguel
                  </button>
               </div>
            </div>
            <div className='flex justify-end mt-5'>
               <p className='text-xs'>*Pagamento disponível via Pix e  cartão de crédito</p>
            </div>
         </div>
      </>
   )
}

export default Simulation