import React from 'react'

function modal({isVisible, onClose, children}) {
   if(!isVisible) return null;
   
   const handleClose = (e) => {
      if(e.target.id === 'wrapper') onClose();
   } 
   
   return (
      <div 
         className="fixed inset-0 bg-black bg-opacity-25 backdrop-blur-sm 
         flex justify-center items-center" id="wrapper" onClick={handleClose}
      >
         <div className="mx-auto flex flex-col h-[95%]">
            <button className="text-white text-xl place-self-end bg-transparent border-0" onClick={() => onClose()}>X</button>
            <div className="bg-white p-2 rounded  overflow-scroll overflow-x-hidden">
               {children}
            </div>
         </div>
      </div>
   )
}

export default modal
