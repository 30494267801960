import React from 'react';
import { useSelector } from 'react-redux';
import { formatarTelefone, formatarCep } from '../utils';

const Address = ({ formik, citys, findCity }) => {
  const states = useSelector(state => state.Auth.states);

  return (
    <div className="space-y-4">
      <p className='mb-4 text-base font-semibold text-gray-900'>Endereço de entrega:</p>
      <div className='grid grid-cols-4 gap-4'>
        <div>
          <label className="block mb-2 text-sm font-semibold text-gray-900">
            CEP: <span className='text-[red]'>*</span>
          </label>
          <input
            type="text"
            name="cep"
            id="cep"
            className="bg-gray-50 border border-gray-300 
              text-gray-900 text-sm rounded-lg block w-full 
              p-2.5 border-solid border-2 focus:border-default-orange outline-none"
            placeholder="Digite seu CEP"
            maxLength={9}
            value={formatarCep(formik.values.cep)}
            onChange={(e) => formik.setFieldValue('cep', e.target.value.replace(/\D/g, ''))}
            onBlur={formik.handleBlur}
          />
          {formik.touched.cep && formik.errors.cep && (
            <p className='text-red-500 text-xs italic'>{formik.errors.cep}</p>
          )}
        </div>

        <div className='col-span-2'>
          <label className="block mb-2 text-sm font-semibold text-gray-900">
            Endereço: <span className='text-[red]'>*</span>
          </label>
          <input
            type="text"
            name="street"
            id="street"
            className="bg-gray-50 border border-gray-300 
              text-gray-900 text-sm rounded-lg block w-full 
              p-2.5 border-solid border-2 focus:border-default-orange outline-none"
            placeholder="Digite sua rua"
            value={formik.values.street}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          {formik.touched.street && formik.errors.street && (
            <p className='text-red-500 text-xs italic'>{formik.errors.street}</p>
          )}
        </div>

        <div>
          <label className="block mb-2 text-sm font-semibold text-gray-900">
            Estado:<span className='text-[red]'>*</span>
          </label>
          <select
            name="state"
            id="state"
            className="bg-gray-50 border border-gray-300 
              text-gray-900 text-sm rounded-lg block w-full 
              p-3 border-solid border-2 focus:border-default-orange outline-none"
            value={formik.values.state}
            onChange={(e) => {
              formik.setFieldValue('state', e.target.value)
              formik.setFieldValue('city', '')
              findCity(e.target.value)
            }}
            onBlur={formik.handleBlur}
          >
            <option value="">Selecione o estado</option>
            {states.map((item) => (
              <option value={item.id} key={item.id}>{item.name}</option>
            ))}
          </select>
          {formik.touched.state && formik.errors.state && (
            <p className='text-red-500 text-xs italic'>{formik.errors.state}</p>
          )}
        </div>
      </div>

      <div className='grid grid-cols-3 gap-4'>
        <div>
          <label className="block mb-2 text-sm font-semibold text-gray-900">
            Cidade:<span className='text-[red]'>*</span>
          </label>
          <select
            name="city"
            id="city"
            className="bg-gray-50 border border-gray-300 
              text-gray-900 text-sm rounded-lg block w-full 
              p-3 border-solid border-2 focus:border-default-orange outline-none"
            value={formik.values.city}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          >
            <option value="">Selecione a cidade</option>
            {citys.map((item) => (
              <option value={item.id} key={item.id}>{item.name}</option>
            ))}
          </select>
          {formik.touched.city && formik.errors.city && (
            <p className='text-red-500 text-xs italic'>{formik.errors.city}</p>
          )}
        </div>

        <div>
          <label className="block mb-2 text-sm font-semibold text-gray-900">
            Bairro:<span className='text-[red]'>*</span>
          </label>
          <input
            type="text"
            name="district"
            id="district"
            className="bg-gray-50 border border-gray-300 
              text-gray-900 text-sm rounded-lg block w-full 
              p-2.5 border-solid border-2 focus:border-default-orange outline-none"
            placeholder="Digite seu bairro"
            value={formik.values.district}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          {formik.touched.district && formik.errors.district && (
            <p className='text-red-500 text-xs italic'>{formik.errors.district}</p>
          )}
        </div>

        <div>
          <label className="block mb-2 text-sm font-semibold text-gray-900">
            Complemento:
          </label>
          <input
            type="text"
            name="complement"
            id="complement"
            className="bg-gray-50 border border-gray-300 
              text-gray-900 text-sm rounded-lg block w-full 
              p-2.5 border-solid border-2 focus:border-default-orange outline-none"
            placeholder="Complemento"
            value={formik.values.complement}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          {formik.touched.complement && formik.errors.complement && (
            <div className="text-red-500 text-sm">{formik.errors.complement}</div>
          )}
        </div>
      </div>

      <div className='grid grid-cols-4 gap-4 pb-4'>
        <div>
          <label className="block mb-2 text-sm font-semibold text-gray-900">
            Número:<span className='text-[red]'>*</span>
          </label>
          <input
            type="text"
            name="number"
            id="number"
            className="bg-gray-50 border border-gray-300 
              text-gray-900 text-sm rounded-lg block w-full 
              p-2.5 border-solid border-2 focus:border-default-orange outline-none"
            placeholder="Número da sua casa/apto"
            value={formik.values.number}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          {formik.touched.number && formik.errors.number && (
            <p className='text-red-500 text-xs italic'>{formik.errors.number}</p>
          )}
        </div>

        <div>
          <label className="block mb-2 text-sm font-semibold text-gray-900">
            Telefone:<span className='text-[red]'>*</span>
          </label>
          <input
            type="text"
            name="phoneNumber"
            id="phoneNumber"
            className="bg-gray-50 border border-gray-300 
              text-gray-900 text-sm rounded-lg block w-full 
              p-2.5 border-solid border-2 focus:border-default-orange outline-none"
            placeholder="Digite seu telefone"
            maxLength={14}
            value={formatarTelefone(formik.values.phoneNumber)}
            onChange={(e) => formik.setFieldValue('phoneNumber', e.target.value.replace(/\D/g, ''))}
            onBlur={formik.handleBlur}
          />
          {formik.touched.phoneNumber && formik.errors.phoneNumber && (
            <p className='text-red-500 text-xs italic'>{formik.errors.phoneNumber}</p>
          )}
        </div>
      </div> 
    </div>
  );
};

export default Address;
