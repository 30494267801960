import {  Divider } from '@mantine/core'
import { toast } from 'react-toastify'
import { useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'

import Footer from '../../components/Footer'
import Header from '../../components/Header'
import Copy from '../../assets/svgs/copy'

const PaymentPix = () => {
   const location = useLocation();
   const navigate = useNavigate();
   const simulate = useSelector(state => state.Simulate.simulate)
  

   const unsecuredCopyToClipboard = (text) => {
      const textArea = document.createElement("textarea");
      textArea.value = text;
      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();
      try {
         document.execCommand('copy');
      } catch (err) {
         console.error('Unable to copy to clipboard', err);
      }
      document.body.removeChild(textArea);
   }

   const copyClipboard = (text) => {
      if (window.isSecureContext && navigator.clipboard) {
         navigator.clipboard.writeText(text)
      } else {
         unsecuredCopyToClipboard(text);
      }
      toast("Pix copiado com sucesso", {
         type: "success"
      })
   }

   return (
      <>
         <Header title={'QR Code'} />
         <div className='bg-white mx-auto py-20 rounded-lg max-w-6xl hidden sm:block'>
            <div className='w-max-lg'>
               <p className='mb-4 text-2xl font-bold text-gray-900'>Leia o QR Code para finalizar a compra</p>
               <Divider className='mx-3' />
               <form className="space-y-6" >
                  <div className="flex my-4">
                     <div className="w-3/5 mx-4 flex flex-col space-y-6">
                        <img
                           src={location.state.qrCode}
                           alt="qrcode"
                           className='h-72 w-72 self-center'
                        />
                        <p className='text-xs font-medium self-center'>Leia o QR Code no aplicativo do seu banco ou carteira digital</p>
                        <div className='flex flex-row h-[37px]'>
                           <input
                              className="bg-gray-50 border border-gray-300 
                                 text-gray-900 text-sm rounded-2xl block w-full 
                                 px-2.5 border-solid border-2  outline-none mr-1"
                              value={location.state.code}
                              disabled={true}
                           />  
                           <button type='button' className="cursor-pointer bg-primary w-96 rounded-xl text-white font-regular " onClick={() => copyClipboard(location.state.code)}>
                              <div className='flex items-center justify-center gap-3'> 
                                 <Copy />
                                 <p>Copiar código Pix</p> 
                              </div> 
                           </button>
                        </div>
                     </div>

                     <Divider orientation='vertical' />

                     <div className="w-3/5">
                        <div className='flex flex-col mx-3 gap-5 my-10'>
                           <img
                              src={require('../../assets/images/pix.png')}
                              alt="qrcode"
                              className='h-[40px]  self-center'
                           />
                           <p className='font-semibold text-lg'>Tudo pronto. Agora é só pagar!</p>
                           <div className='flex gap-3 items-center text-sm'>
                              <div className='h-[20px] w-[20px] rounded rounded-[10px] border border-blue-500 flex items-center justify-center'>
                                 <p className='font-medium text-sm'>1</p>
                              </div>
                              <p>Abra o aplicativo do seu banco ou carteira digital</p>
                           </div>
                           <div className='flex gap-3 items-center text-sm'>
                              <div className='h-[20px] w-[20px] rounded rounded-[10px] border border-blue-500 flex items-center justify-center'>
                                 <p className='font-medium text-sm'>2</p>
                              </div>
                              <p>Faça um pix lendo o QR Code ou copiando o código de pagamento</p>
                           </div>
                           <div className='flex gap-3 items-center text-sm'>
                              <div className='h-[20px] w-[20px] rounded rounded-[10px] border border-blue-500 flex items-center justify-center'>
                                 <p className='font-medium text-sm'>3</p>
                              </div>
                              <p>Revise as informações e aguarde sua confirmação por e-mail. Pronto, tá pago!</p>
                           </div>
                           <div className='flex gap-3 items-center text-sm'>
                              <i class="bi bi-info-circle-fill"></i>
                              <p className='text-xs font-bold'>A sincronização do seu pagamento com a Malug pode demorar, aguarde a confirmação</p>
                           </div>
                        </div>
                        <Divider orientation='horizontal' className='m-3' />
                        <div className='flex flex-col mx-3'>
                           <div className='mt-5 self-end'>
                              <div className='flex font-semibold text-2xl'>
                                 <p className='font-normal text-2xl'>
                                    Total do aluguel:
                                 </p>&nbsp;R$ {simulate.total_value}
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </form>  
            </div>
         </div>

         <div className='rounded-lg bg-white w-max-4xl py-4 block sm:hidden'>
            <div className='w-max-lg'>
               <h3 className='m-4 text-2xl font-bold text-gray-900'>Leia o QR Code para finalizar a compra</h3>
               <Divider className='mx-3' />
               <form className="space-y-4">
                  <div className="flex flex-col my-4">
                     
                     <Divider orientation='vertical' />
                     <div className=" mx-4 flex flex-col space-y-6">
                        <img
                           src={location.state.qrCode}
                           alt="qrcode"
                           className='h-72 w-72 self-center'
                        />
                        <p className='text-xs font-medium self-center'>Leia o QR Code no aplicativo do seu banco ou carteira digital</p>
                        <div className='flex flex-col gap-3'>
                           <input
                              className="bg-gray-50 border border-gray-300 
                                 text-gray-900 text-sm rounded-2xl block w-full 
                                 p-2.5 border-solid border-2  outline-none mr-1"
                              value={location.state.code}
                              disabled={true}
                           />  
                           <button type='button' className="cursor-pointer bg-primary p-2.5 rounded-xl text-white font-regular " onClick={() => copyClipboard(location.state.code)}>
                              <div className='flex items-center justify-center gap-3'> 
                                 <Copy />
                                 <p>Copiar código</p> 
                              </div> 
                           </button>
                        </div>
                     </div>
                  </div>
               </form>
            </div> 
         </div>

         <div className='mx-auto pb-20 rounded-lg max-w-6xl hidden sm:block'>
            <div className=' flex justify-center gap-5' >
               <button
                  type="submit"
                  className=" text-white bg-default-orange hover:bg-default-blue
                  border-none font-semibold rounded-lg text-base font-semibold px-5 py-2.5
                  inline-flex items-center justify-center cursor-pointer"
                  onClick={() => navigate('/')}
               >
                  <>Voltar a alugar malas</>
               </button>
               <button
                  type="submit"
                  className=" text-white bg-default-orange hover:bg-default-blue
                  border-none font-semibold rounded-lg text-base font-semibold px-5 py-2.5
                  inline-flex items-center justify-center cursor-pointer"
                  onClick={() => navigate('/conta/reservas')}
               >
                  <>Meus pedidos</>
               </button>
               <button
                  type="submit"
                  className=" text-white bg-default-orange hover:bg-default-blue
                  border-none font-semibold rounded-lg text-base font-semibold px-5 py-2.5
                  inline-flex items-center justify-center cursor-pointer"
                  onClick={() => navigate('/ordem-de-servico/'+location.state.booking_id)}
               >
                  <>Vizualizar pedido</>
               </button>
            </div>
         </div>

         <div className='rounded-lg bg-white w-max-4xl py-4 block sm:hidden'>
            <div className='flex flex-col items-center gap-5' >
               <button
                  type="submit"
                  className=" text-white bg-default-orange hover:bg-default-blue
                  border-none font-semibold rounded-lg text-base font-semibold px-5 py-2.5
                  inline-flex items-center justify-center cursor-pointer"
                  onClick={() => navigate('/')}
               >
                  <>Voltar a alugar malas</>
               </button>
               <button
                  type="submit"
                  className=" text-white bg-default-orange hover:bg-default-blue
                  border-none font-semibold rounded-lg text-base font-semibold px-5 py-2.5
                  inline-flex items-center justify-center cursor-pointer"
                  onClick={() => navigate('/conta/reservas')}
               >
                  <>Meus pedidos</>
               </button>
               <button
                  type="submit"
                  className=" text-white bg-default-orange hover:bg-default-blue
                  border-none font-semibold rounded-lg text-base font-semibold px-5 py-2.5
                  inline-flex items-center justify-center cursor-pointer"
                  onClick={() => navigate('/ordem-de-servico/'+location.state.booking_id)}
               >
                  <>Vizualizar pedido</>
               </button>
            </div>
         </div>
         <Footer />
      </>
   );
}

export default PaymentPix