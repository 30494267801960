import * as React from "react";
const SVGComponent = (props) => (
  <svg
    width={47}
    height={45}
    viewBox="0 0 47 45"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M10.4476 29.7917V17.0312H37.0332V4.27083H10.4476L37.0332 29.7917H23.7404M10.4476 29.7917L23.7404 42.5521V29.7917M10.4476 29.7917H23.7404"
      stroke="white"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default SVGComponent;
