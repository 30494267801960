import React from "react";

import {  formatarCep, states } from '../../../../../utils';

function DetailsPersonalData({cep, rua, state = 1, city, district, complement, number}) {
   return (
      <>
         <div className='grid grid-cols-4'>  
            <div className='ml-5 mr-2 col-span-1'>
               <label className="block mb-2 text-sm font-semibold text-gray-900">
                  CEP: {formatarCep(cep)}
               </label>
            </div>

            <div className='ml-2 mr-2 col-span-2'>
               <label
                  className="block mb-2 text-sm font-semibold text-gray-900"
               >
                  ENDEREÇO: {rua}
               </label>
            </div>

            <div className='ml-2 mr-10 col-span-1'>
               <label className="block mb-2 text-sm font-semibold text-gray-900">
                  ESTADO: {states.find(el => el.id === state).name}
               </label>
            </div>

         </div>

         <div className='grid grid-cols-10 mt-3'>

            <div className='ml-5 mr-2 col-span-2'>
               <label className="block mb-2 text-sm font-semibold text-gray-900">
                  CIDADE: {city}
               </label>
            </div>
            <div className='ml-2 mr-2 col-span-3'>
               <label className="block mb-2 text-sm font-semibold text-gray-900">
                  BAIRRO: {district}
               </label>
            </div>
            <div className='ml-2 mr-2 col-span-3'>
               <label className="block mb-2 text-sm font-semibold text-gray-900">
                  COMPLEMENTO: {complement}
               </label>
            </div>
            <div className='ml-2 mr-10 col-span-2 items-center'>
               <label className="block mb-2 text-sm font-semibold text-gray-900">
                  NÚMERO: {number}
               </label>
            </div>
            
         </div>
      </>
   )
}

export default DetailsPersonalData 