import React, { useState } from 'react'
import { Divider} from '@mantine/core'
import Card from 'react-credit-cards';
import { 
   formatCreditCardNumber,
   formatCVC,
   formatExpirationDate
} from '../../../utils'

import Spinner from '../../../assets/svgs/spinner'

import { HelpersPayments } from '../../../helpers';
const helpersPayments = new HelpersPayments()

const ConfirmAdrress = ({closeModal}) => {
   const [loadingSaveCard, setLoadingSaveCard] = useState(false)
   const [card, setCard] = useState({
      number: "",
      cvv: "",
      date: "",
      name: "",
      focus: ""
   })
   const [erroCard, setErroCard] = useState(false)

   const handleFocus = (e) => {
      setCard({ ...card, focus: e.target.name })
   }

   const saveCard = async(e) => {
      e.preventDefault();
      setLoadingSaveCard(true)
      await helpersPayments.SaveCard({
         number: card.number.replace(' ','').replace(' ','').replace(' ', ''),
         name: card.name,
         month: card.date.substring(0, 2),
         year: '20' + card.date.substring(3, 5),
         cvv: card.cvv
      }).then(resp => {
         closeModal(resp.data.data.id)
         setLoadingSaveCard(false)
      }).catch(err => {
         setLoadingSaveCard(false)
         setErroCard(true)
         setTimeout(() => {
            setErroCard(false)
         }, 3000);
      })
   }

   return (
      <div className='rounded-lg bg-white w-max-4xl py-4'>
         <p className='m-4 text-2xl font-bold text-gray-900'>Cadastrar novo cartão</p>
         <Divider className='mx-4'/>
         <form className="mx-4 flex flex-col items-center mt-8" onSubmit={saveCard}>
            <div className='mb-4'>
               <Card
                  cvc={card.cvv}
                  expiry={card.date}
                  name={card.name}
                  number={card.number}
                  focused={card.focus}
                  
               />
            </div>
            <div className="space-y-3" >
               <div className="flex flex-col">
                  <input
                     type="tel"
                     required
                     maxLength={19}
                     className="bg-gray-50 border border-gray-300 
                     text-gray-900 text-sm rounded-lg block w-full 
                     p-1.5 border-solid border-2 focus:border-default-orange outline-none"
                     onFocus={e => handleFocus(e)} 
                     name="number" 
                     placeholder='Número do cartão' 
                     value={formatCreditCardNumber(card.number)} 
                     onChange={e => setCard({ ...card, number: e.target.value })}
                  />
               </div>
               <div className="flex flex-col">
                  <input
                     type="text"
                     required
                     className="bg-gray-50 border border-gray-300 
                     text-gray-900 text-sm rounded-lg block w-full 
                     p-1.5 border-solid border-2 focus:border-default-orange outline-none"
                     onFocus={e => handleFocus(e)} 
                     name="name" 
                     placeholder='Nome impresso no cartão' 
                     value={card.name} 
                     onChange={e => setCard({ ...card, name: e.target.value })}
                  />
               </div>
               <div className="grid grid-cols-2 gap-4">
                  <div>
                     <input
                        type="tel"
                        name="expiry"
                        required
                        maxLength={5}
                        minLength={5}
                        className="bg-gray-50 border border-gray-300 
                        text-gray-900 text-sm rounded-lg block w-full 
                        p-1.5 border-solid border-2 focus:border-default-orange outline-none"
                        onFocus={e => handleFocus(e)} 
                        placeholder='Validade MM/AA' 
                        value={formatExpirationDate(card.date)} 
                        onChange={e => setCard({ ...card, date: e.target.value })}
                     />
                  </div>
                  <div>
                     <input
                        type="tel"
                        name="cvc"
                        pattern="\d{3,4}"
                        required
                        maxLength={4}
                        className="bg-gray-50 border border-gray-300 
                        text-gray-900 text-sm rounded-lg block w-full 
                        p-1.5 border-solid border-2 focus:border-default-orange outline-none"
                        onFocus={e => handleFocus(e)} 
                        placeholder='CVV' 
                        value={formatCVC(card.cvv)} 
                        onChange={e => setCard({ ...card, cvv: e.target.value })}
                     />
                  </div>
               </div>
               {
                  erroCard ? <div className='bg-red-300 rounded-lg py-2 px-3'>
                     <p className="text-red-600 text-sm">
                        Erro ao cadatrar cartão, revise os dados e tente novamente
                     </p>
                  </div> : null
               }
            </div>
            <button 
               className="cursor-pointer bg-primary p-3 mt-5 h-[54px] text-2xl font-bold rounded-xl text-white flex items-center justify-center" 
               type="submit" 
               disabled={loadingSaveCard}
            >
               {  loadingSaveCard ? <> <Spinner />Salvando cartão</> : 'Salvar cartão'}
            </button>
         </form>
      </div>
   )
}


export default ConfirmAdrress