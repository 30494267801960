import React, { useEffect } from 'react'
import { Burger, Collapse, Image } from '@mantine/core'
import { useSelector } from 'react-redux'

import logo from '../../../../assets/images/malug.png'
import header from '../../../../assets/images/header.png'

import Modal from '../../../../components/Modal'

import Login from '../../../../components/Login'
import Subscribe from '../../../../components/Subscribe'
import ForgotPassword from '../../../../components/ForgotPassword'
import ResetPassword from '../../../../components/ResetPassword'
import Sucess from '../../../../components/Sucess'

export default function Nav(props) {
   const [opened, setOpened] = React.useState(false);
   const [showModal, setShowModal] = React.useState(false);
   const [typeModal, setTypeModal] = React.useState('sucess');

   const user = useSelector(state => state.Auth.user)

   const title = opened ? 'Close navigation' : 'Open navigation';

   useEffect(() => {
      const token = localStorage.getItem("token_cliente")
      if (token !== null) {
         setShowModal(false)
         setOpened(false)
      }
   }, [user])

   function validToken() {
      const Token = localStorage.getItem("token_cliente")
      return Token
   }

   const renderModal = {
      'login': <Login typeModal={typeModal} setTypeModal={setTypeModal} showModal={showModal} web={true}/>,
      'subscribe': <Subscribe typeModal={typeModal} setTypeModal={setTypeModal} showModal={showModal} web={true}/>,
      'forgotPassword': <ForgotPassword typeModal={typeModal} setTypeModal={setTypeModal} showModal={showModal} />,
      'resetPassword': <ResetPassword typeModal={typeModal} setTypeModal={setTypeModal} showModal={showModal} />,
      'sucess': <Sucess setTypeModal={setTypeModal} />
   }

   return (
      <div>
         <div className='w-full flex justify-between bg-primary'>
            <div className='flex flex-col justify-between m-7'>
               <div className='flex items-center justify-between'>
                  <Image src={logo} w={150} />
                  <Burger
                     className='md:hidden'
                     color='white'
                     opened={opened}
                     onClick={() => setOpened((o) => !o)}
                     title={title}
                  />
               </div>

               <div className='pt-10 block sm:hidden' >
                  <p className='text-white text-[30px] font-bold'>Alugue malas rápido e fácil</p>
                  <p className='text-white text-[20px] font-normal'>Preencha abaixo as informações necessárias para seu orçamento.</p>
               </div>
            </div>

            <div style={{
               backgroundImage: `url(${header})`,
               height: '340px',
               width: '558.5px',
               backgroundRepeat: 'no-repeat',
               justifyContent: 'flex-end',
               paddingBottom: '50px',
            }} className='justify-center align-center hidden md:flex lg:flex p-7'>

               {validToken() !== null ?
                  <button className="mx-2 bg-default-orange hover:bg-default-blue h-10 border-none rounded-xl px-2 text-white font-semibold" primary="true" onClick={() => props.navegar('/conta/dados-pessoais')}>
                     Minha conta
                  </button>
                  :
                  <>
                     <button
                        className="mx-2 bg-default-orange hover:bg-default-blue h-10 border-none rounded-xl px-2 text-white font-semibold"
                        onClick={() => {
                           setShowModal(true)
                           setTypeModal('subscribe')
                        }}
                     >
                        Cadastre-se
                     </button>
                     <button
                        className='mx-2 bg-default-orange hover:bg-default-blue h-10 border-none rounded-xl px-2 text-white font-semibold'
                        onClick={() => {
                           setShowModal(true);
                           setTypeModal('login')
                        }}
                     >
                        Login
                     </button>
                  </>}
            </div>
         </div>


         <Collapse in={opened}>
            {
               validToken() !== null ? <>
                  <div className='flex  align-center flex-col h-20'>
                     <button className="mx-2 hover:bg-default-orange bg-default-blue h-10 border-none rounded-xl text-white font-semibold" primary="true" onClick={() => props.navegar('/conta/dados-pessoais')}>
                        Minha conta
                     </button>
                  </div>
               </>
               :
               <>
                  <div className='flex  align-center flex-col h-40'>
                     <button
                        className="mx-2 hover:bg-default-orange bg-default-blue h-10 border-none rounded-xl text-white font-semibold"
                        onClick={() => {
                           setShowModal(true);
                           setTypeModal('subscribe')
                        }}
                     >
                        Cadastre-se
                     </button>
                     <button
                        className="mx-2 mt-2 hover:bg-default-orange bg-default-blue h-10 border-none rounded-xl text-white font-semibold"
                        onClick={() => {
                           setShowModal(true);
                           setTypeModal('login')
                        }}
                     >
                        Login
                     </button>
                  </div>
               </>
            }
         </Collapse>
         <Modal isVisible={showModal} onClose={() => setShowModal(false)} currentModal={0}>
            {renderModal[typeModal]}
         </Modal>
      </div>
   )
}
