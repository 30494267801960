import { REHYDRATE } from 'redux-persist/lib/constants'; 
import {
    LOGIN_USER,
    LOGIN_USER_SUCCESS,
    LOGOUT_USER,
    REGISTER_USER,
    UPDATE_USER, 
    STATES,
    LOGIN_FAILED,
    SUBSCRIBE_FAILED
} from './constants';

const INIT_STATE = {
    user: {},
    loadingLogin: false,
    loadingSubscribe: false,
    erroLogin: null,
    erroSubscribe: null,
    states: [],
    error: null
};


const Auth = (state = INIT_STATE, action) => {
    console.log(action)
    switch (action.type) {
        case REHYDRATE:
            return { ...state, user: action.payload?.Auth?.user ? action.payload?.Auth?.user : {} };

        case LOGIN_USER:
            return { ...state, loadingLogin: true, loadingSubscribe: false };

        case LOGIN_USER_SUCCESS:
            return { ...state, user: action.payload, loadingLogin: false, loadingSubscribe: false, error: null };

        case REGISTER_USER:
            return { ...state, loadingSubscribe: true, loadingLogin: false };
    
        case UPDATE_USER:
            return { ...state, loadingLogin: true };

        case STATES:
            return { ...state, states: action.payload.states }; 

        case LOGOUT_USER:
            return { ...state, user: {} };

        case LOGIN_FAILED:
            return { ...state, loadingLogin: false, loadingSubscribe: false, erroLogin: action.payload };

        case SUBSCRIBE_FAILED:
            return { ...state, loadingLogin: false, loadingSubscribe: false, erroSubscribe: action.payload };

        default: return { ...state };
    }
}

export default Auth;