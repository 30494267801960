import * as React from "react";
const SVGComponent = (props) => (
  <svg
    width={19}
    height={20}
    viewBox="0 0 19 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_6_160)">
      <path
        d="M16.625 8.33334C16.625 14.1667 9.5 19.1667 9.5 19.1667C9.5 19.1667 2.375 14.1667 2.375 8.33334C2.375 6.34422 3.12567 4.43657 4.46186 3.03004C5.79806 1.62352 7.61033 0.833344 9.5 0.833344C11.3897 0.833344 13.2019 1.62352 14.5381 3.03004C15.8743 4.43657 16.625 6.34422 16.625 8.33334Z"
        stroke="white"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.5 10.8333C10.8117 10.8333 11.875 9.71406 11.875 8.33334C11.875 6.95263 10.8117 5.83334 9.5 5.83334C8.18832 5.83334 7.125 6.95263 7.125 8.33334C7.125 9.71406 8.18832 10.8333 9.5 10.8333Z"
        stroke="white"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_6_160">
        <rect width={19} height={20} fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export default SVGComponent;
