import * as React from "react";
const SVGComponent = (props) => (
  <svg
    width={47}
    height={45}
    viewBox="0 0 47 45"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M23.4398 2.44792V42.5521"
      stroke="white"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M32.9141 9.73958H18.7027C16.9438 9.73958 15.2569 10.4118 14.0131 11.6083C12.7694 12.8048 12.0707 14.4277 12.0707 16.1198C12.0707 17.8119 12.7694 19.4348 14.0131 20.6313C15.2569 21.8278 16.9438 22.5 18.7027 22.5H28.177C29.9359 22.5 31.6228 23.1722 32.8665 24.3687C34.1102 25.5652 34.809 27.1881 34.809 28.8802C34.809 30.5723 34.1102 32.1952 32.8665 33.3917C31.6228 34.5882 29.9359 35.2604 28.177 35.2604H12.0707"
      stroke="white"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default SVGComponent;
