import { SET_SIMULATE, REMOVE_SIMULATE } from './constants';

const INIT_STATE = {
    simulate: null
};


const Simulate = (state = INIT_STATE, action) => {
    console.log(action)
    switch (action.type) {
        case SET_SIMULATE:
            return { ...state, simulate: action.payload };

        case REMOVE_SIMULATE:
            return { ...state, simulate: {} };

        default: return { ...state };
    }
}

export default Simulate;