import api from './Api/api'

function validToken() {
	const Token = localStorage.getItem("token_cliente")
	return Token
}

class HelpersBookings {
   async Simulate(values) {
      return new Promise(async(resolve, reject) => {
         try {
            const bookings = await api.post('/booking/simulate', values, {
               headers: {
                  'Content-Type': 'application/json',
                  'Access-Control-Allow-Origin' : '*',
               },
            })
            resolve (bookings)
         } catch (error) {
            reject (error)
         }
      })
   }  

   async Listbookings(page) {
      return new Promise(async(resolve, reject) => {
         try {
            const bookings = await api.get('/user/bookings?page='+page, {
               headers: {
                  'Content-Type': 'application/json',
                  'Access-Control-Allow-Origin' : '*',
                  'Authorization': 'Bearer ' + validToken()
               },
            })
            resolve (bookings)
         } catch (error) {
            reject (error)
         }
      })
   }  

   async DetailsBooking(id) {
      return new Promise(async(resolve, reject) => {
         try {
            const bookings = await api.get('/user/bookings/'+id+'/details', {
               headers: {
                  'Content-Type': 'application/json',
                  'Access-Control-Allow-Origin' : '*',
                  'Authorization': 'Bearer ' + validToken()
               },
            })
            resolve (bookings)
         } catch (error) {
            reject (error)
         }
      })
   }

}

export { HelpersBookings };