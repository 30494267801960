import React, { useEffect, useState } from 'react'
import moment from 'moment'

import { statusBooking } from "../../components/renderStatusBooking";
import { statusPayment } from '../../components/renderStatusPayment';
import { setCity, findState } from '../../utils';
import { useSelector } from 'react-redux'
import { useParams, useNavigate } from 'react-router-dom'

import Footer from '../../components/Footer'
import Header from '../../components/Header'

import { HelpersBookings, HelpersAdress } from '../../helpers';
const helpersBookings = new HelpersBookings()
const helpersAdress = new HelpersAdress()

const Booking = () => {
   const { id } = useParams();
   const navigate = useNavigate();

   const user = useSelector(state => state.Auth.user)
   const states = useSelector(state => state.Auth.states)

   const [filial, setFilial] = useState("")
   const [booking, setBooking] = useState([])
   const [nameState] = useState(findState(states, user.address.state_id))
   const [nameCity, setNameCity] = useState()

   useEffect(() => {
      const findCity = async (state_id, city_id) => {
         const city = await helpersAdress.ListCities(state_id).then(resp => {
            const nome = setCity(resp.data.data, city_id)
            return nome
         })
         let cit = city
         return cit
      }

      const getDatailsBooking = async () => {
         await helpersBookings.DetailsBooking(id).then(booking => {
            setBooking(booking.data.data)
            findCity(booking.data.data.state_id, booking.data.data.city_id).then(resp => {
               setFilial(resp)
            });
         })
      }

      findCity(user.address.state_id, user.address.city_id).then(resp => {
         setNameCity(resp)
      });

      getDatailsBooking()
   }, [id, user.address.city_id, user.address.state_id])



   return (
      <>
         <Header title={'Ordem de serviço'} />
         <div className='bg-white mx-auto py-20 rounded-lg max-w-6xl hidden sm:block'>
            <div className='h-36 flex flex-row justify-between  items-center p-2'>
               <img src={require("../../assets/images/malugOrange.png")} alt="img" width="150" />
               <div>
                  <p className='text-sm'>
                     Malug - Aluguel de malas
                  </p>
                  <br />
                  <p className='flex justify-end text-xs'>
                     Rua Vitorino Carmilo, N°: S/N <br />
                     65110-000 - São José de Ribamar, MA <br />
                     Loja: Loja Física
                  </p>
               </div>
            </div>
            <h3 className='text-center my-5 font-semibold'>Ordem de serviço N° {booking.id}</h3>
            <div>
               <div className="w-full flex flex-row">
                  <div className='w-3/5'>
                     <h3 className='font-semibold'>Cliente:</h3>
                     <div className='border border-slate-300 h-36 flex items-center pl-3 mr-2'>
                        <p className='text-sm'>
                           {user.name} <br />
                           {user.cpf} <br />
                           {user.address.street}, {user.address.number} /  {user.address.complement}<br />
                           {user.address.district}, {nameCity} - {nameState}<br />
                           Cep: {user.address.cep}<br />
                           Tel: {user.phone}
                        </p>
                     </div>
                  </div>
                  <div className='w-2/5 '>
                     <div className='h-36 mt-6 flex flex-row'>
                        <div className="flex flex-col flex-1">
                           <div className='flex flex-1 border-b-0 border-r-0 border border-black-300 items-center pl-2'>
                              Unidade
                           </div>
                           <div className='flex flex-1 border-r-0 border border-slate-300 items-center pl-2'>
                              {filial}
                           </div>
                        </div>
                        <div className="flex flex-col flex-1">
                           <div className='flex flex-1 border-b-0 border-r-0 border border-black-300 items-center pl-2'>
                              Data de recebimento
                           </div>
                           <div className='flex flex-1 border-r-0 border border-slate-300 items-center pl-2'>
                              {moment(booking.check_in).format('DD/MM/YY')}
                           </div>
                        </div>
                        <div className="flex flex-col flex-1">
                           <div className='flex flex-1 border-b-0  border border-slate-300 items-center pl-2'>
                              Data de devolução
                           </div>
                           <div className='flex flex-1 border border-slate-300 items-center pl-2'>
                              {moment(booking.check_out).format('DD/MM/YY')}
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
               <div className='mt-6'>
                  <h3 className='font-semibold'>Malas:</h3>
                  {
                     booking?.bags?.map((item, index) => (
                        <div className='mb-3 w-full flex items-center' key={index.toString()}>
                           <div className="w-full">
                              <table className='w-full'>
                                 <thead className="bg-gray-100">
                                    <tr>
                                       <th className='p-2 text-sm font-semibold tracking-wide text-left'>Itens</th>
                                       <th className='p-2 text-sm font-semibold tracking-wide text-left'>Marca / Modelo</th>
                                       <th className='p-2 text-sm font-semibold tracking-wide text-left'>Cor</th>
                                       <th className='p-2 text-sm font-semibold tracking-wide text-left'>Valor Un</th>
                                       <th className='p-2 text-sm font-semibold tracking-wide text-left'>Quantidade de diárias</th>
                                       <th className='p-2 text-sm font-semibold tracking-wide text-left'>Total</th>
                                    </tr>
                                 </thead>
                                 <tbody className="divide-y divide-gray-100">
                                    <tr>
                                       <td className='p-2 text-sm text-gray-700 whitespace-nowrap border border-slate-300'>Mala {item.size_name}</td>
                                       <td className='p-2 text-sm text-gray-700 whitespace-nowrap border border-slate-300'>{item.brand} - {item.model}</td>
                                       <td className='p-2 text-sm text-gray-700 whitespace-nowrap border border-slate-300'>{item.color}</td>
                                       <td className='p-2 text-sm text-gray-700 whitespace-nowrap border border-slate-300'>R$ {item.size_price}</td>
                                       <td className='p-2 text-sm text-gray-700 whitespace-nowrap border border-slate-300'>{booking.days}</td>
                                       <td className='p-2 text-sm text-gray-700 whitespace-nowrap border border-slate-300'>R$ {(parseFloat(item.size_price) * booking.days).toFixed(2)}</td>
                                    </tr>
                                    <tr>
                                       <td className='p-2 text-sm text-gray-700 whitespace-nowrap border border-slate-300'>Seguro</td>
                                       <td className='p-2 text-sm text-gray-700 whitespace-nowrap border border-slate-300'></td>
                                       <td className='p-2 text-sm text-gray-700 whitespace-nowrap border border-slate-300'></td>
                                       <td className='p-2 text-sm text-gray-700 whitespace-nowrap border border-slate-300'>R$ {item.secure_value}</td>
                                       <td className='p-2 text-sm text-gray-700 italic whitespace-nowrap border border-slate-300'>{booking.days} (Não se aplica ao cálculo)</td>
                                       <td className='p-2 text-sm text-gray-700 whitespace-nowrap border border-slate-300'>R$ {item.secure_value}</td>
                                    </tr>
                                    <tr>
                                       <td></td>
                                       <td></td>
                                       <td></td>
                                       <td></td>
                                       <td></td>
                                       <td className='p-2 text-sm text-gray-700 whitespace-nowrap border border-slate-300'>R$ {((parseFloat(item.size_price) * booking.days) + parseInt(item.secure_value)).toFixed(2)}</td>
                                    </tr>
                                 </tbody>
                              </table>
                           </div>
                        </div>
                     ))
                  }
               </div>
               <div>
                  <h3 className='font-semibold'>Totais:</h3>
                  <div className='h-20 flex flex-row'>
                     <div className="flex flex-col flex-1">
                        <div className='flex flex-1 border-b-0 border-r-0 border border-slate-300 items-center pl-2'>
                           Total em malas
                        </div>
                        <div className='flex flex-1 border-r-0 border border-slate-300 items-center pl-2'>
                           R${(parseFloat(booking.total_value) - booking.delivery_value).toFixed(2)}
                        </div>
                     </div>
                     <div className="flex flex-col flex-1">
                        <div className='flex flex-1 border-b-0  border border-slate-300 items-center pl-2'>
                           Frete
                        </div>
                        <div className='flex flex-1 border border-slate-300 items-center pl-2'>
                           R$ {booking.delivery_value}.00
                        </div>
                     </div>
                     <div className="flex flex-col flex-1">
                        <div className='flex flex-1 border-b-0  border border-slate-300 items-center pl-2'>
                           Total da ordem de serviço
                        </div>
                        <div className='flex flex-1 border border-slate-300 items-center pl-2'>
                           R$ {booking.total_value}
                        </div>
                     </div>
                  </div>
               </div>
               <div className='my-6'>
                  <h3 className='font-semibold'>Dados de pagamento:</h3>
                  <div className='h-20 flex flex-row'>
                     <div className="flex flex-col flex-1">
                        <div className='flex flex-1 border-b-0 border-r-0 border border-slate-300 items-center pl-2'>
                           Método de pagamento
                        </div>
                        <div className='flex flex-1 border-r-0 border border-slate-300 items-center pl-2'>
                           {booking.payment_method === 'credit_card' ? 'Cartão de crédito' : null}
                           {booking.payment_method === 'pix' ? 'Pix' : null}
                        </div>
                     </div>
                     <div className="flex flex-col flex-1">
                        <div className='flex flex-1 border-b-0  border border-slate-300 items-center pl-2'>
                           Pagamento
                        </div>
                        <div className='flex flex-1 border border-slate-300 items-center pl-2'>
                           {statusPayment(booking.payment_method, booking.status)}
                        </div>
                     </div>
                     <div className="flex flex-col flex-1">
                        <div className='flex flex-1 border-b-0  border border-slate-300 items-center pl-2'>
                           Status do pedido
                        </div>
                        <div className='flex flex-1 border border-slate-300 items-center pl-2'>
                           {statusBooking(booking.reserve_status)}
                        </div>
                     </div>
                  </div>
               </div>
               <div className='mt-20'>
                  <p className='text-xs'>Concordo com os termos acima:</p>
                  <div className=' flex flex-row justify-between'>
                     <div>
                        <p>Data: _____ /_____ /______</p>
                     </div>
                     <div>
                        ________________________________________
                        <p className='text-xs text-center'>Assinatura do responsável</p>
                     </div>
                  </div>

               </div>
            </div>
         </div>


         <div className='bg-white p-5 block sm:hidden'>
            <div className='h-36 flex flex-row justify-between  items-center p-2'>
               <img src={require("../../assets/images/malugOrange.png")} alt="img" width="80" />
               <div>
                  <p className='text-sm'>
                     Malug - Aluguel <br /> de Malas LTDA
                  </p>
               </div>
            </div>
            <h3 className='text-center my-5 font-semibold'>Ordem de serviço N° {booking.id}</h3>
            <div>
               <div className="w-full flex flex-col">
                  <div className=''>
                     <h3 className='font-semibold'>Cliente:</h3>
                     <div className='border border-slate-300 h-36 flex items-center px-2'>
                        <p className='text-sm'>
                           {user.name} <br />
                           {user.cpf} <br />
                           {user.address.street}, {user.address.number} <br />
                           {user.address.complement} <br />
                           {user.address.district} <br />
                           CEP: {user.address.cep} <br />
                           Tel: {user.phone}
                        </p>
                     </div>
                  </div>
                  <div className=''>
                     <div className='h-24 flex flex-row'>
                        <div className="flex flex-col flex-1">
                           <div className='flex flex-1 border-b-0 border-r-0 border border-black-300 items-center pl-2'>
                              Filial
                           </div>
                           <div className='flex flex-1 border-r-0 border border-slate-300 items-center pl-2'>
                              {filial}
                           </div>
                        </div>
                        <div className="flex flex-col flex-1">
                           <div className='flex flex-1 border-b-0 text-sm border-r-0 border border-black-300 items-center pl-2'>
                              Receber
                           </div>
                           <div className='flex flex-1 border-r-0 text-sm text-sm border border-slate-300 items-center pl-2'>
                              {moment(booking.check_in).format('DD/MM/YY')}
                           </div>
                        </div>
                        <div className="flex flex-col flex-1">
                           <div className='flex flex-1 border-b-0 text-sm text-sm border border-slate-300 items-center pl-2'>
                              Devolver
                           </div>
                           <div className='flex flex-1 border text-sm border-slate-300 items-center pl-2'>
                              {moment(booking.check_out).format('DD/MM/YY')}
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
               <div className='mt-6'>
                  <h3 className="font-semibold">Malas:</h3>
                  {
                     booking?.bags?.map((item, index) => (
                        <div className='shadow-lg my-4 p-3 rounded-lg' key={index.toString()}>
                           Mala {item.size_name} <br />
                           {item.brand} - {item.model} <br />
                           Cor: {item.color} <br />
                           Valor Un: R$ {item.size_price} <br />
                           Valor Seguro: R$ {item.secure_value} <br />
                           Qtd de diárias: {booking.days} <br />
                           Total: R$ {((parseFloat(item.size_price) * booking.days) + parseInt(item.secure_value)).toFixed(2)}
                        </div>
                     ))
                  }
               </div>
               <div>
                  <h3 className='font-semibold'>Totais:</h3>
                  <div className='h-20 flex flex-row'>
                     <div className="flex flex-col flex-1">
                        <div className='flex flex-1 text-sm border-b-0 border-r-0 border border-slate-300 items-center pl-2'>
                           Malas
                        </div>
                        <div className='flex flex-1 text-sm border-r-0 border border-slate-300 items-center pl-2'>
                           R${(parseFloat(booking.total_value) - booking.delivery_value).toFixed(2)}
                        </div>
                     </div>
                     <div className="flex flex-col flex-1">
                        <div className='flex flex-1 text-sm border-b-0  border border-slate-300 items-center pl-2'>
                           Frete
                        </div>
                        <div className='flex flex-1 text-sm border border-slate-300 items-center pl-2'>
                           R$ {booking.delivery_value}.00
                        </div>
                     </div>
                     <div className="flex flex-col flex-1">
                        <div className='flex flex-1 text-sm border-b-0  border border-slate-300 items-center pl-2'>
                           Total
                        </div>
                        <div className='flex flex-1 text-sm border border-slate-300 items-center pl-2'>
                           R$ {booking.total_value}
                        </div>
                     </div>
                  </div>
               </div>
               <div className='my-6'>
                  <h3 className='font-semibold'>Dados de pagamento:</h3>
                  <div className='h-24 flex flex-row'>
                     <div className="flex flex-col flex-1">
                        <div className='flex flex-1 text-sm border border-b-0 border-slate-300 items-center pl-2'>
                           {booking.payment_method === 'credit_card' ? 'Cartão de crédito:' : null}
                           {booking.payment_method === 'pix' ? 'Pix:' : null}
                           &nbsp;
                           {statusPayment(booking.payment_method, booking.status)}
                        </div>
                        <div className='flex flex-1 text-sm border border-slate-300 items-center pl-2'>
                           Pedido:
                           &nbsp;
                           {statusPayment(booking.payment_method, booking.status)}
                        </div>
                     </div>
                    
                  </div>
               </div>
               <div className='mt-3'>
                  <p className='text-xs pb-4'>Concordo com os termos acima:</p>
                  <div className=' flex flex-col justify-between items-center'>
                     <div>
                        <p>Data: _____ /_____ /______</p>
                     </div>
                     <div>
                        ________________________
                        <p className='text-xs text-center'>Assinatura do responsável</p>
                     </div>
                  </div>
               </div>
            </div>
         </div>

         <div className='mx-auto pb-20 rounded-lg max-w-6xl hidden sm:block'>
            <div className=' flex justify-center gap-5' >
               <button
                  type="submit"
                  className=" text-white bg-default-orange hover:bg-default-blue
                  border-none font-semibold rounded-lg text-base font-semibold px-5 py-2.5
                  inline-flex items-center justify-center cursor-pointer"
                  onClick={() => navigate('/')}
               >
                  <>Voltar a alugar malas</>
               </button>
               <button
                  type="submit"
                  className=" text-white bg-default-orange hover:bg-default-blue
                  border-none font-semibold rounded-lg text-base font-semibold px-5 py-2.5
                  inline-flex items-center justify-center cursor-pointer"
                  onClick={() => navigate('/conta/reservas')}
               >
                  <>Meus pedidos</>
               </button>
            </div>
         </div>

         <div className='mx-5 pb-20 rounded-lg  block sm:hidden'>
            <div className=' flex justify-center gap-5' >
               <button
                  type="submit"
                  className=" text-white bg-default-orange hover:bg-default-blue
                  border-none font-semibold rounded-lg text-base font-semibold px-5 py-2.5
                  inline-flex items-center justify-center cursor-pointer"
                  onClick={() => navigate('/')}
               >
                  <>Voltar a alugar malas</>
               </button>
               <button
                  type="submit"
                  className=" text-white bg-default-orange hover:bg-default-blue
                  border-none font-semibold rounded-lg text-base font-semibold px-5 py-2.5
                  inline-flex items-center justify-center cursor-pointer"
                  onClick={() => navigate('/conta/reservas')}
               >
                  <>Meus pedidos</>
               </button>
            </div>
         </div>
         <Footer />
      </>
   );
}

export default Booking

