import React, { useState, useEffect } from 'react'
import { Divider, Image } from '@mantine/core'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';
import moment from 'moment'
import { useFormik } from 'formik';
import * as Yup from 'yup';

import Footer from '../../components/Footer'
import Header from '../../components/Header'
import AddCard from './components/AddCard'
import Spinner from '../../assets/svgs/spinner'

import ModalComponent from '../../components/Modal';
import Address from '../../components/Address'
import Budget from './components/Budget'

import { valid, formatarTelefone, formatarCep, brandsCard, states } from '../../utils';

import { loginUserSuccess } from '../../redux/actions'

import { HelpersAdress, HelpersAuth, HelpersPayments, HelpersCoupom } from '../../helpers';
const helpersAdress = new HelpersAdress()
const helpersAuth = new HelpersAuth()
const helpersPayments = new HelpersPayments()
const helpersCoupons = new HelpersCoupom()

const ConfirmAdrress = () => {
   const dispatch = useDispatch()
   const user = useSelector(state => state.Auth.user)
   const simulate = useSelector(state => state.Simulate.simulate)

   const [paymentMethod, setPaymentMethod] = useState({ type: 'pix', card_id: null });
   const [loadingPayment, setLoadingPayment] = useState(false);
   const [citys, setCitys] = useState([]);
   const [cards, setCards] = useState([]);
   const [modalAddCard, setModalAddCard] = useState(false);
   const [cupom, setCupom] = useState('');
   const [cupomErro, setCupomErro] = useState(false);
   const [cupomValue, setCupomValue] = useState({})

   const navigate = useNavigate();

   useEffect(() => {
      const listarCartoes = () => {
         helpersPayments.ListCards().then(resp => {
            setCards(resp.data.data)
         })
      }

      listarCartoes()
   }, [user.address])

   useEffect(() => {
      const findCity = async () => {
         try {
            const resp = await helpersAdress.ListCities(user.address.state_id);
            setCitys(resp.data.data);
         } catch (error) {
            console.error('Failed to fetch cities:', error);
         }
      };

      if (user.address) findCity();
   }, [user.address]);

   const listarCartoes = () => {
      helpersPayments.ListCards().then(resp => {
         setCards(resp.data.data)
      })
   }
   
   const AddressSchema = Yup.object().shape({
      cep: Yup.string()
         .matches(/^\d{8}$/, "CEP inválido")
         .required("O CEP é obrigatório"),
      street: Yup.string()
         .required("A rua é obrigatória"),
      state: Yup.string()
         .required("O estado é obrigatório"),
      city: Yup.string()
         .required("A cidade é obrigatória"),
      district: Yup.string()
         .required("O bairro é obrigatório"),
      complement: Yup.string()
         .notRequired(), // Opcional, não é obrigatório
      number: Yup.string()
         .required("O número é obrigatório"),
      phoneNumber: Yup.string()
         .required("O telefone é obrigatório")
         .matches(/^\d{10,11}$/, "Telefone inválido"),
   });

   const formik = useFormik({
      validationSchema: AddressSchema,
      validateOnBlur: false,
      validateOnChange: true,
      initialValues: {
         cep: valid(user.address) ? user.address.cep : '',
         street: valid(user.address) ? user.address.street : '',
         state: valid(user.address) ? user.address.state_id : 1,
         city: valid(user.address) ? user.address.city_id : '',
         district: valid(user.address) ? user.address.district : '',
         complement: valid(user.address) ? user.address.complement : '',
         number: valid(user.address) ? user.address.number : '',
         phoneNumber: valid(user.phone) ? user.phone : '',
      },
      onSubmit: async values => {
         setLoadingPayment(true)

         const usuario = {
            id: user.id,
            profile_id: 3,
            name: user.name,
            email: user.email,
            cpf: user.cpf,
            phone: values.phoneNumber.replace('(', '').replace(')', '').replace('-', '').trim(),
            rg: valid(user.rg) ? user.rg : '',
            birthdate: moment(user.birthDate).format('DD/MM/YYYY'),
            city_id: values.city,
            state_id: values.state,
            cep: values.cep,
            street: values.street,
            number: values.number,
            district: values.district,
            complement: values.complement
         }
   
         await helpersAuth.UpdateUser(usuario)
            .then(async () => {
            const validToken = localStorage.getItem("token_cliente")
            const user = await helpersAuth.GetUser(validToken);
            dispatch(loginUserSuccess(user.data.data))
            paymentMethod.type === 'pix' ? paymentWithPix() : paymentWithCard()
         })
      },
   });

   const findCity = async (sta) => {
      try {
         const resp = await helpersAdress.ListCities(sta);
         setCitys(resp.data.data);
      } catch (error) {
         setCitys([])
      }
   };
   

   const paymentWithCard = () => {
      const values = {
         payment_type: "credit_card",
         city_id: simulate.branch,
         initial_date: simulate.initial_date,
         final_date: simulate.final_date,
         card_id: paymentMethod.card_id,
         bags: simulate.items.map(item => ({
            "quantity": parseInt(item.quantity),
            "bag_size": item.size_id
         })),
         coupon_id: cupomValue?.id
      }

      helpersPayments.Payment(values).then(resp => {
         navigate('/ordem-de-servico/' + resp.data.data.id)
         setLoadingPayment(false)
      }).catch(err => {
         setLoadingPayment(false)
      })
   }

   const paymentWithPix = () => {
      const values = {
         payment_type: "pix",
         city_id: simulate.branch,
         initial_date: simulate.initial_date,
         final_date: simulate.final_date,
         bags: simulate.items.map(item => ({
            "quantity": parseInt(item.quantity),
            "bag_size": item.size_id
         })),
         coupon_id: cupomValue?.id
      }

      helpersPayments.Payment(values).then(resp => {
         const data = {
            qrCode: resp.data.data.qr_code_image, 
            code: resp.data.data.qr_code,
            booking_id: resp.data.data.booking_id 
         };
         navigate('/qrcode', { state: data });
         setLoadingPayment(false)
      })
   }

   const validate = () => {
      helpersCoupons.validateCoupom(cupom)
      .then(resp => {
         setCupomValue(resp.data.data)
         setCupomErro(false)
         toast("Cupom aplicado !", {
            type: "success"
         })
      })
      .catch(e => setCupomErro(true))
   }


   return (
      <>
         <Header title={'Checkout'}/>
         <div className='bg-white mx-auto py-20 rounded-lg max-w-6xl hidden sm:block'>
            <div className='w-max-lg'>
               <p className='mb-4 text-2xl font-bold text-gray-900'>Endereço e formas de pagamento:</p>

               <Divider className='' />
               
               <form className="space-y-6" onSubmit={formik.handleSubmit}>
                  <div className="flex my-4">
                     <div className='w-3/4' >
                        <Address formik={formik} citys={citys} findCity={findCity} />
                        <Divider className='mr-5' />
                        <Budget simulate={simulate} />
                     </div>

                     <Divider orientation='vertical' />

                     <div className="w-1/4">
                        <div className='flex flex-col mx-3'>
                           <div className='w-full my-3'>
                              <div className="flex">
                                 <div className="flex items-center gap-3">
                                    <input
                                       id={105}
                                       type="checkbox"
                                       value="Paneer"
                                       checked={paymentMethod.type === 'pix' ? true : false}
                                       onChange={() => setPaymentMethod({ type: 'pix', card_id: null })}
                                       className="w-4 h-4 bg-gray-50 rounded border border-gray-300 
                                       focus:ring-3 focus:ring-blue-300"
                                    />
                                    <Image
                                       src={brandsCard['Pix'].uri}
                                       alt="alt"
                                       w={brandsCard['Pix'].width}
                                    />
                                 </div>
                              </div>
                           </div>
                           {
                              cards.map((item, index) => (
                                 <div className='w-full my-3' key={index.toString()}>
                                    <div className="flex ">
                                       <div className="flex items-center gap-3">
                                          <input
                                             id={item.id.toString()}
                                             type="checkbox"
                                             value="Paneer"
                                             checked={paymentMethod.card_id === item.id ? true : false}
                                             onChange={() => setPaymentMethod({ type: 'credit_card', card_id: cards[index]?.id })}
                                             className="w-4 h-4 bg-gray-50 rounded border border-gray-300 
                                             focus:ring-3 focus:ring-blue-300"
                                          />
                                          <Image
                                             src={brandsCard[item.brand].uri}
                                             alt="alt"
                                             w={brandsCard[item.brand].width}
                                          />
                                       </div>
                                       <div className='flex flex-col'>
                                          <label className="ml-2 text-sm font-semibold text-gray-900">
                                             {item.first_numbers} **** **** {item.final_numbers}
                                          </label>
                                          <label className="ml-2 text-sm font-semibold text-gray-900">
                                             {item.client_name}
                                          </label>
                                       </div>
                                    </div>
                                 </div>
                              ))
                           }

                           <div className='w-full my-3'>
                              <div className="flex ">
                                 <div className="flex items-center gap-3">
                                    <input
                                       id={105}
                                       type="checkbox"
                                       value="Paneer"
                                       checked={false}
                                       onChange={() => setModalAddCard(true)}
                                       className="w-4 h-4 bg-gray-50 rounded border border-gray-300 
                                       focus:ring-3 focus:ring-blue-300"
                                    />
                                    <Image
                                       src={brandsCard['Add'].uri}
                                       alt="alt"
                                       w={brandsCard['Add'].width}
                                    />
                                 </div>
                                 <div className='flex flex-col justify-center'>
                                    <label className="ml-2 text-sm font-semibold text-gray-900">
                                       Adicionar cartão
                                    </label>
                                 </div>
                              </div>
                           </div>

                           <Divider />

                           <div className='mt-5'>
                              <label className="block mb-2 text-sm font-semibold text-gray-900">Cupom de desconto:</label>
                              <div className='flex'>
                                 <input
                                    type="text"
                                    name="numero"
                                    id="numero"
                                    className="bg-gray-50 border border-gray-300 
                                          text-gray-900 text-sm rounded-lg block w-full 
                                          p-2.5 border-solid border-2 focus:border-default-orange outline-none"
                                    placeholder="Digite o cupom"
                                    value={cupom}
                                    onChange={e => setCupom(e.target.value)}
                                 />
                                 <button
                                    className='bg-green-500 flex items-center px-3 rounded text-white ml-3 text-2xl'
                                    type="button"
                                    onClick={() => validate()}
                                 >
                                    +
                                 </button>
                              </div>
                              { cupomErro && <p className='text-red-500 text-sm'>Cupom inválido !</p> }
                           </div>

                           <div className='mt-5'>
                              <div className='flex font-semibold'><p className='font-normal'>Diárias:</p>&nbsp;R$ {(simulate.total_value - simulate.delivery_price).toFixed(2)}</div>
                              <div className='flex my-2 font-semibold'><p className='font-normal'>Entrega/coleta:</p>&nbsp;R$ {simulate.delivery_price}</div>
                              {
                                 Object.keys(cupomValue).length > 0 &&
                                 <>
                                    <div className='flex my-2 font-semibold'>
                                    <p className='font-normal'>
                                       Desconto:
                                    </p>&nbsp; 
                                    - R$ { cupomValue.discount_type === 'percentage' ? ((simulate.total_value * cupomValue.discount) / 100 ).toFixed(2) : cupomValue.discount}
                                    </div>
                                 </>
                              }
                              <div className='flex font-semibold text-2xl'><p className='font-normal text-2xl'>
                                 Total:</p>&nbsp;
                                 R$ { cupomValue.discount ? cupomValue.discount_type === 'percentage' ? (simulate.total_value - ((simulate.total_value * cupomValue.discount) / 100)).toFixed(2) : (simulate.total_value - cupomValue.discount).toFixed(2) : simulate.total_value }
                              </div>
                           </div>
                        </div>
                        <div className='flex justify-end mt-10 mx-4'>
                           <button className="cursor-pointer mx-auto bg-default-orange hover:bg-default-blue p-3 rounded-xl text-white font-semibold text-2xl" type="submit" disabled={loadingPayment}>
                              {
                                 loadingPayment ? <div className='flex items-center justify-center'>
                                    <Spinner />
                                    {paymentMethod.type === 'credit_card' ? 'Pagando com cartão' : 'Gerando QRcode'}
                                 </div> : paymentMethod.type === 'credit_card' ? 'Pagar com cartão' : 'Pagar via Pix'
                              }
                           </button>
                        </div>
                     </div>
                  </div>
               </form>
            </div>
         </div>


         <div className='rounded-lg bg-white w-max-4xl p-5 block sm:hidden'>
            <div className='w-max-lg'>
               <form className="space-y-6" onSubmit={formik.handleSubmit}>
                  <p className='my-4 text-2xl font-bold text-gray-900'>Endereço e formas de pagamento:</p>
                  <Divider className='' />
                  <div className="f">
                     <div className="space-y-2">
                        <p className='mb-4 text-base font-semibold text-gray-900'>Confirme seu endereço de entrega:</p>
                        <div>
                           <input
                              type="text"
                              name="cep"
                              id="cep"
                              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full 
                                 p-1.5 border-solid border-2 focus:border-default-orange outline-none"
                              placeholder="Digite seu CEP"
                              maxLength={9}
                              
                              value={formatarCep(formik.values.cep)}
                              onChange={(e) => formik.setFieldValue('cep', e.target.value.replace(/\D/g, ''))}
                              onBlur={formik.handleBlur}
                           />
                           {formik.touched.cep && formik.errors.cep && (
                              <p className='text-red-500 text-xs italic'>{formik.errors.cep}</p>
                           )}
                        </div>

                        <div>
                           <input
                              type="text"
                              name="street"
                              id="street"
                              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full 
                                 p-1.5 border-solid border-2 focus:border-default-orange outline-none"
                              placeholder="Digite sua rua"
                              
                              value={formik.values.street}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                           />
                           {formik.touched.street && formik.errors.street && (
                              <p className='text-red-500 text-xs italic'>{formik.errors.street}</p>
                           )}
                        </div>

                        <div>
                           <select
                              type="text"
                              name="state"
                              id="state"
                              className="bg-gray-50 border border-gray-300 
                              text-gray-900 text-sm rounded-lg  block w-full 
                              p-2.5 border-solid border-2 focus:border-default-orange outline-none"
                              placeholder="Selecione seu estado" 
                              value={formik.values.state}
                              onChange={(e) => {
                                 formik.setFieldValue('state', e.target.value)
                                 formik.setFieldValue('city', '')
                                 findCity(e.target.value)
                              }}
                              onBlur={formik.handleBlur}
                           >  
                              <option value="">Selecione o estado</option>
                              {states.map((item, index) => (
                                 <option value={item.id} key={index.toString()}>{item.name}</option>
                              ))}
                           </select>
                           {formik.touched.state && formik.errors.state ? (
                              <p className='text-red-500 text-xs italic'>{formik.errors.state}</p> 
                           ) : null}
                        </div>

                        <div>
                           <select
                              type="text"
                              name="city"
                              id="city"
                              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg  block w-full 
                                 p-1.5 border-solid border-2 focus:border-default-orange outline-none"
                              placeholder="Selecione sua cidade"
                              
                              value={formik.values.city}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                           >
                              <option value="">Selecione a cidade</option>
                              {
                                 citys.map((item, index) =>
                                    <option value={item.id} key={index.toString()}>{item.name}</option>
                                 )
                              }
                           </select>
                           {formik.touched.city && formik.errors.city && (
                              <p className='text-red-500 text-xs italic'>{formik.errors.city}</p>
                           )}
                        </div>

                        <div>
                           <input
                              type="text"
                              name="district"
                              id="district"
                              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg  block w-full 
                                 p-1.5 border-solid border-2 focus:border-default-orange outline-none"
                              placeholder="Digite seu bairro"
                              
                              value={formik.values.district}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                           />
                           {formik.touched.district && formik.errors.district && (
                              <p className='text-red-500 text-xs italic'>{formik.errors.district}</p>
                           )}
                        </div>

                        <div>
                           <input
                              type="text"
                              name="complement"
                              id="complement"
                              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full 
                                 p-1.5 border-solid border-2 focus:border-default-orange outline-none"
                              placeholder="Complemento"
                              value={formik.values.complement}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                           />
                           {formik.touched.complement && formik.errors.complement && (
                              <div className="text-red-500 text-sm">{formik.errors.complement}</div>
                           )}
                        </div>

                        <div>
                           <input
                              type="text"
                              name="number"
                              id="number"
                              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full 
                                 p-1.5 border-solid border-2 focus:border-default-orange outline-none"
                              placeholder="Número da casa/apto"
                              
                              value={formik.values.number}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                           />
                           {formik.touched.number && formik.errors.number && (
                              <p className='text-red-500 text-xs italic'>{formik.errors.number}</p>
                           )}
                        </div>

                        <div>
                           <input
                              type="text"
                              name="phoneNumber"
                              id="phoneNumber"
                              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full 
                                 p-1.5 border-solid border-2 focus:border-default-orange outline-none"
                              placeholder="Digite seu telefone"
                              
                              maxLength={14}
                              value={formatarTelefone(formik.values.phoneNumber)}
                              onChange={(e) => formik.setFieldValue('phoneNumber', e.target.value.replace(/\D/g, ''))}
                              onBlur={formik.handleBlur}
                           />
                           {formik.touched.phoneNumber && formik.errors.phoneNumber && (
                              <p className='text-red-500 text-xs italic'>{formik.errors.phoneNumber}</p>
                           )}
                        </div>
                     </div>
                  </div>

                  <Divider className='' />

                  <h3 className='my-3 text-base font-semibold text-gray-900'>Formas de pagamento:</h3>

                  <div className='flex flex-col '>
                     <div className='w-full my-3'>
                        <div className="flex">
                           <div className="flex items-center gap-3">
                              <input
                                 id={105}
                                 type="checkbox"
                                 value="Paneer"
                                 checked={paymentMethod.type === 'pix' ? true : false}
                                 onChange={() => setPaymentMethod({ type: 'pix', card_id: null })}
                                 className="w-4 h-4 bg-gray-50 rounded border border-gray-300 
                                       focus:ring-3 focus:ring-blue-300"
                              />
                              <Image
                                 src={brandsCard['Pix'].uri}
                                 alt="alt"
                                 w={brandsCard['Pix'].width}
                              />
                           </div>
                        </div>
                     </div>
                     {
                        cards.map((item, index) => (
                           <div className='w-full my-3' key={index.toString()}>
                              <div className="flex ">
                                 <div className="flex items-center gap-3">
                                    <input
                                       id={item.id.toString()}
                                       type="checkbox"
                                       value="Paneer"
                                       checked={paymentMethod.card_id === item.id ? true : false}
                                       onChange={() => setPaymentMethod({ type: 'credit_card', card_id: cards[index]?.id })}
                                       className="w-4 h-4 bg-gray-50 rounded border border-gray-300 
                                             focus:ring-3 focus:ring-blue-300"
                                    />
                                    <Image
                                       src={brandsCard[item.brand].uri}
                                       alt="alt"
                                       w={brandsCard[item.brand].width}
                                    />
                                 </div>
                                 <div className='flex flex-col'>
                                    <label className="ml-2 text-sm font-semibold text-gray-900">
                                       {item.first_numbers.substring(0, 4)} **** **** {item.final_numbers}
                                    </label>
                                    <label className="ml-2 text-sm font-semibold text-gray-900">
                                       {item.client_name}
                                    </label>
                                 </div>
                              </div>
                           </div>
                        ))
                     }

                     <div className='w-full my-3'>
                        <div className="flex ">
                           <div className="flex items-center gap-3">
                              <input
                                 id={105}
                                 type="checkbox"
                                 value="Paneer"
                                 checked={false}
                                 onChange={() => setModalAddCard(true)}
                                 className="w-4 h-4 bg-gray-50 rounded border border-gray-300 
                                       focus:ring-3 focus:ring-blue-300"
                              />
                              <Image
                                 src={brandsCard['Add'].uri}
                                 alt="alt"
                                 w={brandsCard['Add'].width}
                              />
                           </div>
                           <div className='flex flex-col justify-center'>
                              <label className="ml-2 text-sm font-semibold text-gray-900">
                                 Adicionar cartão
                              </label>
                           </div>
                        </div>
                     </div>

                     <Divider />

                     <div className='mt-5'>
                        <label className="block mb-2 text-sm font-semibold text-gray-900">Cupom de desconto:</label>
                        <div className='flex'>
                           <input
                              type="text"
                              name="numero"
                              id="numero"
                              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full 
                              p-2.5 border-solid border-2 focus:border-default-orange outline-none"
                              placeholder="Digite o cupom"
                              value={cupom}
                              onChange={e => setCupom(e.target.value)}
                           />
                           <button
                              className='bg-green-500 flex items-center px-3 rounded text-white ml-3 text-2xl'
                              type="button"
                              onClick={() => validate()}
                           >
                              +
                           </button>
                        </div>
                        { cupomErro && <p className='text-red-500 text-sm'>Cupom inválido!</p> }
                     </div>

                     <div className='mt-5'>
                        <div className='flex font-semibold'><p className='font-normal'>Diárias:</p>&nbsp; R$ {(simulate.total_value - simulate.delivery_price).toFixed(2)}</div>
                        <div className='flex my-2 font-semibold'><p className='font-normal'>Entrega/coleta:</p>&nbsp; R$ {simulate.delivery_price}</div>
                        {
                           Object.keys(cupomValue).length > 0 &&
                           <>
                              <div className='flex my-2 font-semibold'>
                              <p className='font-normal'>
                                 Desconto:
                              </p>&nbsp; 
                              - R$ { cupomValue.discount_type === 'percentage' ? ((simulate.total_value * cupomValue.discount) / 100 ).toFixed(2) : cupomValue.discount}
                              </div>
                           </>
                        }
                        <div className='flex font-semibold text-2xl'><p className='font-normal text-2xl'>
                           Total:</p>&nbsp;
                           R$ { cupomValue.discount ? cupomValue.discount_type === 'percentage' ? (simulate.total_value - ((simulate.total_value * cupomValue.discount) / 100)).toFixed(2) : (simulate.total_value - cupomValue.discount).toFixed(2) : simulate.total_value }
                        </div>
                     </div>
                  </div>
                  
                  <div className='flex justify-end py-5 mx-4'>
                     <button className="cursor-pointer mx-auto bg-primary p-3 rounded-xl text-white font-semibold text-2xl" type="submit" disabled={loadingPayment}>
                        {
                           loadingPayment ? <div className='flex items-center justify-center'>
                              <Spinner />
                              {paymentMethod.type === 'credit_card' ? 'Pagando com cartão' : 'Gerando QRcode'}
                           </div> : paymentMethod.type === 'credit_card' ? 'Pagar com cartão' : 'Pagar via Pix'
                        }
                     </button>
                  </div>
               </form>
            </div>
         </div>

         <ModalComponent
            isVisible={modalAddCard}
            onClose={() => setModalAddCard(false)}
            currentModal={0}
         >
            <AddCard closeModal={(card_id) => {
               listarCartoes()
               setModalAddCard(false)
               setPaymentMethod({ type: 'credit_card', card_id: card_id })
            }} />
         </ModalComponent>
         <Footer />
      </>
   );
}

export default ConfirmAdrress