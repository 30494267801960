import {
    LOGIN_USER,
    LOGIN_USER_SUCCESS,
    LOGOUT_USER,
    REGISTER_USER,
    REGISTER_USER_SUCCESS,
    UPDATE_USER, 
    STATES,
    LOGIN_FAILED,
    SUBSCRIBE_FAILED
} from './constants';


export const loginUser = (email, password) => ({
    type: LOGIN_USER,
    payload: { email, password }
});

export const loginUserSuccess = (user) => ({
    type: LOGIN_USER_SUCCESS,
    payload: user
});

export const registerUser = (name, email, cpf, password, confirmPassword) => ({
    type: REGISTER_USER,
    payload: { name, email, cpf, password, confirmPassword }
});

export const registerUserSuccess = (user) => ({
    type: REGISTER_USER_SUCCESS,
    payload: user
});

export const logoutUser = (history) => ({
    type: LOGOUT_USER,
    payload: { history }
});

export const updateUser = (valuesUser) => ({
    type: UPDATE_USER,
    payload: { valuesUser }
});

export const setStates = (states) => ({
    type: STATES,
    payload: { states }
});

export const loginFailed = (error) => ({
    type: LOGIN_FAILED,
    payload: error
});

export const subscribeFailed = (error) => ({
    type: SUBSCRIBE_FAILED,
    payload: error
});