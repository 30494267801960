export const statusBooking = (status) => {
   const statusBook = {
      'pending': <span className=" p-1.5 text-xs font-medium uppercase tracking-wider text-green-800 bg-gray-400 rounded-lg bg-opacity-50">
         Pendente
      </span>,
      'scheduled': <span className=" p-1.5 text-xs font-medium uppercase tracking-wider text-green-800 bg-green-200 rounded-lg bg-opacity-50">
         Pago
      </span>,
      'in_delivery': <span className="p-1.5 text-xs font-medium uppercase tracking-wider text-yellow-800 bg-violet-200 rounded-lg bg-opacity-50">
        Em entrega
      </span>,
      'in_use': <span className="p-1.5 text-xs font-medium uppercase tracking-wider text-red-800 bg-blue-200 rounded-lg bg-opacity-50">
         Em uso
      </span>,
      'in_devolution': <span className="p-1.5 text-xs font-medium uppercase tracking-wider text-red-800 bg-orange-200 rounded-lg bg-opacity-50">
         Em devolução
      </span>,
      'finished': <span className="p-1.5 text-xs font-medium uppercase tracking-wider text-red-800 bg-red-200 rounded-lg bg-opacity-50">
         Finalizada
      </span>
   }
      
   return statusBook[status]
}