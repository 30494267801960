import React from 'react'
import { Image } from '@mantine/core'
import logo from '../../assets/images/malug.png'

function Header({title}) {
   return (
      <div className='bg-primary px-5'>
         <div className={`w-full flex py-7 m-0 mx-auto max-w-6xl`} >
               <div className='w-full flex justify-between'>
                  <a href="/" >
                     <Image src={logo} w={130} />
                  </a>
                  <h1 className='text-white m-0 text-10xl font-bold'>{title}</h1>
               </div>
         </div>
      </div>
   )
}

export default Header




