import React from 'react'

const ModalLoginSubscribe = ({isVisible, onClose, currentModal, children}) => {
	if(!isVisible) return null;

	const LoginSubscribe = () => {
		return (
			<div 
				className="fixed inset-0 bg-black bg-opacity-50 z-40 backdrop-blur-sm
				flex justify-center items-center" id="wrapper"
			>
				<div className="md:w-[600px] w-[90%] mx-auto flex flex-col">
					<button className="text-white text-xl place-self-end bg-transparent border-0" onClick={() => onClose()}>X</button>
					<div className="bg-white p-2 rounded ">
						{children}
					</div>
				</div>
			</div>
		)
	}

	const Calendar = () => {
		return (
			<div 
				className="fixed inset-0 bg-black bg-opacity-50 z-40 backdrop-blur-sm
				flex justify-center items-center" id="wrapper" 
			>
				<div className="max-w-6xl mx-auto flex flex-col">
					<button className="text-white text-xl place-self-end bg-transparent border-0" onClick={() => onClose()}>X</button>
					<div className="flex flex-col justify-center">
						{children}
					</div>
				</div>
			</div>
		)
	}

	const modals = [
		<LoginSubscribe  />,
		<Calendar  />,
	];

	return modals[currentModal]

	
	
}

export default ModalLoginSubscribe