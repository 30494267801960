import React, { useEffect } from 'react';
import { formataCPF, validateCPF } from '../../utils';
import { Divider } from '@mantine/core';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import Spinner from '../../assets/svgs/spinner';

import { useDispatch, useSelector } from 'react-redux';
import * as authActions from '../../redux/actions';

export default function Subscribe({ typeModal, setTypeModal, web }) {
   const dispatch = useDispatch();

   const loading = useSelector((state) => state.Auth.loadingSubscribe);
   const error = useSelector((state) => state.Auth.erroSubscribe);

   useEffect(() => {
      // Você pode adicionar lógica aqui, se necessário
   }, [typeModal, error]);

   const SignUpSchema = Yup.object().shape({
      name: Yup.string()
        .min(2, "No mínimo 2 caracteres")
        .max(30, "No máximo 30 caracteres")
        .required("O nome é obrigatório"),
      email: Yup.string().email("Email inválido").required("O email é obrigatório"),
      cpf: Yup.string()
        .required("O CPF é obrigatório")
        .test('is-valid-cpf', 'CPF inválido', value => validateCPF(value.replace(/\D/g, ''))), // Remove caracteres não numéricos para validação
      password: Yup.string()
        .min(6, "A senha deve ter no mínimo 6 caracteres")
        .required("A senha é obrigatória"),
      confirmPassword: Yup.string()
        .oneOf([Yup.ref('password'), null], "As senhas devem coincidir")
        .required("A confirmação da senha é obrigatória"),
    });

   const formik = useFormik({
      validationSchema: SignUpSchema, // Corrigido aqui
      validateOnBlur: false,
      validateOnChange: true,
      initialValues: {
         name: '',
         email: '',
         cpf: '',
         password: '',
         confirmPassword: ''
      },
      onSubmit: values => {
         // Altere esta linha para a ação de dispatch correta
         dispatch(authActions.registerUser(values.name, values.email, values.cpf, values.password, values.confirmPassword));
      },
    });

   return (
      <div className="py-6 px-6 lg:px-8 text-left">
         <h3 className="mb-4 text-xl font-bold text-gray-900">
            Cadastre-se em nossa plataforma
         </h3>
         <Divider />
         <form className="space-y-4 mt-4" onSubmit={formik.handleSubmit}>
            <div>
               <label className="block text-sm font-semibold text-gray-900">
                  Seu Nome:
               </label>
               <input
                  type="text"
                  name="name"
                  id="name"
                  className="bg-gray-50 border border-gray-300 
                text-gray-900 text-sm rounded-lg  block w-full 
                p-2.5 border-solid border-2 focus:border-default-orange outline-none"
                  placeholder="Carlos dos Santos Pereira"
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  disabled={loading}
               />
               {formik.touched.name && formik.errors.name ? (
                  <p className='text-red-500 text-xs italic'>{formik.errors.name}</p>
               ) : null}
            </div>
            <div>
               <label className="block text-sm font-semibold text-gray-900">
                  CPF:
               </label>
               <input
                  type="text"
                  name="cpf"
                  id="cpf"
                  className="bg-gray-50 border border-gray-300 
                text-gray-900 text-sm rounded-lg  block w-full 
                p-2.5 border-solid border-2 focus:border-default-orange outline-none"
                  placeholder="111.222.333-44"
                  value={formataCPF(formik.values.cpf)} // Aplica a formatação para exibição
                  onChange={(e) => formik.setFieldValue('cpf', e.target.value.replace(/\D/g, ''))} // Atualiza valor sem formatação para validação
                  onBlur={formik.handleBlur}
                  disabled={loading}
               />
               {formik.touched.cpf && formik.errors.cpf ? (
                  <p className='text-red-500 text-xs italic'>{formik.errors.cpf}</p>
               ) : null}
            </div>
            <div>
               <label className="block text-sm font-semibold text-gray-900">
                  Seu email:
               </label>
               <input
                  type="email"
                  name="email"
                  id="email"
                  autoComplete="email"
                  className="bg-gray-50 border border-gray-300 
              text-gray-900 text-sm rounded-lg  block w-full 
              p-2.5 border-solid border-2 focus:border-default-orange outline-none"
                  placeholder="nome@email.com"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  disabled={loading}
               />
               {formik.touched.email && formik.errors.email ? (
                  <p className='text-red-500 text-xs italic'>{formik.errors.email}</p>
               ) : null}
            </div>
            <div>
               <label className="block text-sm font-semibold text-gray-900">
                  Sua senha:
               </label>
               <input
                  type="password"
                  name="password"
                  id="password"
                  autoComplete="current-password"
                  className="bg-gray-50 border border-gray-300 
                text-gray-900 text-sm rounded-lg  block w-full 
                p-2.5 border-solid border-2 focus:border-default-orange outline-none"
                  placeholder="*********"
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  disabled={loading}
               />
               {formik.touched.password && formik.errors.password ? (
                  <p className='text-red-500 text-xs italic'>{formik.errors.password}</p>
               ) : null}
            </div>
            <div>
               <label className="block text-sm font-semibold text-gray-900">
                  Confirme sua senha:
               </label>
               <input
                  type="password"
                  name="confirmPassword"
                  id="confirmPassword"
                  autoComplete="confirm-password"
                  className="bg-gray-50 border border-gray-300 
                text-gray-900 text-sm rounded-lg  block w-full 
                p-2.5 border-solid border-2 focus:border-default-orange outline-none"
                  placeholder="*********"
                  value={formik.values.confirmPassword}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  disabled={loading}
               />
               {formik.touched.confirmPassword && formik.errors.confirmPassword ? (
                  <p className='text-red-500 text-xs italic'>{formik.errors.confirmPassword}</p>
               ) : null}
            </div>
            <div className="flex justify-between">
               <div className="flex items-start">
                  <div className="flex items-center h-5">
                     <input
                        id="remember"
                        type="checkbox"
                        value=""
                        className="w-4 h-4 bg-gray-50 rounded border border-gray-300 
                          focus:ring-3 focus:ring-blue-300"
                     />
                  </div>
                  <label
                     className="ml-2 text-sm font-semibold text-gray-900"
                  >
                     Lembrar-me
                  </label>
               </div>
            </div>
            {error ? (
              <div className='w-full bg-red-300 rounded-lg py-2 px-3'>
                <p className="text-red-600">
                   {error.message ===
                      'O campo cpf não é um CPF válido.' ?
                      'O CPF é inválido' : null
                   }
                   {error.message ===
                      'O campo cpf não é um CPF válido. (and 1 more error)' ?
                      'O CPF é inválido' : null
                   }
                   {error.message ===
                      'The email has already been taken.' ?
                      'Este email já está sendo usado' : null
                   }
                   {error.message ===
                      'The cpf has already been taken. (and 1 more error)' ?
                      'Este CPF já está sendo usado' : null
                   }
                   {error.message ===
                      'The password confirmation does not match.' ?
                      'As senhas não correspondem' : null
                   }
                   {error.message ===
                      'The cpf has already been taken.' ?
                      'Este CPF já está sendo usado' : null
                   }
                </p>
              </div>
            ) : null}
            <button
               type="submit"
               className="w-full text-white bg-default-orange hover:bg-default-blue
              border-none font-semibold rounded-lg text-base font-semibold px-5 py-2.5
              inline-flex items-center justify-center cursor-pointer"
               disabled={loading}
            >
               {loading ? <> <Spinner /> Fazendo cadastro</> : 'Fazer cadastro'}
            </button>
            {
               web &&
               <div className="text-sm font-semibold text-gray-500">
                  Já tem uma conta?{" "}
                  <button className="bg-transparent border-0 text-sm font-semibold text-default-orange hover:underline cursor-pointer" onClick={() => setTypeModal('login')}>
                     Entrar
                  </button>
               </div>
            }
         </form>
      </div>
   )
}
