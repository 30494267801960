import React from 'react'
import { Divider } from '@mantine/core'

export default function Sucess({setTypeModal}) {
   return (
      <div className="py-6 px-6 lg:px-8 text-left" >
         <h3 className="mb-4 text-xl font-bold text-gray-900">
            Sua senha redefinida com sucesso
         </h3>
         <Divider />
         <div className="space-y-6 mt-4">
            <div className='flex justify-center items-center'>
               <i className="bi bi-check-circle" style={{fontSize: 80, color: 'green'}}></i>
            </div>
            <div className='flex flex-col justify-evenly items-center gap-3'>
               <h1 className="font-bold text-xl">Concluído</h1>
               <h1 className='text-center font-semibold '>Senha redefinida com sucesso</h1>
               <button
                  onClick={() => setTypeModal('login')}
                  className="w-full text-white bg-default-orange hover:bg-default-blue
                  border-none font-semibold rounded-lg text-base font-semibold px-5 py-2.5
                  inline-flex items-center justify-center cursor-pointer"
               > 
                  Fazer login
               </button>
            </div>
         </div>
      </div>
   )
}
