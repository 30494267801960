import React, { useEffect, useState } from 'react';
import ReactPaginate from 'react-paginate';

function PaginatedItems({ itemsPerPage, setPg, qtdItems }) {
  // We start with an empty list of items.
  
  const [pageCount, setPageCount] = useState(0);
  // Here we use item offsets; we could also use page offsets
  // following the API or data you're working with.


  useEffect(() => {
    // Fetch items from another resources.
    setPageCount(Math.ceil(qtdItems / itemsPerPage));
  }, [itemsPerPage, qtdItems]);

  // Invoke when user click to request another page.
  const handlePageClick = (event) => {
    setPg(event.selected + 1)
  };

  return (
    
        <ReactPaginate
            className='flex flex-row'  
            pageClassName="bg-white border border-gray-300 text-xs text-gray-500  hover:bg-gray-100 hover:text-gray-700 leading-tight py-2 px-3"
            breakClassName="bg-white border border-gray-300 text-xs text-gray-500  hover:bg-gray-100 hover:text-gray-700 leading-tight py-2 px-3"
            activeClassName="bg-blue-50 border border-gray-300 text-xs text-blue-600 font-bold hover:bg-blue-100 hover:text-blue-700  py-2 px-3"
            previousClassName="bg-white border border-gray-300 text-xs text-gray-500 hover:bg-gray-100 hover:text-gray-700 ml-0 rounded-l-lg leading-tight py-2 px-3"
            nextClassName="bg-white border border-gray-300 text-xs text-gray-500 hover:bg-gray-100 hover:text-gray-700 rounded-r-lg leading-tight py-2 px-3"
            breakLabel="..."
            nextLabel=">>"
            onPageChange={handlePageClick}
            pageRangeDisplayed={5}
            pageCount={pageCount}
            previousLabel="<<"
            renderOnZeroPageCount={null}
        />
   
  );
}

export default PaginatedItems