import api from './Api/api'


class HelpersBranchs {
    async ListBranchs() {
		return new Promise(async(resolve, reject) => {
		   try {
			   const branchs = await api.get('/branch/list-simple', {
					headers: {
					   'Content-Type': 'application/json',
					   'Access-Control-Allow-Origin' : '*',
					},
				})
			   resolve (branchs)
		   } catch (error) {
			  reject (error)
		   }
		})
	}

}

export { HelpersBranchs };