import React from 'react'


const Pix = () => {
   return (
      <div className="flex">
         <img
            src={require("../assets/images/pix_pay.png")}
            width="15"
            height="20"
            alt='img'
         />
      </div>

   )
}

const CreditCard = () => {
   return (
      <div className="flex">
         <img
            src={require("../assets/images/card.png")}
            width="15"
            alt='img'
         />
      </div>
   )
}

export const payment = (value) => {
   const renderPayment = {
      'pix':  <Pix />,
      'credit_card': <CreditCard />
   }

   return renderPayment[value]
}