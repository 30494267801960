import api from './Api/api'


function validToken() {
	const Token = localStorage.getItem("token_cliente")
	return Token
}

class HelpersAuth {

	async Login(email, password) {
      return new Promise(async(resolve, reject) => {
         const payload = {
            email: email,
            password : password
         }

         try {
            const auth = await api.post('auth/login', payload,
               {
                  headers: {
                     'Content-Type': 'application/json',
                     'Access-Control-Allow-Origin' : '*'
                  },
               },
            )
            resolve (auth)
         } catch (error) {
            reject (error.response.data)
         }
      })
	}

	async Register(name, email, cpf, password, confirmPassword) {
		return new Promise(async(resolve, reject) => {

         const payload = {
            name: name,
            cpf: cpf,
            email: email,
            password: password,
            password_confirmation: confirmPassword
         }
  
		   try {
			  const register = await api.post('/auth/register', payload,
				 {
					headers: {
					   'Content-Type': 'application/json',
					   'Access-Control-Allow-Origin' : '*'
					},
				 },
			  )
			  resolve (register)
		   } catch (error) {
			  reject (error.response.data)
		   }
		})
	}

   async UpdateUser(values) {
		return new Promise(async(resolve, reject) => {
		   try {
			   const userUpdate = await api.post('/user', values,
               {
                  headers: {
                     'Content-Type': 'application/json',
                     'Access-Control-Allow-Origin' : '*',
                     'Authorization': 'Bearer ' + validToken()
                  },
               },
			   )
			  resolve (userUpdate)
		   } catch (error) {
			  reject (error.response.data)
		   }
		})
	}

   async sendEmailForgot(email) {
      return new Promise(async(resolve, reject) => {
         const payload = {
            email: email
         }

         try {
            const sendEmail = await api.post('auth/reset', payload,
               {
                  headers: {
                     'Content-Type': 'application/json',
                     'Access-Control-Allow-Origin' : '*'
                  },
               },
            )
            resolve (sendEmail)
         } catch (error) {
            reject (error)
         }
      })
	}

   async resetPassword(values) {
      return new Promise(async(resolve, reject) => {

         try {
            const sendCode = await api.post('auth/confirm', values,
               {
                  headers: {
                     'Content-Type': 'application/json',
                     'Access-Control-Allow-Origin' : '*'
                  },
               },
            )
            resolve (sendCode)
         } catch (error) {
            reject (error)
         }
      })
	}

	async GetUser(token) {
		try {
			const user = await api.get('/user', {
				headers: {
					'Content-Type': 'application/json',
					'Access-Control-Allow-Origin' : '*',
			   		'Authorization': 'Bearer ' + token
				}
			})
			return user
		} catch (error) {
			return error
		}
	}

}

export { HelpersAuth }
