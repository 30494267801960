import api from './Api/api'

function validToken() {
   const Token = localStorage.getItem("token_cliente")
   return Token
}

class HelpersCoupom {

   async validateCoupom(cupom) {
      return new Promise(async (resolve, reject) => {
         try {
            const coupom = await api.get('user/coupon/validate?coupon=' + cupom, {
               headers: {
                  'Content-Type': 'application/json',
                  'Access-Control-Allow-Origin': '*',
                  'Authorization': 'Bearer ' + validToken()
               },
            })
            resolve(coupom)
         } catch (error) {
            reject(error)
         }
      })
   }

}

export { HelpersCoupom };

