import * as React from "react";
const SVGComponent = (props) => (
  <svg
    width={47}
    height={45}
    viewBox="0 0 47 45"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M40.7251 7.91667H6.2718C4.15757 7.91667 2.44365 9.54897 2.44365 11.5625V33.4375C2.44365 35.451 4.15757 37.0833 6.2718 37.0833H40.7251C42.8394 37.0833 44.5533 35.451 44.5533 33.4375V11.5625C44.5533 9.54897 42.8394 7.91667 40.7251 7.91667Z"
      stroke="white"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2.44365 18.8542H44.5533"
      stroke="white"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default SVGComponent;
