/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react'
import { Divider, Modal } from '@mantine/core'
import { DatePicker } from '@mantine/dates';
import dayjs from 'dayjs';
import 'dayjs/locale/pt';
import { useNavigate } from "react-router-dom";

import moment from 'moment'
import 'moment/locale/pt-br'

import Simulation from './components/Simulation'

//SVG's
import Calendarr from '../../../../assets/svgs/calendar'
import Map from '../../../../assets/svgs/map'
import BriefCase from '../../../../assets/svgs/briefcase'
import Mapin from '../../../../assets/svgs/map-in'
import Spinner from '../../../../assets/svgs/spinner'


import ModalComponent from '../../../../components/Modal';

import { HelpersBookings } from '../../../../helpers';
const helpersBookings = new HelpersBookings()

function SelectDate({ branchs, simulate, setSimulate, user }) {
   const [checkin, setCheckin] = useState(null)
   const [checkinErro, setCheckinErro] = useState(false)
   const [checkout, setCheckout] = useState(null)
   const [checkoutErro, setCheckoutErro] = useState(false)
   const [malaP, setMalaP] = useState("")
   const [malaM, setMalaM] = useState("")
   const [malaG, setMalaG] = useState("")
   const [malaErro, setMalaErro] = useState(false)
   const [branch, setBranch] = useState("")
   const [branchErro, setBranchErro] = useState(false)
   const [loadingSimulate, setLoadingSimulate] = React.useState(false);
   const [selectDateCollectModal, setSelectDateCollectModal] = useState(false)
   const [selectDateDeliveryModal, setSelectDateDeliveryModal] = useState(false)
   const [selectBranchModal, setSelectBranchModal] = useState(false)
   const [selectQtdModal, setSelectQtdModal] = useState(false)
   const [showFilter, setShowFilter] = React.useState(false);
   const [showErroBudget, setShowErroBudget] = React.useState(false);

   const navigate = useNavigate();

   const checking = () => {
      let erro = false
      if (checkin === '' || checkin === null) {
         setCheckinErro(true)
         erro = true
      } else setCheckinErro('')

      if (branch === '') {
         setBranchErro(true)
         erro = true
      } else setBranchErro('')

      if (checkout === '' || checkout === null) {
         setCheckoutErro(true)
         erro = true
      } else setCheckoutErro('')

      let bagp = false
      let bagm = false
      let bagg = false

      if (parseInt(malaP) === 0 || malaP === "") {
         bagp = true
      }

      if (parseInt(malaM) === 0 || malaM === "") {
         bagm = true
      }

      if (parseInt(malaG) === 0 || malaG === "") {
         bagg = true
      }

      if (bagp && bagm && bagg === true) {
         setMalaErro(true)
         erro = true
      } else setMalaErro('')

      const bags = [
         malaP > 0 ? {
            quantity: malaP,
            bag_size: 1
         } : null,
         malaM > 0 ? {
            quantity: malaM,
            bag_size: 2
         } : null,
         malaG > 0 ? {
            quantity: malaG,
            bag_size: 3
         } : null
      ]

      const malas = bags.filter(word => word !== null);

      if (erro === false) simulateBookings({
         city_id: branch.city_id,
         initial_date: moment(checkin).format('DD/MM/YYYY'),
         final_date: moment(checkout).format('DD/MM/YYYY'),
         bags: malas
      })
   }

   const simulateBookings = (values) => {
      setLoadingSimulate(true)
      helpersBookings.Simulate(values).then(resp => {
         setLoadingSimulate(false)
         setSimulate({
            branch: branch.city_id,
            initial_date: moment(checkin).format('DD/MM/YYYY'),
            final_date: moment(checkout).format('DD/MM/YYYY'),
            items: resp.data.data.items,
            delivery_price: resp.data.data.delivery_price,
            total_value: resp.data.data.total_value
         })
         setShowFilter(true)
      }).catch(() => {
         setLoadingSimulate(false)
         setShowErroBudget(true)
      })
   }

   function validToken() {
      const Token = localStorage.getItem("token_cliente")
      return Token
   }

   const getDayPropsCheckIn = (date) => {
      if (dayjs(checkin).format('DD/MM/YYYY') === dayjs(date).format('DD/MM/YYYY')) {
         return {
            style: {
               backgroundColor: 'var(--mantine-color-red-filled)',
               color: 'var(--mantine-color-white)',
            },
         };
      }
      return {};
   };

   const getDayPropsCheckOut = (date) => {
      if (dayjs(checkout).format('DD/MM/YYYY') === dayjs(date).format('DD/MM/YYYY')) {
         return {
            style: {
               backgroundColor: 'var(--mantine-color-red-filled)',
               color: 'var(--mantine-color-white)',
            },
         };
      }
      return {};
   };

   return (
      <div>
         <div className='-mt-32 max-w-6xl mx-auto hidden sm:block'>
            <div className='sm:block hidden' >
                  <p className='text-white text-[40px] font-bold'>Alugue malas rápido e fácil</p>
                  <p className='text-white text-[25px] font-normal'>Preencha abaixo as informações necessárias para seu orçamento.</p>
               </div>
            <div className='
               hidden 
               sm:block 
               sm:flex 
               justify-between  
               align-center  
               bg-white 
               shadow-lg 
               max-w-6xl 
               mx-auto 
               p-5
               rounded-2xl
               relative'
               id="wrapper"
            >
               <div>
                  <p className='mb-5 font-medium'>Escolha as melhores datas</p>
                  <div
                     className='bg-[#E9E9E9] w-64 h-[66px] rounded rounded-2xl flex items-center cursor-pointer'
                     onClick={() => setSelectDateCollectModal(true)}
                  >
                     <div className='h-[40px] w-[40px] bg-primary rounded rounded-[20px] flex items-center justify-center mx-3'>
                        <Calendarr />
                     </div>
                     <p className='text-base text-gray-500'>{checkin ? moment(checkin).format('DD/MM/YYYY') : "Data de recebimento"}</p>
                  </div>
                  <p className='italic text-default-red text-xs'>{checkinErro ? 'Preeencha este campo' : null}</p>
                  <p className='my-2 font-regular text-sm text-center'>até</p>

                  <div
                     className='bg-[#E9E9E9] w-64 h-[66px] rounded rounded-2xl flex items-center cursor-pointer'
                     onClick={() => setSelectDateDeliveryModal(true)}
                  >
                     <div className='h-[40px] w-[40px] bg-primary rounded rounded-[20px] flex items-center justify-center mx-3'>
                        <Calendarr />
                     </div>
                     <p className='text-base text-gray-500'>{checkout ? moment(checkout).format('DD/MM/YYYY') : 'Data de devolução'}</p>
                  </div>
                  <p className='italic text-default-red text-xs'>{checkoutErro ? 'Preeencha este campo' : null}</p>
               </div>

               <Divider orientation='vertical' />

               <div>
                  <p className='mb-5 font-medium'>Informações adicionais</p>

                  <div className='flex gap-5'>
                     <div>
                        <div
                           className='bg-[#E9E9E9] w-96 h-[66px] rounded rounded-2xl flex items-center cursor-pointer'
                           onClick={() => setSelectBranchModal(true)}
                        >
                           <div className='h-[40px] w-[40px] bg-primary rounded rounded-[20px] flex items-center justify-center mx-3'>
                              <Map />
                           </div>
                           <p className='text-base text-gray-500'>{branch ? branch.city_name : 'Selecione a unidade'}</p>
                        </div>
                        <p className='ml-1 italic text-default-red text-xs'>{branchErro ? 'Preeencha este campo' : null}</p>
                     </div>

                     <div>
                        <div
                           className='bg-[#E9E9E9] w-96 h-[66px] rounded rounded-2xl flex items-center cursor-pointer'
                           onClick={() => setSelectQtdModal(true)}
                        >
                           <div className='h-[40px] w-[40px] bg-primary rounded rounded-[20px] flex items-center justify-center mx-3'>
                              <BriefCase />
                           </div>
                           <p className='text-base text-gray-500'>{malaG > 0 ? `${malaG} ${malaG > 1 ? 'Malas G, ' : 'Mala G, '}` : null}</p>
                           <p className='text-base text-gray-500'>{malaM > 0 ? `${malaM} ${malaM > 1 ? 'Malas M, ' : 'Mala M, '}` : null}</p>
                           <p className='text-base text-gray-500'>{malaP > 0 ? `${malaP} ${malaP > 1 ? 'Malas P' : 'Mala P'}` : null}</p>
                           <p className='text-base text-gray-500'>{malaG > 0 || malaM > 0 || malaP > 0 ? null : "Tamanho e quantidade"}</p>
                        </div>
                        <p className='italic text-default-red text-xs'>{malaErro ? 'Preeencha este campo' : null}</p>
                     </div>
                  </div>

                  <Divider type='vertical' className={'my-5'} />

                  <button
                     className="cursor-pointer bg-default-orange hover:bg-default-blue w-full h-[54px] text-2xl font-bold rounded-xl text-white flex items-center justify-center"
                     onClick={() => checking()}
                  >
                     {loadingSimulate ? <> <Spinner /> Orçando</> : 'Fazer orçamento'}
                  </button>
               </div>
            </div>
         </div>


         <div className='
            block
            sm:hidden
            flex 
            flex-col
            justify-center 
            p-2 
            align-center 
            bg-white 
            shadow-lg 
            max-w-5xl 
            mx-auto 
            py-5
            mx-5
            -mt-7 
            gap-3
            rounded-xl
            relative'
            id="wrapper"
         >
            <div className='mx-auto flex flex-col justify-between align-center'>
               <div className="flex flex-col items-center">

                  <p className='mb-5 font-medium'>Escolha as melhores datas</p>
                  <div>
                     <div
                        className='bg-[#E9E9E9] w-64 h-[66px] rounded rounded-2xl flex items-center cursor-pointer'
                        onClick={() => setSelectDateCollectModal(true)}
                     >
                        <div className='h-[40px] w-[40px] bg-primary rounded rounded-[20px] flex items-center justify-center mx-3'>
                           <Calendarr />
                        </div>
                        <p className='text-base text-gray-500'>{checkin ? moment(checkin).format('DD/MM/YYYY') : "Data de recebimento"}</p>
                     </div>
                     <p className='italic text-default-red text-xs'>{checkinErro ? 'Preeencha este campo' : null}</p>
                  </div>

                  <p className='my-2 font-regular text-sm'>Selecione as duas datas</p>

                  <div>
                     <div
                        className='bg-[#E9E9E9] w-64 h-[66px] rounded rounded-2xl flex items-center cursor-pointer'
                        onClick={() => setSelectDateDeliveryModal(true)}
                     >
                        <div className='h-[40px] w-[40px] bg-primary rounded rounded-[20px] flex items-center justify-center mx-3'>
                           <Calendarr />
                        </div>
                        <p className='text-base text-gray-500'>{checkout ? moment(checkout).format('DD/MM/YYYY') : 'Data de devolução'}</p>
                     </div>
                     <p className='italic text-default-red text-xs'>{checkoutErro ? 'Preeencha este campo' : null}</p>
                  </div>

                  <p className='mt-5 mb-2 font-medium'>Informações adicionais</p>
                  <div className='mb-3'>
                     <div
                        className='bg-[#E9E9E9] w-64 h-[66px] rounded rounded-2xl flex items-center cursor-pointer'
                        onClick={() => setSelectBranchModal(true)}
                     >
                        <div className='h-[40px] w-[40px] bg-primary rounded rounded-[20px] flex items-center justify-center mx-3'>
                           <Map />
                        </div>
                        <p className='text-base text-gray-500'>{branch ? branch.city_name : 'Selecione a unidade'}</p>
                     </div>
                     <p className='ml-1 italic text-default-red text-xs'>{branchErro ? 'Preeencha este campo' : null}</p>
                  </div>

                  <div className='mb-3'>
                     <div
                        className='bg-[#E9E9E9] w-64 h-[66px] rounded rounded-2xl flex items-center cursor-pointer'
                        onClick={() => setSelectQtdModal(true)}
                     >
                        <div className='h-[40px] w-[40px] bg-primary rounded rounded-[20px] flex items-center justify-center mx-3'>
                           <BriefCase />
                        </div>
                        <p className='text-base text-gray-500'>{malaG > 0 ? `${malaG} ${malaG > 1 ? 'Malas G, ' : 'Mala G, '}` : null}</p>
                        <p className='text-base text-gray-500'>{malaM > 0 ? `${malaM} ${malaM > 1 ? 'Malas M, ' : 'Mala M, '}` : null}</p>
                        <p className='text-base text-gray-500'>{malaP > 0 ? `${malaP} ${malaP > 1 ? 'Malas P' : 'Mala P'}` : null}</p>
                        <p className='text-base text-gray-500'>{malaG > 0 || malaM > 0 || malaP > 0 ? null : "Tamanho e quantidade"}</p>
                     </div>
                     <p className='italic text-default-red text-xs'>{malaErro ? 'Preeencha este campo' : null}</p>
                  </div>

               </div>
               <button className="cursor-pointer bg-primary w-64 h-[54px] text-2xl font-bold rounded-xl text-white flex items-center justify-center" onClick={() => checking()} disabled={loadingSimulate}>
                  {
                     loadingSimulate ? <><Spinner /> Orçando</> : 'Fazer orçamento'
                  }
               </button>
            </div>
         </div>
         <Modal overflow="inside" onClose={() => setShowFilter(false)} opened={showFilter} size="calc(100vw - 3rem)">
            <Simulation
               nextStep={() => validToken() === null ? navigate('/identificacao') : user.address === null ? navigate('/endereco') : navigate('/checkout')}
               simulate={simulate}
            />
         </Modal>
         <Modal overflow="inside" onClose={() => setShowErroBudget(false)} opened={showErroBudget} size="calc(100vw - 3rem)">
            <div className='bg-white mx-auto p-4 rounded-lg max-w-6xl '>
               <div className='max-w-5xl mx-auto '>
                  <p className='mb-4 text-2xl font-bold text-gray-900'>Informações sobre o seu orçamento</p>
                  <Divider className='' />
                  <p className='mt-4 text-xl font-semibold text-red-600'>Nos desculpe, mas não foram encontradas malas disponíveis nessa data.</p>
               </div>
            </div>
         </Modal>
         <ModalComponent isVisible={selectDateCollectModal} onClose={() => setSelectDateCollectModal(false)} currentModal={1}>
            <div className='bg-white rounded-xl'>
               <DatePicker
                  placeholder="Data de retirada"
                  minDate={dayjs(new Date()).add(1, 'days').toDate()}
                  maxDate={checkout === null ? dayjs(new Date()).add(6, 'months').toDate() : dayjs(new Date(checkout)).subtract(5, 'days').toDate()}
                  value={checkin}
                  onChange={value => {
                     setCheckin(value)
                     setSelectDateCollectModal(false)
                  }}
                  getDayProps={getDayPropsCheckIn}
                  locale="pt-br"
                  icon={<svg aria-hidden="true" className="w-5 h-5 text-gray-500" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z" clipRule="evenodd"></path></svg>}
                  className="border-none text-gray-900 text-2xl
                     sm:text-sm rounded-lg focus:ring-default-orange 
                     focus:border-default-orange w-full px-10 p-2.5"
                  monthlabelformat="MMMM Do YYYY"

               />
            </div>
            <p className='text-center text-xs text-white pt-1'>*Data de retirada</p>
         </ModalComponent>
         <ModalComponent isVisible={selectDateDeliveryModal} onClose={() => setSelectDateDeliveryModal(false)} currentModal={1}>
            <div className='bg-white rounded-xl'>
               <DatePicker
                  placeholder="Data de devolução"
                  minDate={checkin === null ? dayjs(new Date()).add(6, 'days').toDate() : dayjs(new Date(checkin)).add(5, 'days').toDate()}
                  value={checkout}
                  onChange={value => {
                     setCheckout(value)
                     setSelectDateDeliveryModal(false)
                  }}
                  getDayProps={getDayPropsCheckOut}
                  locale="pt-br"
                  icon={<svg aria-hidden="true" className="w-5 h-5 text-gray-500" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z" clipRule="evenodd"></path></svg>}
                  className="border-none text-gray-900 text-2xl
                        sm:text-sm rounded-lg focus:ring-default-orange 
                        focus:border-default-orange w-full px-10 p-2.5 "
                  monthlabelformat="MMMM Do YYYY"
               />
            </div>
            <p className='text-center text-xs text-white pt-1'>*Data de devolução</p>
         </ModalComponent>
         <ModalComponent isVisible={selectBranchModal} onClose={() => setSelectBranchModal(false)} currentModal={1}>
            <div className='bg-white w-[334px] max-h-96 rounded-xl flex flex-col items-center gap-3 py-3'>
               {
                  branchs.map((item, index) => (
                     <div
                        className={`h-[57px] w-[305px] ${branch === item ? 'bg-primary' : 'bg-[#D9D9D9]'} rounded-xl flex items-center pl-5 gap-3 cursor-pointer`}
                        key={index.toString()}
                        onClick={() => {
                           setBranch(item)
                           setSelectBranchModal(false)
                        }}
                     >
                        <Mapin />
                        <h1 className='font-medium'>{item.state_name} - {item.city_name}</h1>
                     </div>
                  ))
               }
            </div>
            <p className='text-center text-xs text-white pt-1'>*Marque a opção mais próxima de você</p>
         </ModalComponent>
         <ModalComponent isVisible={selectQtdModal} onClose={() => setSelectQtdModal(false)} currentModal={1}>
            <div className='bg-white w-[334px] max-h-96 rounded-xl flex flex-col items-center gap-3 py-3'>
               <p className='mb-5 font-medium text-center'>
                  Selecione o tamanho e a quantidade de malas
               </p>
               <div className='h-[57px] w-[305px] bg-[#D9D9D9] rounded-[30px] flex items-center pl-1 gap-3'>
                  <div className='bg-primary h-[48px] w-[48px] rounded-[24px] flex items-center justify-center'>
                     <h1 className='text-white font-bold text-2xl'>P</h1>
                  </div>
                  <select
                     name="cars" id="cars" className='bg-transparent w-52'
                     value={malaP} onChange={event => setMalaP(event.target.value)}
                  >
                     <option value="">Selecione a quantidade</option>
                     <option value="1">1 Mala</option>
                     <option value="2">2 Malas</option>
                     <option value="3">3 Malas</option>
                     <option value="4">4 Malas</option>
                  </select>
               </div>
               <div className='h-[57px] w-[305px] bg-[#D9D9D9] rounded-[30px] flex items-center pl-1 gap-3'>
                  <div className='bg-primary h-[48px] w-[48px] rounded-[24px] flex items-center justify-center'>
                     <h1 className='text-white font-bold text-2xl'>M</h1>
                  </div>
                  <select
                     name="cars" id="cars" className='bg-transparent w-52'
                     value={malaM} onChange={event => setMalaM(event.target.value)}
                  >
                     <option value="">Selecione a quantidade</option>
                     <option value="1">1 Mala</option>
                     <option value="2">2 Malas</option>
                     <option value="3">3 Malas</option>
                     <option value="4">4 Malas</option>
                  </select>
               </div>
               <div className='h-[57px] w-[305px] bg-[#D9D9D9] rounded-[30px] flex items-center pl-1 gap-3'>
                  <div className='bg-primary h-[48px] w-[48px] rounded-[24px] flex items-center justify-center'>
                     <h1 className='text-white font-bold text-2xl'>G</h1>
                  </div>
                  <select className='bg-transparent w-52' value={malaG} onChange={event => setMalaG(event.target.value)}>
                     <option value="">Selecione a quantidade</option>
                     <option value="1">1 Mala</option>
                     <option value="2">2 Malas</option>
                     <option value="3">3 Malas</option>
                     <option value="4">4 Malas</option>
                  </select>
               </div>
            </div>
            <button
               className="cursor-pointer mx-auto bg-default-orange hover:bg-default-blue w-[300px] h-[54px] font-bold mt-5 text-2xl rounded-lg text-white"
               onClick={() => setSelectQtdModal(false)}
               disabled={loadingSimulate}
            >
               Confirmar quantidade
            </button>
         </ModalComponent>
      </div>
   )
}

export default SelectDate


