import api from './Api/api'

function validToken() {
	const Token = localStorage.getItem("token_cliente")
	return Token
}

class HelpersPayments {

   async Payment(payload) {
		return new Promise(async(resolve, reject) => {
		   try {
			   const pix = await api.post('/booking/save', payload, {
					headers: {
					   'Content-Type': 'application/json',
					   'Access-Control-Allow-Origin' : '*',
                  		'Authorization': 'Bearer ' + validToken()
					},
				})
			   resolve (pix)
		   } catch (error) {
			  reject (error)
		   }
		})
	}

   async ListCards() {
		return new Promise(async(resolve, reject) => {
		   try {
			   const cards = await api.get('/user/card', {
					headers: {
					   'Content-Type': 'application/json',
					   'Access-Control-Allow-Origin' : '*',
                  'Authorization': 'Bearer ' + validToken()
					},
				})
			   resolve (cards)
		   } catch (error) {
			  reject (error)
		   }
		})
	}

   async SaveCard(payload) {
		return new Promise(async(resolve, reject) => {
		   try {
			   const card = await api.post('/user/card', payload,  {
					headers: {
					   'Content-Type': 'application/json',
					   'Access-Control-Allow-Origin' : '*',
                  'Authorization': 'Bearer ' + validToken()
					},
				})
			   resolve (card)
		   } catch (error) {
			  reject (error)
		   }
		})
	}

   async DeleteCard(id) {
		return new Promise(async(resolve, reject) => {
		   try {
			   const card = await api.delete('/user/card/'+id,  {
					headers: {
					   'Content-Type': 'application/json',
					   'Access-Control-Allow-Origin' : '*',
                  'Authorization': 'Bearer ' + validToken()
					},
				})
			   resolve (card)
		   } catch (error) {
			  reject (error)
		   }
		})
	}

}

export { HelpersPayments };